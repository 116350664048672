import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import ProductandCategory from '../../../services/ProductandCategory';

const CreateCategory = () => {
    const [formData, setFormData] = useState({ name: '', description: '' });
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        // Check if an ID is provided in the URL params
        if (id) {
            // Fetch category data for editing
            ProductandCategory.getCategoryById(id)
                .then((res) => {
                    const { name, description } = res.data[0];
                    setFormData({ name, description });
                    setIsEditing(true);
                })
                .catch((error) => {
                    console.error('Error fetching category for editing:', error);
                });
        }
    }, [id]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const getCurrentDate = () => {
        return new Date().toISOString().split('T')[0]; // Get current date in 'YYYY-MM-DD' format
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        const { name, description } = formData;

        if (!name) {
            alert('Name is required.');
            return;
        }

        const categoryData = {
            name,
            description,
            createdDate: isEditing ? formData.createdDate : getCurrentDate(),
            lastModifiedDate: getCurrentDate(),
        };

        // Check if it's an edit or create operation
        if (isEditing) {
            // Update existing category
            ProductandCategory.editCategory(id, categoryData)
                .then(() => {
                    alert('Category updated successfully');
                    navigate('/productcategory/list');
                })
                .catch((error) => {
                    console.error('Error updating category:', error);
                });
        } else {
            // Create new category
            ProductandCategory.addCategory(categoryData)
                .then((res) => {
                    if (res.status === 201) {
                        alert('Category created successfully')
                        navigate('/productcategory/list');
                    } else {
                        alert("something went wrong")
                    }

                })
                .catch((error) => {
                    console.error('Error creating category:', error);
                });
        }
    };

    return (
        <div className='container mb-5'>
            <div className="d-flex justify-content-between align-items-center">
                <h2>{isEditing ? 'Edit Category' : 'Create Category'}</h2>
                <Link to="/productcategory/list">
                    <button
                        className="btn btn-success"
                    >
                        View category list
                    </button></Link>

            </div>

            <form onSubmit={handleSubmit} className="container mt-4">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <label>
                            Name:
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="form-control"
                                required
                            />
                        </label>
                        <div dangerouslySetInnerHTML={{ __html: formData.name }} />
                        <br />
                        <label>
                            Description:
                            <input
                                type="text"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </label>
                        <div dangerouslySetInnerHTML={{ __html: formData.description }} />
                        <br />
                        <button type="submit" className="btn btn-primary mt-2">
                            {isEditing ? 'Update Category' : 'Create Category'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreateCategory;
