import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";

export default function ManageTrainingProgress(props) {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState([]);
  const [pageno, setPageno] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [chunks, setChunks] = useState([]);
  const [pagesArr, setPagesArr] = useState([]);
  const [usersLength, setUsersLength] = useState(0);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      CourseService.getStudentTrainingProgress(token)
        .then((res) => {

          const usersProgress = res.data.progress
            .slice(0)
            .reverse()
            .filter((result) => {
              return result.progress > 0 && result.userId != null;
            });
          setUsersLength(usersProgress.length);
          paginationData(usersProgress);
          setCurrentPage(1);
          setProgress(usersProgress.splice(0, 10));
          setLoading(false);
        })
        .catch((error) => {
          console.log(error.reponse);
        });
    }
  }, []);

  //add pagination Bug #252975 --start
  const paginationData = (progressArr) => {
    let l = 0;
    let j = progressArr.length % 10;
    let k = Math.ceil(progressArr.length / 10);
    let i = 10;
    for (let x = 0; x < k; x++) {
      setPagesArr(...pagesArr, (pagesArr[x] = []));
      if (progressArr.length % (i * (x + 1)) !== progressArr.length) {
        for (let y = 0; y < 10; y++) {
          setPagesArr(...pagesArr, (pagesArr[x][y] = progressArr[l]));
          l++;
        }
      } else if (progressArr.length % (i * (x + 1)) === progressArr.length) {
        for (let z = 0; z < j; z++) {
          setPagesArr(...pagesArr, (pagesArr[x][z] = progressArr[l]));
          l++;
        }
      }
    }
    setChunks(pagesArr);
  };

  const handlePageChange = (e) => {
    setCurrentPage(e);
    setProgress(chunks[e - 1]);
  };
  //add pagination Bug #252975 --end

  if (loading) {
    return (
      <div className="formloader">
        <div className="row text-center">
          <div className="col-12">
            <img src={formloader} alt="" height="100" />
          </div>
          <div className="col-12 text-white h4">Please Wait...</div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
          <nav>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item active" style={{ cursor: "auto" }}>
                Training Progress
              </li>
            </ol>
          </nav>
        </div>
        <div className="container p-2 px-3" style={{ height: "90vh" }}>
          {!loading && (!progress || progress.length === 0) ? (
            <h2 className="m-4 text-center">No Progress yet.</h2>
          ) : (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Course</th>
                    <th scope="col">Progress</th>
                  </tr>
                </thead>
                <tbody>
                  {progress &&
                    progress.map((userProgress, index) => {
                      if (userProgress !== undefined && !userProgress.userId) {
                        return null;
                      }
                      if (userProgress !== undefined && index < pageno * 10) {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              {(currentPage - 1) * 10 + index + 1}
                            </th>
                            <td>{userProgress.userId.username}</td>
                            <td>{userProgress.courseId.name}</td>
                            <td>
                              <div className="progress">
                                <div
                                  className="progress-bar progress-bar-animated"
                                  role="progressbar"
                                  aria-valuenow="75"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{
                                    width: `${userProgress.progress}%`,
                                  }}>
                                  {userProgress.progress} %
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>

              {/* pagination */}
              <div className="d-flex justify-content-center mt-5">
                <Pagination
                  activePage={currentPage}
                  itemClass="page-item"
                  linkClass="page-link"
                  itemsCountPerPage={10}
                  totalItemsCount={usersLength}
                  pageRangeDisplayed={10}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
