import React, { useEffect } from "react";
import CourseService from "../../services/CourseService";
import { useParams, useHistory, Link } from "react-router-dom";
import jwt_decode from "jwt-decode";

export default function PracticalExam(props) {
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   console.log(jwt_decode(token));
  //   // CourseService.getExamPracticalQuestions(examId).then((res) => {});
  //   // if (!token) {
  //   //   props.history.push("/login");
  //   // } else {
  //   //   const userId = jwt_decode(localStorage.getItem("token")).id;
  //   //   // const examId = "6262413737943525bc559024";
  //   //   CourseService.getExamPracticalQuestions(examId, userId).then((res) => {
  //   //   });
  //   // }
  // }, []);

  return (
    <div className="container mt-2">
      <h2 className="pt-4">Practical Exam</h2>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item border border-primary mb-3">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Windows Users
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ul>
                <li>
                  <p>
                    Install below setups in your system in a specified order. To
                    Appear for the exam only run exam-win.exe.
                  </p>
                  <ol>
                    <li>
                      <a
                        className="text-decoration-none"
                        href="https://apitutorial.dev.radixweb.net/setups/setup-win.exe"
                      >
                        Setup-win.exe
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-decoration-none"
                        href="https://apitutorial.dev.radixweb.net/setups/exam-win.exe"
                      >
                        Exam-win.exe
                      </a>
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item border border-primary mb-3">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Linux Users
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ul>
                <li>
                  <p>
                    Install below setups in your system in a specified order. To
                    Appear for the exam only run exam-win.exe.
                  </p>
                  <ol>
                    <li>
                      <a
                        className="text-decoration-none"
                        href="https://apitutorial.dev.radixweb.net/setups/setup-linux"
                      >
                        Setup-linux
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-decoration-none"
                        href="https://apitutorial.dev.radixweb.net/setups/exam-linux"
                      >
                        Exam-linux
                      </a>
                    </li>
                  </ol>
                </li>
                <li>
                  Run chmod +x ./setup-linux and chmod +u ./setup-linux on
                  location where .exe is downloaded.
                </li>
                <li>Run ./setup-linux when you are ready for exam.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item border border-primary mb-3">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              MAC Users
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ul>
                <li>
                  <p>
                    Install below setups in your system in a specified order. To
                    Appear for the exam only run exam-win.exe.
                  </p>
                  <ol>
                    <li>
                      <a
                        className="text-decoration-none"
                        href="https://apitutorial.dev.radixweb.net/setups/setup-macos"
                      >
                        Setup-macos
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-decoration-none"
                        href="https://apitutorial.dev.radixweb.net/setups/exam-macos"
                      >
                        Exam-macos
                      </a>
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
