import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import "./courseList.scss";
import CourseListBanner from "../../components/courseListBanner/courseListBanner";
import AccordionList from "../../components/accordion/accordionList";
import { useParams, Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import $ from "jquery";
import formloader from "../../images/formloading.svg";
import global from "../../servicesvariable";
import LogoSlider from "../../components/logoSlider/logoSlider";

export default function CourseList() {
  const navigate = useNavigate();
  let { cname } = useParams();
  const [page, setPage] = useState({ selected: 1 });
  const [course, setCourse] = useState({});
  const [subcourse, setSubCourse] = useState({});
  const [selectedcourses, setSelectedcourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allsubcourse, setallsubcourse] = useState([]);
  const [user, setUser] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [users, setUsers] = useState([]);
  const [otherusers, setOtherUsers] = useState([]);
  const [vcounter, setVcounter] = useState([]);
  const [tcounter, setTcounter] = useState([]);
  const [dcounter, setDcounter] = useState(-1);
  const token = localStorage.getItem("token");

  var imgList = [];
  var logo = [];
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 14,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 8.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings2 = {
    // slidesToShow: 3,
    // slidesToScroll: 3,
    // infinite: false,
    // dots: true,
    // arrows: false,
    // adaptiveHeight: false,
    // responsive: [
    //   {
    //     breakpoint: 1199,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 575,
    //     settings: {
    //       slidesToShow: 1.3,
    //       slidesToScroll: 1,
    //       dots: false,
    //       adaptiveHeight: false,
    //     },
    //   },
    // ],
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    dots: true,
    arrows: false,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getCoursesSubscribedAndUsersSubscribed = async (token, courseId) => {
    try {
      let limit = 9;
      const getCoursesSubscribedAndUsersSubscribedResponse =
        await LoginService.getCoursesSubscribedAndUsersSubscribed(
          token,
          courseId,
          limit
        );
      // if (!getCoursesSubscribedAndUsersSubscribedResponse.data.success) {
        
      // }
      setUsers(
        getCoursesSubscribedAndUsersSubscribedResponse.data?.data
          ?.coursesSubscribedToCourse?.courses?.length
      );
      setOtherUsers(
        getCoursesSubscribedAndUsersSubscribedResponse.data.data
          .usersSubscribeToCourse
      );
    } catch (error) {
      console.log("Error getCoursesSubscribedAndUsersSubscribed", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    setConfirmBox(false);

    if (!token) {
      navigate("/login");
    }

    let tmpcid = "";
    const data = [];

    // get course
    CourseService.getSingleCourse(cname)
      .then((res) => {
        if (res.data) {
          tmpcid = res.data._id;
          setCourse(res.data);
          getCoursesSubscribedAndUsersSubscribed(token, res.data?._id);
          CourseService.getSinglesubcourse(res.data._id).then((result) => {
            if (result.data) {
              setDcounter(result.data.length);
              var counter = 0;
              const data = [];
              const otherdata = [];
              result.data.map((item) => {
                CourseService.getTrainingPlan(item.name).then((tres) => {
                  if (tres.data.length > 0) {
                    let length = {
                      length: tres.data.length,
                      name: item.name,
                    };
                    if (tres.data.length > 0) {
                      setTcounter((d) => [...d, length]);
                    }
                  } else {
                    let length = {
                      length: 0,
                      name: item.name,
                    };
                    setTcounter((d) => [...d, length]);
                  }
                });
              });
            }
          });
        }
      })
      .catch((ex) => {});
    CourseService.getSubCourses()
      .then((res) => {
        res.data = res.data.filter((d)=>d.type != null)
        setallsubcourse(res.data);
        res.data.map((item) => {
          if (tmpcid === item.type) {
            const decoded = jwt_decode(token);

            CourseService.getSingleSubCourse(item.name)
              .then((res) => {
                if (res.data) {
                  setSubCourse(res.data.name);
                }
              })
              .catch((ex) => {});
          }
        });
      })
      .catch((ex) => {});

    const article = document.querySelector("#content");
    const button = document.querySelector("#read-more");

    // button.addEventListener("click", readMore);

    function readMore() {
      if (article.className == "open") {
        article.className = "";
        button.innerHTML = "Show more";
      } else {
        article.className = "open";
        button.innerHTML = "Show less";
      }
    }
    setLoading(false);
  }, [cname]);

  useEffect(() => {
    $(window).scrollTop(0);
  }, []);

  useEffect(() => {
    setConfirmBox(false);

    if (token) {
      setLoading(true);
      const decoded = jwt_decode(token);
      LoginService.getSingleUser(decoded.id, token)
        .then((res) => {
          if (res.data) {
            const u = res.data;
            setUser(u);
            CourseService.getSubCoursesIn(
              { courses: res.data.courses },
              token
            ).then((result) => {
              if (result.data) {
                setSelectedcourses(result.data);
              } else {
                setSelectedcourses([]);
              }
            });
          } else {
            navigate("/login");
          }
          setLoading(false);
        })
        .catch((ex) => {});
    }
  }, [cname, confirmBox]);

  const goToDescription = (name) => {
    navigate("/course/" + cname + "/description/" + name);
  };
  const goToSubCourse = (name) => {
    navigate("/course/" + cname + "/subcourse/" + name);
  };

  const handleAutoSubscribe = (sid) => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.AddSubscribeCourse(decoded.role, decoded.id, sid, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => {});
  };

  const handleAutoSubscribed = (sid) => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.deleteSubscriptionCourse(decoded.id, sid, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => {});
  };

  const handleSubscribe = (sid) => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.addSubscriptionRequest(decoded.id, sid, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => {});
  };

  const handlePendingRequest = (sid) => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.deleteSubscriptionRequest(decoded.id, sid, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => {});
  };
  const handleSubscribed = (sid) => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.deleteSubscriptionCourse(decoded.id, sid, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => {});
  };

  allsubcourse.forEach((data) => {
    if (
      data.type.icon !== "default.jpg" &&
      !logo.find((item) => item.type.icon === data.type.icon)
    ) {
      logo.push(data);
      imgList.push(
        <div class="text-center">
          <a
            href={`/course/${data.type.name}`}
            class="d-inline-block"
            title="course"
          >
            {/* <img class="mx-auto" alt="" width="48" height="48" src={img.img} /> */}
            {data.type.icon.match(/http/g) ? (
              <img
                src={data.type.icon}
                class="mx-auto"
                alt=""
                width="48"
                height="48"
              />
            ) : (
              <>
                <img
                  src={`${global.imgURL}/${data.type.icon}`}
                  class="img-fluid"
                  alt={data.type.name}
                  height="48"
                  width="48"
                ></img>
              </>
            )}
          </a>
        </div>
      );
      // }
      // else{
      //   imgList.push(<div class="text-center"><a href="#" class="d-inline-block" title="course"><img class="mx-auto" alt="" width="48" height="48" src={img.img} /></a></div>  )
    }
    // })
  });
  const whoElseLearning = (item, course) => {
    // return item.courses.map((courses) => {
    return (
      // cname == courses.name && (
      <>
        <div class="col-3 course-img">
          {course.icon === "default.jpg" ? (
            <img
              src={`./../${course.icon}`}
              class="img-fluid h-100"
              alt={course.icon}
              width="150"
              height="150"
            ></img>
          ) : course.icon.match(/http/g) ? (
            <img
              src={course.icon}
              alt={course.icon}
              class="img-fluid w-100"
              width="150"
              height="150"
            />
          ) : (
            <>
              <img
                src={`${global.imgURL}/${course.icon}`}
                class="img-fluid h-100"
                alt={course.name}
                height="150"
                width="150"
              ></img>
            </>
          )}
        </div>
        <div class="col-9">
          <div class="card-body p-3">
            <h4 class="card-title text-black font-weight-400">
              {course.name.replaceAll("_", "  ").replaceAll("-", " ")}
            </h4>
            <p class="card-text m-0 font-12 text-gray">Subscribed by</p>
            <p class="card-text text-dark text-capitalize">{item.username}</p>
          </div>
        </div>
      </>
      // )
    );
    // });
  };
  return (
    <div>
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">Loading...</div>
          </div>
        </div>
      )}
      <CourseListBanner
        course={course}
        allsubcourse={allsubcourse}
        vcounter={vcounter}
        tcounter={tcounter}
        users={users}
        selectedcourses={selectedcourses}
        setConfirmBox={setConfirmBox}
      />
      <section class="container">
        {allsubcourse.filter((item) => item.type._id === course._id)?.length >
        0 ? (
          <h2 class="text-dark-gray font-24 mb-2 mb-md-4">
            {course.name} courses available for you
          </h2>
        ) : (
          <h2 class="text-dark-gray font-24 mb-2 mb-md-4">
            {course.name} courses are not available
          </h2>
        )}
        <AccordionList
          allsubcourse={allsubcourse}
          course={course}
          vcounter={vcounter}
          tcounter={tcounter}
          handleAutoSubscribe={handleAutoSubscribe}
          jwt_decode={jwt_decode}
          selectedcourses={selectedcourses}
        />
        <div class="d-flex align-items-center justify-content-end mt-2 mt-lg-4">
          <div>
            <Link
              class="text-primary h3 text-decoration-none mt-4 d-block"
              to={`/exploreCourses`}
              title="Back to course list"
            >
              Browse other categories
            </Link>
          </div>
        </div>
      </section>
      {/* <section className="bg-light-gray">
        <div class="container-fluid logo-container">
          {allsubcourse && allsubcourse.length > 0 && (
            <Slider {...settings}>{imgList}</Slider>
          )}
        </div>
      </section> */}
      <section class="bg-light-gray py-4">
        <LogoSlider />
      </section>
      {otherusers && otherusers.length > 0 && (
        <section class="">
          <div className="container">
            <div className="row">
              <div className="subscribe-slider position-relative">
                <h2 className="text-dark-gray font-weight-400 mb-md-4 mb-3">
                  Who else is learning
                </h2>
                <Slider {...settings2}>
                  {otherusers.map((item, index) => {
                    return (
                      <div className="subscribe-slide h-100" key={index}>
                        <div className="card mb-3 h-100">
                          <div className="row g-0 h-100">
                            {whoElseLearning(item, course)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
