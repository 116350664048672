import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ProductandCategory from '../../../services/ProductandCategory';

const CategoryDetails = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    let id = queryParams.get('id')
    const [formData, setFormData] = useState({ name: queryParams.get('name'), description: queryParams.get('description') });

    useEffect(() => {
        if (id) {
            ProductandCategory.getCategoryById(id).then(res => {
                setFormData(res.data)
            })
        }
    }, [])

    return (
        <div className='container'>
            <div className="d-flex justify-content-between align-items-center">
                <h2>Categories Details</h2>
                <Link to="/productcategory/list">
                    <button className="btn btn-success" >View Category list</button>
                </Link>

            </div>
            <div className='row'>
                {formData && formData.length > 0 ? formData.map(formData => (<>
                    <div className="card mt-5 mx-auto" style={{ width: '18rem' }}>
                        <div className="card-body">
                            <h4> Name:</h4>  <h6 className="card-title" dangerouslySetInnerHTML={{ __html: formData.name }} /><hr />
                            <h4>Description:</h4> <p className="card-text" dangerouslySetInnerHTML={{ __html: formData.description }} /><hr />
                        </div>
                    </div>
                </>)) : (<> <h5 dangerouslySetInnerHTML={{ __html: `No data found ${id}` }} /></>)}
            </div>
        </div >
    );
};

export default CategoryDetails;