import React, { useState, useEffect } from "react";
import LoginService from "../../services/LoginService";
import { useNavigate } from "react-router-dom";

export default function UserList() {
  const [allUsers, setAllUsers] = useState([]);
  const [filterUser, setFilterUser] = useState([]);
  const [search, setSearch] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    LoginService.getUsers(token)
      .then((res) => {
        if (res.data) {
          setAllUsers(res.data);
          setFilterUser(res.data);
        }
      })
      .catch((ex) => console.log(ex));
  }, []);

  const fetchUsers = (suser) => {
    setSearch(suser);

    if (suser === "") {
      setFilterUser(allUsers);
    } else {
      const filterUsers = allUsers.filter((item) =>
        item.username.includes(suser)
      );

      console.log(filterUsers);
      setFilterUser(filterUsers);
    }
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li className={"breadcrumb-item active"}>Video Progress</li>
          </ol>
        </nav>
      </div>
      <div className="container p-2 px-3 mb-5">
        <div className={"row p-3"}>
          <input
            className="col-lg-6 col-md-8 col-sm-12 py-2 me-5 "
            type="search"
            placeholder="Search by name"
            onChange={(e) => fetchUsers(e.target.value)}
            value={search}
          />
        </div>
        <div className="form px-lg-1">
          <div className="row g-4 py-4">
            {filterUser.map((item, index) => {
              return (
                <div className="col">
                  <div
                    className="card pt-4 pb-2 px-4 text-center d-flex justify-content-center align-items-center"
                    onClick={() => {
                      navigate(`/admin/user/${item._id}/video-progress`, {
                        state: item,
                      });
                    }}
                  >
                    <div
                      className={
                        "rounded-pill d-flex justify-content-center align-items-center text-capitalize text-white clr" +
                        (Math.floor(Math.random() * 7) + 1)
                      }
                      style={{
                        height: "3rem",
                        width: "3rem",
                        fontSize: "1.6rem",
                      }}
                    >
                      {item.username.charAt(0)}
                    </div>
                    <h6 className="card-title color-dgreen pt-3">
                      {item.username}
                    </h6>
                    <div className="text-center w-100 p-0">
                      <p
                        className="card-text color-dback"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item.email}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
