import React, { useEffect, useState } from "react";
import BatchManagementServices from "../../services/BatchManagementServices";
import CourseService from "../../services/CourseService";
import Select, { components } from "react-select";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { SiSonarqube } from "react-icons/si";
import { AiOutlineDelete } from "react-icons/ai";
import { MdCreateNewFolder } from "react-icons/md";
import MyToast from "../BsComponents/MyToast";
import { useNavigate, useParams } from "react-router-dom";
// import { Modal } from "react-bootstrap";
// import { FaTimes } from "react-icons/fa";
import { LuFolderEdit } from "react-icons/lu";

const dateInDDMMYYY = (date1) => {
  let date = new Date(date1);

  return `${date.getFullYear()}-${
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`
  }-${date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`}`;
};

export default function UpdateBatch() {
  const [updateData, setUpdateData] = useState({});

  const [allOptions, setAllOptions] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    BatchManagementServices.getBatchById(id, token)
      .then((res) => {
        const addSkillsInUsers = res.data.data.users.map((i) => {
          return { ...i, selectedSkills: [] };
        });
        const tempSelectedSkills = res.data.data.users.map((i) => {
          return { userId: i._id, selectedSkills: [] };
        });
        setSelectedSkills(tempSelectedSkills);
        const batchUpdateData = { ...res.data.data, users: addSkillsInUsers };
        setUpdateData(batchUpdateData);
        const temp1 = [];
        res.data.data._id &&
          res.data.data.skills.map((item) => {
            temp1.push({
              value: item.skillId.name,
              label: item.skillId.name,
              id: item.skillId._id,
              days:
                item.trainingPlansId?.length > 0
                  ? item.trainingPlansId.length
                  : "",
              weightage: item.weightage ? item.weightage : "",
              daysLength: item.trainingPlansId.length,
              daysError: "",
              trainingPlansId: item.trainingPlansId,
            });
            return;
          });
        setSkills([...temp1]);
        // skillsChange([...temp1]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    CourseService.getSubCourses().then((res) => {
      let data = res.data.map((item) => {
        return { value: item.name, label: item.name, id: item._id };
      });
      setAllOptions(data);
    });
  }, []);

  const MultiSkillDropDown = ({ skills, handleSelectChange, allOptions }) => {
    return (
      <div className="form-group mt-3">
        <label for="exampleInputPassword1">Select Skills</label>
        <SortableSelect
          axis="xy"
          onSortEnd={onSortEnd}
          distance={4}
          getHelperDimensions={({ node }) => node.getBoundingClientRect()}
          isMulti
          options={allOptions}
          value={skills && skills}
          onChange={handleSelectChange}
          components={{
            MultiValue: sortableMultiValue,
          }}
          closeMenuOnSelect={false}
        />
        {/* <small className="text-danger mt-1"> {errors.skills?.message}</small> */}
      </div>
    );
  };

  const handleSelectChange = (selectedOptions, e) => {
    if (e.action === "clear") {
      CourseService.getTrainingPlan([], token).then((res) => {
        setUpdateData({
          ...updateData,
          skills: selectedOptions,
          days: updateData.days + res.data.length,
        });
        setSkills(selectedOptions);
        // skillsChange(selectedOptions);
      });
    } else if (e.action === "remove-value") {
      CourseService.getTrainingPlan(e.removedValue.value, token).then((res) => {
        setUpdateData({
          ...updateData,
          skills: selectedOptions,
          days: updateData.days - res.data.length,
        });
        setSkills(selectedOptions);
        // skillsChange(selectedOptions);
      });
    } else if (e.action === "select-option") {
      CourseService.getTrainingPlan(e.option.value, token).then((res) => {
        const trainingPlans = res.data;
        const getProp = (prop) => (obj) => obj[prop];
        const getIds = getProp("_id");
        const trainingPlansIds = trainingPlans.map(getIds);
        e.option.days = res.data?.length > 0 ? res.data.length : "";
        e.option.daysLength = res.data?.length > 0 ? res.data.length : "";
        e.option.weightage = "";
        e.option.daysError = "";
        e.option.trainingPlansId = trainingPlansIds;
        setUpdateData({
          ...updateData,
          skills: selectedOptions,
          days: updateData.days + res.data.length,
        });
        setSkills(selectedOptions);
        // skillsChange(selectedOptions);
      });
    }
  };

  const handleEdit = (e) => {
    BatchManagementServices.getBatchById(e, token)
      .then((res) => {
        const users = res.data.data.users;
        const addSkillsInUsers = res.data.data.users.map((i) => {
          return { ...i, selectedSkills: [] };
        });
        const tempSelectedSkills = res.data.data.users.map((i) => {
          return { userId: i._id, selectedSkills: [] };
        });
        setSelectedSkills(tempSelectedSkills);
        const batchUpdateData = { ...res.data.data, users: addSkillsInUsers };
        setUpdateData(batchUpdateData);
        const temp1 = [];
        res.data.data._id &&
          res.data.data.skills.map((item) => {
            temp1.push({
              value: item.skillId.name,
              label: item.skillId.name,
              id: item.skillId._id,
              days:
                item.trainingPlansId?.length > 0
                  ? item.trainingPlansId.length
                  : "",
              weightage: item.weightage ? item.weightage : "",
              daysLength: item.trainingPlansId.length,
              daysError: "",
              trainingPlansId: item.trainingPlansId,
            });
            return;
          });
        setSkills([...temp1]);
        // skillsChange([...temp1]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }
  const sortableMultiValue = SortableElement((props) => {
    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  });
  const SortableSelect = SortableContainer(Select);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(skills, oldIndex, newIndex);
    setSkills(newValue);
    // skillsChange(newValue);
    setUpdateData({ ...updateData, skills: newValue });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let updatedData = {
      bname: updateData.name,
      skills: skills,
      startDate: new Date(updateData.startDate).toISOString(),
      days: updateData.days,
      status: updateData.status,
    };
    BatchManagementServices.updateBatch(updateData._id, updatedData, token)
      .then((res) => {
        alert("Batch Updated Succesfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 py-2">
            <li
              className={"breadcrumb-item text-primary"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/admin/batch-management/manage");
              }}
            >
              Batch
            </li>
            <li className={"breadcrumb-item active"}>Update Batch</li>
          </ol>
        </nav>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col col-4">
            <form method="post">
              <h3 className="text-center">Edit Batch</h3>
              <div className="form-group mt-4">
                <label for="exampleInputEmail1">Batch Name</label>
                <input
                  // {...register("batchName")}
                  type="text"
                  className="form-control mt-1"
                  placeholder="Enter Batch Name"
                  value={updateData.name}
                  onChange={(e) => {
                    setUpdateData({ ...updateData, name: e.target.value });
                  }}
                />
              </div>
              <div className="form-group mt-3">
                <label>Start Date</label>
                <input
                  // {...register("startDate")}
                  value={dateInDDMMYYY(updateData.startDate)}
                  type="date"
                  className="form-control mt-1"
                  onChange={(e) => {
                    // setDate(e.target.value);
                    setUpdateData({
                      ...updateData,
                      startDate: new Date(e.target.value).toISOString(),
                    });
                  }}
                />
                {/* <small className="text-danger mt-1"> {errors.startDate?.message}</small> */}
              </div>
              <MultiSkillDropDown
                allOptions={allOptions}
                handleSelectChange={handleSelectChange}
                skills={skills}
              />
              <div className="form-check form-switch mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="disableSwitch"
                  checked={updateData.status}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      status: !updateData.status,
                    })
                  }
                />{" "}
                <label>Active</label>
              </div>
              {/* <div className="form-group mt-4">
                  
                  {console.log("skillDataaaaaaaa", skillData, "skills", skills)}
                  {skills.length === skillData.length
                    ? skillData.map((elem, index, array) => {
                       
                        if (elem !== undefined && elem.day && elem.day > 0) {
                          return (
                            <>
                              <label for="exampleInputEmail1">
                                {elem.name} Days
                              </label>
                              <input
                                type="text"
                                value={elem.day}
                                onChange={(event) =>
                                  changeDays(event, elem, index, array)
                                }
                                className="form-control mt-1"
                              />
                            </>
                          );
                        }
                      })
                    : null}
                </div> */}

              <div className="mt-3 ">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Edit
                </button>
              </div>
            </form>
          </div>
          <div className="col col-8">
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Username</th>
                    <th scope="col">Select Skills</th>
                    <th scope="col" className="text-center">
                      Delete User
                    </th>
                    <th scope="col" className="text-center">
                      Sonar
                    </th>
                    <th scope="col" className="text-center">
                      Repo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {updateData.users &&
                    updateData.users.length > 0 &&
                    updateData.users.map((item, index) => {
                      if (item.deactivate) {
                        return <></>;
                      }
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{item.username}</td>
                          <td>
                            <SortableSelect
                              axis="xy"
                              onSortEnd={onSortEnd}
                              distance={4}
                              getHelperDimensions={({ node }) =>
                                node.getBoundingClientRect()
                              }
                              isMulti
                              options={skills}
                              value={
                                selectedSkills.find(
                                  (i) => i.userId === item._id
                                )?.selectedSkills
                              }
                              onChange={(values) => {
                                const temp = [...selectedSkills];
                                const findUserInSelectedSkills = temp.find(
                                  (i) => i.userId === item._id
                                );
                                findUserInSelectedSkills.selectedSkills =
                                  values;
                                setSelectedSkills(temp);
                              }}
                              components={{
                                MultiValue: sortableMultiValue,
                              }}
                              closeMenuOnSelect={false}
                            />
                            {/* <small className="text-danger mt-1"> {errors.skills?.message}</small> */}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <div className="">
                                <div className="d-flex justify-content-center">
                                  <div className="">
                                    <DeleteUser
                                      handleEdit={handleEdit}
                                      item={item}
                                      userDataId={updateData._id}
                                      key={index}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <div className="">
                                <div className="d-flex justify-content-center">
                                  <div className="">
                                    <SonarCreate
                                      item={item}
                                      batchName={updateData.name}
                                      key={index}
                                      sonarStatus={item.sonarProject}
                                    />
                                  </div>
                                  {/* <div className=""></div> */}
                                  {/* <div className="">
                                    <SonarDelete
                                      item={item}
                                      batchName={updateData.name}
                                      key={index}
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <div>
                                <RepoCreate
                                  item={item}
                                  batchName={updateData.name}
                                  key={index}
                                  skills={
                                    selectedSkills.find(
                                      (i) => i.userId === item._id
                                    )?.selectedSkills
                                  }
                                  repoStatus={item.repoProject}
                                />
                              </div>
                              {/* <div>
                                <RepoDelete
                                  item={item}
                                  batchName={updateData.name}
                                  key={index}
                                />
                              </div> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const DeleteUser = ({ handleEdit, item, userDataId }) => {
  const [status, setStatus] = useState({
    loading: false,
  });

  const removeUser = async (event, userId, batchId) => {
    let confirm = window.confirm("Are you sure, you want to remove this user?");
    if (confirm) {
      setStatus({ ...status, loading: true });
      try {
        let res = await BatchManagementServices.removeUserFromBatch(
          { batchId, userId },
          localStorage.getItem("token")
        );
        if (res.status === 200) {
          setStatus({ ...status, loading: false });
          handleEdit(batchId);
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  return (
    <div>
      {!status.loading ? (
        <button
          className="btn btn-sm btn-outline-danger rounded-circle mx-1"
          onClick={(e) => {
            removeUser(e, item._id, userDataId);
          }}
        >
          <AiOutlineDelete />
        </button>
      ) : (
        <>
          <div className="spinner-border text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </>
      )}
    </div>
  );
};

// const CreateModal = ({
//   status,
//   message,
//   handleSonarCreate,
//   closeMcqModel,
//   props,
// }) => {
//   return (
//     <>
//       <Modal
//         show={status}
//         onHide={closeMcqModel}
//         class="mt-5"
//         size="sl"
//         aria-labelledby="ModalHeader"
//       >
//         <Modal.Header>
//           <Modal.Title></Modal.Title>
//           <button
//             onClick={() => {
//               closeMcqModel();
//             }}
//           >
//             <FaTimes />
//           </button>
//         </Modal.Header>
//         <Modal.Body>
//           <div>{message}</div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button
//             onClick={() => {
//               closeMcqModel();
//               handleSonarCreate(
//                 props.item.username,
//                 props.item?.fname,
//                 props.item?.lname,
//                 props.item?.empId,
//                 props.item?.githubUserName
//               );
//             }}
//           >
//             Ok
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

const SonarCreate = (props) => {
  const [status, setStatus] = useState({
    loading: false,
    status: "",
    processed: false,
    msg: "",
  });

  const handleSonarCreate = async (
    username,
    fName,
    lName,
    empCode,
    githubUserName
  ) => {
    try {
      setStatus({ ...status, loading: true });
      const batchName = props.batchName.trim().split("-");
      const suffix = batchName[1] + batchName[2];
      // const suffix = batchName[1] + "-" + batchName[2];
      let firstName = fName;
      let lastName = lName;
      if (!firstName || !lastName) {
        const names = username.trim().split(" ");
        firstName = names[0];
        lastName = names[1];
      }

      if (
        firstName &&
        lastName &&
        empCode &&
        githubUserName &&
        suffix &&
        suffix.length > 0
      ) {
        const createSonar = await BatchManagementServices.createSonar(
          firstName,
          lastName,
          empCode,
          githubUserName,
          suffix,
          localStorage.getItem("token")
        );
        setStatus({
          ...status,
          loading: false,
          status: "success",
          msg: `Sonar created for ${firstName} ${lastName}`,
          processed: true,
        });
      } else {
        setStatus({
          ...status,
          loading: false,
          status: "warning",
          msg: "Please provide all values",
          processed: true,
        });
      }
    } catch (error) {
      setStatus({
        ...status,
        loading: false,
        status: "danger",
        processed: true,
        msg: error["response"]?.data?.message,
      });
      // console.log("error creating sonar", error?.response?.data);
    }
  };

  return (
    <>
      {!status.loading ? (
        <>
          <button
            className="btn btn-sm btn-outline-warning rounded-circle mx-1"
            disabled={props.sonarStatus ? true : false}
            onClick={() => {
              handleSonarCreate(
                props.item.username,
                props.item?.fname,
                props.item?.lname,
                props.item?.empId,
                props.item?.githubUserName
              );
            }}
          >
            <SiSonarqube />
          </button>
        </>
      ) : (
        <>
          <div
            className="spinner-border text-warning m-0 p-0"
            id={`sonarCreateToast${props.key}`}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </>
      )}
      {status.processed && !status.loading && (
        <MyToast
          id={`sonarCreateToast${props.key}`}
          show={status.processed && !status.loading}
          status={status.status}
          msg={status.msg}
          header={"Create Sonar"}
          icon={<SiSonarqube />}
          setShow={(show) => setStatus({ ...status, processed: show })}
        ></MyToast>
      )}
    </>
  );
};

// const SonarDelete = (props) => {
//   const [status, setStatus] = useState({
//     loading: false,
//     status: "",
//     processed: false,
//     msg: "",
//   });

//   const handleSonarDelete = async (
//     username,
//     fName,
//     lName,
//     empCode,
//     githubUserName
//   ) => {
//     try {
//       let confirm = window.confirm(
//         "Are you sure, you want to delete this sonar?"
//       );
//       if (!confirm) {
//         return;
//       }
//       setStatus({ ...status, loading: true });

//       const batchName = props.batchName.trim().split("-");
//       // const suffix = batchName[1] + "-" + batchName[2];
//       const suffix = batchName[1] + batchName[2];
//       let firstName = fName;
//       let lastName = lName;
//       if (!firstName || !lastName) {
//         const names = username?.trim().split(" ");
//         if (names.length > 0) {
//           firstName = names[0];
//           lastName = names[1];
//         }
//       }

//       if (
//         firstName &&
//         lastName &&
//         empCode &&
//         githubUserName &&
//         suffix &&
//         suffix.length > 0
//       ) {
//         const deleteSonar = await BatchManagementServices.deleteSonar(
//           firstName,
//           lastName,
//           empCode,
//           githubUserName,
//           suffix,
//           localStorage.getItem("token")
//         );
//         setStatus({
//           ...status,
//           loading: false,
//           status: "success",
//           msg: `Sonar deleted for ${firstName} ${lastName}`,
//           processed: true,
//         });
//       } else {
//         setStatus({
//           ...status,
//           loading: false,
//           status: "warning",
//           msg: "Please provide all values",
//           processed: true,
//         });
//       }
//     } catch (error) {
//       setStatus({
//         ...status,
//         loading: false,
//         status: "danger",
//         processed: true,
//         msg:
//           error?.response?.data?.error ||
//           error?.response?.data?.msg ||
//           "Something went wrong",
//       });
//       // console.log("error deleting sonar", error?.response?.data || error);
//     }
//   };
//   return (
//     <>
//       {!status.loading ? (
//         <button
//           className="btn btn-sm btn-outline-danger rounded-circle mx-1"
//           onClick={() => {
//             handleSonarDelete(
//               props.item.username,
//               props.item?.fname,
//               props.item?.lname,
//               props.item?.empId,
//               props.item?.githubUserName
//             );
//           }}
//         >
//           <AiOutlineDelete />
//         </button>
//       ) : (
//         <div className="spinner-border text-danger" role="status">
//           <span className="visually-hidden">Loading...</span>
//         </div>
//       )}
//       {status.processed && !status.loading && (
//         <MyToast
//           id={`sonarDeleteToast${props.key}`}
//           show={status.processed && !status.loading}
//           status={status.status}
//           msg={status.msg}
//           header={"Delete Sonar"}
//           icon={<AiOutlineDelete />}
//           setShow={(show) => setStatus({ ...status, processed: show })}
//         ></MyToast>
//       )}
//       {status.processed && !status.loading && (
//         <MyToast
//           id={`sonarDeleteToast${props.key}`}
//           show={status.processed && !status.loading}
//           status={status.status}
//           msg={status.msg}
//           header={"Delete Sonar"}
//           icon={<AiOutlineDelete />}
//           setShow={(show) => setStatus({ ...status, processed: show })}
//         ></MyToast>
//       )}
//     </>
//   );
// };

const RepoCreate = (props) => {
  const [status, setStatus] = useState({
    loading: false,
    status: "",
    processed: false,
    msg: "",
  });

  const handleRepoCreate = async (
    username,
    fName,
    lName,
    empCode,
    githubUserName
  ) => {
    try {
      setStatus({ ...status, loading: true });
      const batchName = props.batchName.trim().split("-");
      const suffix = batchName[1] + "-" + batchName[2];
      let firstName = fName;
      let lastName = lName;
      if (!firstName || !lastName) {
        const names = username.trim().split(" ");
        firstName = names[0];
        lastName = names[1];
      }

      if (
        firstName &&
        lastName &&
        empCode &&
        githubUserName &&
        suffix &&
        suffix.length > 0
      ) {
        const createRepo = await BatchManagementServices.createRepo(
          localStorage.getItem("token"),
          firstName,
          lastName,
          empCode,
          githubUserName,
          suffix,
          props.skills.map((i) => i.id) || []
        );

        setStatus({
          ...status,
          loading: false,
          status: "success",
          msg: `Repo created for ${firstName} ${lastName}`,
          processed: true,
        });
      } else {
        setStatus({
          ...status,
          loading: false,
          status: "warning",
          msg: "Please provide all values",
          processed: true,
        });
      }
    } catch (error) {
      console.log(error['response']);
      setStatus({
        ...status,
        loading: false,
        status: "danger",
        processed: true,
        msg:
          error?.response?.data?.error ||
          error?.response?.data?.msg ||
          "Something went wrong",
      });
      // console.log("error creating repo", error?.response?.data);
    }
  };

  return (
    <>
      {!status.loading ? (
        <button
          className={
            props.repoStatus
              ? "btn btn-sm btn-outline-primary rounded-circle mx-1"
              : "btn btn-sm btn-outline-warning rounded-circle mx-1"
          }
          disabled={
            props.repoStatus ? (props.skills?.length > 0 ? false : true) : false
          }
          onClick={() => {
            handleRepoCreate(
              props.item.username,
              props.item?.fname,
              props.item?.lname,
              props.item?.empId,
              props.item?.githubUserName
            );
          }}
        >
          {props.repoStatus ? <LuFolderEdit /> : <MdCreateNewFolder />}
        </button>
      ) : (
        <div className="spinner-border text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
      {status.processed && !status.loading && (
        <MyToast
          id={`sonarDeleteToast${props.key}`}
          show={status.processed && !status.loading}
          status={status.status}
          msg={status.msg}
          header={"Create Repo"}
          icon={<MdCreateNewFolder />}
          setShow={(show) => setStatus({ ...status, processed: show })}
        ></MyToast>
      )}
      {status.processed && !status.loading && (
        <MyToast
          id={`sonarDeleteToast${props.key}`}
          show={status.processed && !status.loading}
          status={status.status}
          msg={status.msg}
          header={"Create Repo"}
          icon={<MdCreateNewFolder />}
          setShow={(show) => setStatus({ ...status, processed: show })}
        ></MyToast>
      )}
    </>
  );
};

// const RepoDelete = (props) => {
//   const [status, setStatus] = useState({
//     loading: false,
//     status: "",
//     processed: false,
//     msg: "",
//   });

//   const handleRepoDelete = async (
//     username,
//     fName,
//     lName,
//     empCode,
//     githubUserName
//   ) => {
//     try {
//       let confirm = window.confirm(
//         "Are you sure, you want to delete this repo?"
//       );
//       if (!confirm) {
//         return;
//       }
//       setStatus({ ...status, loading: true });
//       const batchName = props.batchName.trim().split("-");
//       const suffix = batchName[1] + "-" + batchName[2];
//       let firstName = fName;
//       let lastName = lName;
//       if (!firstName || !lastName) {
//         const names = username.trim().split(" ");
//         firstName = names[0];
//         lastName = names[1];
//       }

//       if (
//         firstName &&
//         lastName &&
//         empCode &&
//         githubUserName &&
//         suffix &&
//         suffix.length > 0
//       ) {
//         const deleteRepo = await BatchManagementServices.deleteRepo(
//           firstName,
//           lastName,
//           empCode,
//           githubUserName,
//           suffix,
//           localStorage.getItem("token")
//         );

//         setStatus({
//           ...status,
//           loading: false,
//           status: "success",
//           msg: `Repo deleted for ${firstName} ${lastName}`,
//           processed: true,
//         });
//       } else {
//         setStatus({
//           ...status,
//           loading: false,
//           status: "warning",
//           msg: "Please provide all values",
//           processed: true,
//         });
//       }
//     } catch (error) {
//       setStatus({
//         ...status,
//         loading: false,
//         status: "danger",
//         processed: true,
//         msg:
//           error?.response?.data?.error ||
//           error?.response?.data?.msg ||
//           "Something went wrong",
//       });
//       // console.log("error deleting repo", error?.response?.data);
//     }
//   };

//   return (
//     <>
//       {!status.loading ? (
//         <button
//           className="btn btn-sm btn-outline-danger rounded-circle mx-1"
//           onClick={() => {
//             handleRepoDelete(
//               props.item.username,
//               props.item?.fname,
//               props.item?.lname,
//               props.item?.empId,
//               props.item?.githubUserName
//             );
//           }}
//         >
//           <AiOutlineDelete />
//         </button>
//       ) : (
//         <div className="spinner-border text-danger" role="status">
//           <span className="visually-hidden">Loading...</span>
//         </div>
//       )}
//       {status.processed && !status.loading && (
//         <MyToast
//           id={`sonarDeleteToast${props.key}`}
//           show={status.processed && !status.loading}
//           status={status.status}
//           msg={status.msg}
//           header={"Delete Repo"}
//           icon={<AiOutlineDelete />}
//           setShow={(show) => setStatus({ ...status, processed: show })}
//         ></MyToast>
//       )}
//       {status.processed && !status.loading && (
//         <MyToast
//           id={`sonarDeleteToast${props.key}`}
//           show={status.processed && !status.loading}
//           status={status.status}
//           msg={status.msg}
//           header={"Delete Repo"}
//           icon={<AiOutlineDelete />}
//           setShow={(show) => setStatus({ ...status, processed: show })}
//         ></MyToast>
//       )}
//     </>
//   );
// };
