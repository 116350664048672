import React, { useEffect, useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import ShoWHideToggle from "../showHideToggle/ShowHideToggle";

const CoursePPT = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [allcategory, setallcategory] = useState([]);
  const [dppt, setDppt] = useState([]);
  const [courseppts, setCourseppts] = useState({
    _id: "",
    ppts: [],
    cid: "",
  });

  const [subcoursename, setSubCoursename] = useState("Select SubCourses");
  const [subCourseValue, setSubCourseValue] = useState("");

  const [msg, setMsg] = useState("");
  const [courseId, setCourseId] = useState("");
  const [dSelectStatus, setDeselectStatus] = useState(false);
  const [handleDeletePopup, setHandleDeletePopup] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    setSubCoursename(`Select ${state} SubCourses`);
    getPPTs(state);
    CourseService.getSinglesubcourse(id).then((res) => {
      if (res.data) {
        setallcategory(res.data);
      }
    });
  }, []);

  const getPPTs = (cname) => {
    CourseService.getPpts(cname, token)
      .then((res) => {
        if (res.data) {
          setCourseppts(res.data);
          setConfirmBox(false);
          if (!!res.data.ppts && res.data.ppts.length === 0) {
            setMsg("ppts are not available");

            setConfirmBox(true);
            setTimeout(() => {
              setConfirmBox(false);
            }, 2000);
          }
        } else {
          setCourseppts([]);
          setMsg("ppts are not available");

          setConfirmBox(true);
          setTimeout(() => {
            setConfirmBox(false);
          }, 2000);
        }
      })
      .catch((ex) => console.log(ex));
  };

  const handleSubCourseChange = (cname, e) => {
    setSubCourseValue(cname);
    const token = localStorage.getItem("token");
    if (e.target.id === "subcourse") {
      if (!token) {
        navigate("/login");
      } else {
        getPPTs(cname);
      }
    }
  };

  const handlePptcheck = (e, ppt) => {
    const target = e.target;

    if (target.checked) {
      setDppt((d) => [...d, ppt]);
    } else {
      const uppts = dppt.filter((item) => item !== ppt);
      setDppt(uppts);
    }
  };

  const handleUploadPpts = async (e) => {
    let id = courseppts.cid;
    let ppts = e.target.files;

    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (id && ppts.length > 0) {
        setLoading(true);

        const upCppt = [];
        // Uploading ppts to cloudinary
        for (const ppt of ppts) {
          if (ppt.type !== "" && (ppt.name.split(".").includes('pptx') || ppt.name.split(".").includes('ppt'))) {
            const formdata = new FormData();
            formdata.append("file", ppt);
            formdata.append("upload_preset", "jeybxizi");

            // remove space if subcourse have any
            const foldername = subcoursename.split(" ").join("")
            formdata.append("folder", `/ppt/${foldername}`)

            try {
              const result = await CourseService.postPpts(formdata);
              let removestring = result.data.url.split("/")
              const lastThreeElements = removestring.slice(-2);
              const resultsting = lastThreeElements.join('/');
              // upCppt.push({ filename: result.data.url });
              upCppt.push({ filename: resultsting })

            } catch (error) {
              console.log(error.response);
            }

            setMsg("PPT uploaded successfully..");
            setConfirmBox(true);
          } else {
            setMsg("file required in .ppt or .pptx");
            setConfirmBox(true);
          }
        }

        ppts = upCppt;

        if (ppts.length > 0) {
          try {
            const res = await CourseService.postCourseppt({ id, ppts }, token);

            if (res.data.msg) {
              getPPTs(subCourseValue);
              // setConfirmBox(true);
            }
            await setMsg("PPT uploaded successfully")
          } catch (ex) {
            console.log(ex);
          }
        }
        else {
          await setMsg("file required in .ppt or .pptx")
        }
        setLoading(false);
      }
      e.target.value = "";
    }
    {
      setTimeout(() => setConfirmBox(false), 5000);
    }
  };

  const handleDeletePpts = async () => {
    const ans = window.confirm(`Are you sure you want to delete selected PPT?`);
    if (!ans) {
      return;
    }
    if (ans) {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
      } else {
        const { cid } = courseppts;

        if (cid && dppt.length > 0) {
          // setLoading(true);
          let upPpt = courseppts;
          const deletedPpts = courseppts.courseppt.filter((item) =>
            dppt.includes(item)
          );

          const ppts = courseppts.courseppt.filter((item) => !dppt.includes(item));

          upPpt.courseppt = ppts;
          // upPpt.dppts = dppt;
          try {
            const res = await CourseService.removePpt(
              upPpt,
              deletedPpts,
              token
            );

            // setLoading(false);
            if (res.data.msg) {
              setConfirmBox(true);
              const { ppts, _id, cid } = res.data.Courseppt;
              setCourseppts({
                ppts: ppts,
                _id: _id,
                cid: cid,
              });
              setMsg("PPTS deleted successfully..");
              setTimeout(() => setConfirmBox(false), 2000);
            }
          } catch (ex) {
            console.log(ex);
          }

          setDppt([]);
        }
      }
    }
  };

  const pptUrl = (url) => {
    if (!url.split("/").includes("res.cloudinary.com"))
      return "https://res.cloudinary.com/change/raw/upload/ppt/" + url
    else {
      return url
    }
  }

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li
              classNamuploadere={"breadcrumb-item text-primary"}
              onClick={() => {
                navigate("/admin/course/ppt");
              }}
              style={{ cursor: "pointer" }}
            >
              PPT
            </li>
            <li className={"breadcrumb-item active"}>/{state}</li>
          </ol>
        </nav>
        <label htmlFor="upppt" className={"btn bg-green text-white visible"}>
          <FaCloudUploadAlt className="me-2 fs-5" />
          Upload
        </label>
        <input
          type="file"
          id="upppt"
          className="d-none"
          multiple
          accept=".pptx,.ppt"
          onChange={handleUploadPpts}
        />
      </div>
      <div className="container p-2 px-3 mb-5">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Uploading PPTS...</div>
            </div>
          </div>
        )}
        {confirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div
                  className={
                    msg === "PPT uploaded successfully.."
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                >
                  {msg}
                </div>
              </div>
            </div>
          </div>
        )}
        {dppt?.length > 0 && !dSelectStatus ? (
          <>
            <ShoWHideToggle
              videoAndPPT={dppt}
              allVideoAndPPT={courseppts.courseppt}
              setVideoAndPPT={setDppt}
              setDSelectPPTAndVideo={setDeselectStatus}
              setHandleDeletePopup={setHandleDeletePopup}
              handleRemovePPTAndVideo={handleDeletePpts}
            />
          </>
        ) : (
          handleDeletePopup && (
            <ShoWHideToggle
              videoAndPPT={dppt}
              allVideoAndPPT={courseppts.courseppt}
              setVideoAndPPT={setDppt}
              setDSelectPPTAndVideo={setDeselectStatus}
              setHandleDeletePopup={setHandleDeletePopup}
              handleRemovePPTAndVideo={handleDeletePpts}
            />
          )
        )}

        <div className="form px-lg-5 pt-3">
          <div>
            {allcategory[0] !== undefined && (
              <div className="dropdown">
                <button
                  class="btn bg-primary text-white dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {subcoursename}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {allcategory.map((cat, index) => {
                    return (
                      <li>
                        <a
                          className="dropdown-item"
                          id="subcourse"
                          href="javascript:void(0)"
                          onClick={(e) => {
                            setSubCoursename(cat.name);
                            setDppt([]);
                            handleSubCourseChange(cat.name, e);
                          }}
                        >
                          {cat.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <form className="py-3" method="post" id="myform">
              <div className="row listppts g-4">
                {!!courseppts.courseppt && courseppts.courseppt[0]
                  ? courseppts.courseppt.map((item, index) => {
                    return (
                      <div className="col-lg-4" key={index}>
                        <div className="card rounded">
                          <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${pptUrl(item.filename)}&embedded=true`}
                            width="100%"
                            height="210"
                          ></iframe>
                          <div className="card-body">
                            <h6 className="card-title color-dgreen">
                              Lesson {index + 1}
                            </h6>
                          </div>
                          <div className="checkheader">
                            <input
                              type="checkbox"
                              checked={
                                dppt?.find((dp) => dp === item) ? true : false
                              }
                              id={"check" + index}
                              className="form-check-input p-2 pptcheck"
                              onChange={(e) => {
                                handlePptcheck(e, item);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursePPT;
