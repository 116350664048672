import React, { useState, useEffect } from "react";
import formloader from "../../images/formloading.svg";
import jwt_decode from "jwt-decode";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import {
  FaCheck,
  FaTimes,
  FaPen,
  FaChevronLeft,
  FaChevronRight,
  FaSpinner,
  FaCheckSquare,
  FaWindowClose,
} from "react-icons/fa";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCoffee, Faspinner } from "@fortawesome/free-solid-svg-icons";

import Select from "react-select";
import { useNavigate } from "react-router-dom";

export default function ManageRequest(props) {
  const navigate = useNavigate();
  const [page, setPage] = useState({ selected: 1, choice: "" });
  const [loading, setLoading] = useState(false);
  const [allRequest, setAllRequest] = useState([]);
  const [someusers, setsomeUsers] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [confirmBox1, setConfirmBox1] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [msg, setMsg] = useState({ message: "", status: false });
  const [msg1, setMsg1] = useState("");
  const [trainingplan, setTrainingplan] = useState({
    cid: "",
    trainerId: jwt_decode(localStorage.getItem("token")).id,
    contentName: "",
    Remark: "",
    Status: "Pending",
  });
  const [scnames, setSCnames] = useState([]);
  const [validate, setValidate] = useState(false);
  const [visible, setVisible] = useState(false);
  const [updateData, setUpdateData] = useState("");
  const [search, setSearch] = useState("");
  const [contentId, setContentId] = useState("");
  const [user, setUser] = useState([]);
  const [userid, setUserId] = useState("");
  const Options = [
    { label: "Approve", value: "status1" },
    { label: "Reject", value: "status2" },
    { label: "Pending", value: "status3" },
  ];

  useEffect(() => {
    setTimeout(() => {
      setConfirmBox(false);
    }, 3000);
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      CourseService.getAllContent(token).then((res) => {
        if (res.data && res.data.length > 0) {
          const trainerData = res.data.filter(
            (item) => item.trainerId._id === trainingplan.trainerId
          );
          setAllRequest(trainerData.reverse());
          setsomeUsers(trainerData.reverse());
        } else {
          setMsg1("Requests Are Not Founded");
        }
      });
      CourseService.getSubCourses().then((res) => {
        if (res.data) {
          setSCnames(res.data);
        }
      });
      LoginService.getTrainers(token).then((res) => {
        if (res.data) {
          setUser(res.data);
        }
      });
    }
    // window.addEventListener("scroll", toggleVisible);
  }, [confirmBox, confirmBox1]);

  const handleChange = (e) => {
    if (e == null) {
      setConfirmBox1(!confirmBox1);
    } else {
      const filterData = allRequest.filter((item) => item.Status === e.label);
      if (filterData && filterData.length > 0) {
        setAllRequest(filterData.reverse());
      } else {
        setMsg1("No Data Avilable");
        setAllRequest([]);
      }
    }
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 150) {
      setVisible(true);
    } else if (scrolled <= 10) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (
        trainingplan.cid !== "" &&
        trainingplan.contentName !== "" &&
        trainingplan.Remark !== ""
      ) {
        CourseService.postAddContent(trainingplan, token).then((res) => {
          setMsg({ message: "Content are uploaded", status: true });
          setConfirmBox(true);
          setTrainingplan({
            cid: "",
            trainerId: jwt_decode(localStorage.getItem("token")).id,
            contentName: "",
            Remark: "",
            Status: "Pending",
          });
        });
      } else {
        setMsg({
          message: `your ${trainingplan.cid === "" ? "Subcources " : ""} ${
            trainingplan.contentName === "" ? "Content Name ," : ""
          } ${
            trainingplan.Remark === "" ? "Remark" : ""
          } filed are not available`,
          status: false,
        });
        setConfirmBox(true);
      }
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (
        trainingplan.cid !== "" &&
        trainingplan.contentName !== "" &&
        trainingplan.Remark !== ""
      ) {
        CourseService.putContent(trainingplan, contentId, token).then((res) => {
          setMsg({
            message: "Your Content update Request are sent",
            status: true,
          });
          setConfirmBox(true);
          setUpdateData("");
          setTrainingplan({
            cid: "",
            trainerId: jwt_decode(localStorage.getItem("token")).id,
            contentName: "",
            Remark: "",
            Status: "Pending",
          });
        });
      } else {
        setMsg({
          message: `your ${trainingplan.cid === "" ? "Subcources " : ""} ${
            trainingplan.contentName === "" ? "Content Name ," : ""
          } ${
            trainingplan.Remark === "" ? "Remark" : ""
          } filed are not available`,
          status: false,
        });
        setConfirmBox(true);
      }
    }
  };

  const handlecount = (count) => {
    setAllRequest(
      someusers.map((item, index) => {
        if (index >= count * 10 - 10 && index < count * 10) {
          return item;
        }
      })
    );
    setPageno(count);
  };

  const handlePagination = (useritem) => {
    return (
      page.selected === 1 && (
        <div className="col-lg-12 d-flex justify-content-center">
          <nav className="mt-4 p-0 pagefooter">
            <ul className="pagination row g-1" id="pageno">
              <li className="page-item col-auto">
                <button
                  className="btn page-link px-3 py-2 shadow-none"
                  disabled={pageno === 1 ? true : false}
                  onClick={() => {
                    handlecount(pageno - 1);
                  }}
                >
                  <FaChevronLeft className="text-muted" />
                </button>
              </li>
              {(() => {
                const element = [];

                for (
                  let index = 1;
                  index <= Math.ceil(useritem.length / 10);
                  index++
                ) {
                  element.push(
                    <li className="page-item col-auto">
                      <button
                        className={
                          pageno === index
                            ? "page-link rounded-pill px-3 py-2 active bg-primary text-white shadow-none"
                            : "page-link rounded-pill border border-primary px-3 py-2 "
                        }
                        onClick={() => {
                          handlecount(index);
                        }}
                      >
                        {index}
                      </button>
                    </li>
                  );
                }
                return element;
              })()}
              <li className="page-item col-auto">
                <button
                  className="btn page-link px-3 py-2 shadow-none"
                  disabled={
                    pageno === Math.ceil(useritem.length / 10) ? true : false
                  }
                  onClick={() => {
                    handlecount(pageno + 1);
                  }}
                >
                  <FaChevronRight className="text-muted" />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )
    );
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li
              className={
                page.selected === 1
                  ? "breadcrumb-item active"
                  : "breadcrumb-item text-primary"
              }
              style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
              onClick={() => {
                setPage((d) => ({ selected: 1, choice: "" }));
              }}
            >
              All Request
            </li>
            <li
              className={
                page.selected === 2
                  ? "breadcrumb-item active d-block"
                  : "d-none"
              }
              style={{ cursor: page.selected > 2 ? "pointer" : "auto" }}
            >
              Add Content
            </li>
            <li
              className={
                page.selected === 3
                  ? "breadcrumb-item active d-block"
                  : "d-none"
              }
              style={{ cursor: page.selected > 3 ? "pointer" : "auto" }}
            >
              / Update Content
            </li>
          </ol>
        </nav>
        <label
          htmlFor={page.selected === 3 ? "uvids" : "abc"}
          className={
            page.selected == 1
              ? "btn bg-green text-white visible"
              : "btn bg-green text-white invisible"
          }
          style={{ marginRight: "4px" }}
          onClick={(e) => {
            setPage({ selected: 2 });
            setUpdateData("");
            setTrainingplan({
              cid: "",
              trainerId: jwt_decode(localStorage.getItem("token")).id,
              contentName: "",
              Remark: "",
              Status: "Pending",
            });
          }}
        >
          Create Content Request
        </label>
      </div>
      <div class="container my-2 bg-white p-4">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">
                Uploading Training plan New Content...
              </div>
            </div>
          </div>
        )}
        {confirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div
                  className={`alert ${
                    msg.status ? "alert-success" : "alert-danger"
                  }`}
                >
                  {msg.message}
                </div>
              </div>
            </div>
          </div>
        )}
        <div class="form px-lg-5">
          {page.selected === 1 ? (
            <>
              <h4 class="mt-2">All Requests</h4>
              <div class="mt-4 row">
                <input
                  className="col-lg-8 col-md-4 col-sm-12 py-2 me-5"
                  type="search"
                  placeholder="search by Course name"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <div className="col-lg-3 col-md-4 col-sm-12 ms-5">
                  <Select
                    placeholder="Apply Filters ..."
                    isClearable={true}
                    options={Options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {allRequest && allRequest.length > 0 ? (
                <table class="table mt-4">
                  <thead>
                    <tr>
                      <th scope="col">#</th>

                      <th scope="col">Admin Name</th>

                      <th scope="col">Course</th>

                      <th scope="col">Content</th>

                      <th scope="col">Remark</th>
                      <th scope="col">day_number</th>
                      <th scope="col">AdminRemark</th>
                      <th scope="col">Status</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>

                  <tbody>
                    {allRequest
                      .filter((item) => {
                        if (search === "") {
                          return item;
                        } else if (
                          item.cid.name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, index) => {
                        if (item !== undefined && index < pageno * 10) {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              {item.Status !== "Pending" ? (
                                <td>{item.adminId.username}</td>
                              ) : (
                                <td>
                                  <FaSpinner className="fs-5 text-danger " />
                                </td>
                              )}
                              <td>{item.cid.name}</td>
                              <td>{item.contentName}</td>
                              <td>{item.Remark}</td>
                              {item.Status !== "Pending" ? (
                                item.Status === "Approve" ? (
                                  <td>{item.dayNumber}</td>
                                ) : (
                                  <td>
                                    <FaWindowClose className="fs-5 text-danger" />
                                  </td>
                                )
                              ) : (
                                <td>
                                  <FaSpinner className="fs-5 text-danger " />
                                </td>
                              )}
                              {item.Status !== "Pending" ? (
                                <td>{item.adminRemark}</td>
                              ) : (
                                <td>
                                  <FaSpinner className="fs-5 text-danger " />
                                </td>
                              )}
                              {item.Status === "Pending" && (
                                <td>
                                  <FaSpinner className="fs-5 text-danger " />
                                </td>
                              )}
                              {item.Status === "Approve" && (
                                <td>
                                  <FaCheckSquare className="fs-5 text-success " />
                                </td>
                              )}
                              {item.Status === "Reject" && (
                                <td>
                                  <FaWindowClose className="fs-5 text-danger" />
                                </td>
                              )}
                              <td>
                                {item.Status !== "Approve" && (
                                  <button
                                    class="btn btn-warning px-2 py-1 mx-1 fs-8"
                                    onClick={(e) => {
                                      setPage({ selected: 3 });
                                      setUpdateData("update");
                                      setContentId(item._id);
                                      setTrainingplan((d) => ({
                                        ...d,
                                        cid: item.cid._id,
                                        contentName: item.contentName,
                                        Remark: item.Remark,
                                      }));
                                    }}
                                  >
                                    <FaPen className="color-white text-white" />
                                  </button>
                                )}

                                <button class="btn btn-secondary px-2 py-1 mx-1">
                                  <FaTimes className="color-white text-white" />
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>
              ) : (
                <h5 class="text-danger fw-bold mt-5 text-center">{msg1}</h5>
              )}
              {allRequest &&
                allRequest.length > 0 &&
                handlePagination(allRequest)}
            </>
          ) : (
            <>
              <h5 className="color-dgreen text-uppercase text-center heading">
                {updateData === ""
                  ? "Upload New Content Trainingplan"
                  : "Update Content Data"}
              </h5>
              <form
                onSubmit={updateData === "" ? handleSubmit : handleEditSubmit}
                className="px-lg-5 py-3"
                id="myform"
              >
                <div class="row mb-3">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 position-relative">
                    <label htmlFor="scname" className="form-label">
                      SubCourse name
                    </label>
                    <select
                      className={
                        trainingplan.cid || !validate
                          ? "form-select"
                          : "form-select border-danger"
                      }
                      id="scname"
                      value={trainingplan.cid}
                      name="scname"
                      onChange={(e) =>
                        setTrainingplan((d) => ({ ...d, cid: e.target.value }))
                      }
                    >
                      <option value="">select subcourse</option>
                      {scnames.map((item, index) => {
                        return (
                          <option value={item._id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 position-relative">
                    <label htmlFor="scname" className="form-label">
                      Content name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={trainingplan.contentName}
                      onChange={(e) =>
                        setTrainingplan((d) => ({
                          ...d,
                          contentName: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="mb-3 mt-3">
                    <label htmlFor="tp_wtl" className="form-label">
                      Remark
                    </label>
                    <textarea
                      id="tp_wtl"
                      rows="5"
                      className="form-control"
                      name="Remark"
                      value={trainingplan.Remark}
                      onChange={(e) =>
                        setTrainingplan((d) => ({
                          ...d,
                          Remark: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-submit"
                  onClick={scrollToTop}
                  //   style={{ display: visible ? "inline" : "none" }}
                >
                  Add Training Plan Content
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}
