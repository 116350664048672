import jwtDecode from "jwt-decode";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import img2 from "../../assets/images/feather-bar-chart.svg";
import img3 from "../../assets/images/material-timelapse.svg";
import img4 from "../../assets/images/material-view-module.svg";
import jwt_decode from "jwt-decode";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import global from "../../servicesvariable";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import "./interestedCourses.scss";

function IntrestedCources(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [courcesId, setCourcesId] = useState([]);
  const [selectedcourses, setSelectedcourses] = useState([]);
  const [intrestedCources, setIntrestedCources] = useState([]);
  const [tcounter, setTcounter] = useState([]);
  const [subCourses, setSubCourses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(8);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  var settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const getUser = async () => {
    LoginService.getSingleUser(
      jwtDecode(localStorage.getItem("token")).id,
      localStorage.getItem("token")
    )
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubCourses = async () => {
    CourseService.getSubCourses()
      .then((res) => {
        setSubCourses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUser();
    getSubCourses();
  }, []);

  const getCourses = async () => {
    CourseService.getCourses().then((res) => {
      const data =
        res &&
        res.data &&
        res.data
          .filter((item) => {
            if (
              user &&
              user.intrests &&
              user.intrests.some((item1) => item1.label === item.name)
            ) {
              return item.type;
            }
          })
          .map(({ type }) => type);
      const uniqueCourceId = [...new Set(data)];
      setCourcesId(uniqueCourceId);
    });
  };

  useEffect(() => {
    getCourses();
  }, [user]);

  useEffect(() => {
    CourseService.getCourses().then((res) => {
      let temp = res.data.filter((item) => {
        let temp1 = courcesId.some((item1) => {
          return item1 === item.type;
        });
        return temp1;
      });
      setIntrestedCources(temp);
    });
  }, [courcesId]);

  useEffect(() => {
    setLoading(true);
    setConfirmBox(false);
    if (token) {
      const decoded = jwt_decode(token);

      LoginService.getSingleUser(decoded.id, token)
        .then((res) => {
          if (res.data) {
            CourseService.getSubCoursesIn(
              { courses: res.data.courses },
              token
            ).then((result) => {
              if (result.data) {
                setSelectedcourses(result.data);
              } else {
                setSelectedcourses([]);
              }
            });
          } else {
            navigate("/login");
          }
          setLoading(false);
        })
        .catch((ex) => {});
    }
  }, [confirmBox]);

  useEffect(() => {
    let result = subCourses.filter((item) =>
      intrestedCources.some((item1) => item.type._id === item1._id)
    );
    result.map((item) => {
      CourseService.getTrainingPlan(item.name).then((tplan) => {
        if (tplan.data) {
          let length = {
            length: tplan.data.length,
            name: item.name,
          };
          setTcounter((d) => [...d, length]);
        } else {
          let length = {
            length: 0,
            name: item.name,
          };
          setTcounter((d) => [...d, length]);
        }
      });
    });
    setAllCourses(result);
  }, [intrestedCources]);

  const handleAutoSubscribe = (sid) => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.AddSubscribeCourse(decoded.role, decoded.id, sid, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => {});
  };

  const subscribeCourses = (item, index) => {
    return (
      <div class="learncourse-slider">
        <div class="card mb-3">
          <div class="course-item-img">
            {item.icon === "default.jpg" ? (
              <img
                src={`./../${item.icon}`}
                class="card-img-top"
                alt={item.icon}
                height="150"
                width="150"
              ></img>
            ) : item.icon.match(/http/g) ? (
              <img
                src={item.icon}
                class="card-img-top"
                alt={item.icon}
                height="150"
                width="150"
              />
            ) : (
              <>
                <img
                  src={`${global.imgURL}/${item.icon}`}
                  className="card-img-top"
                  alt={item.name}
                  height="150"
                  width="150"
                ></img>
              </>
            )}
          </div>

          <div class="card-body d-flex flex-column p-0">
            <div class="course-content p-3 pb-3 pb-lg-0">
              <h4
                class="card-title text-black font-weight-400"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.name.replaceAll("_", " ").replaceAll("-", " ")}
              </h4>
              <p
                className="card-text font-14 text-dark "
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.desc}
              </p>
            </div>
            <div class="course-details">
              <div class="px-lg-3 px-2 py-2 course-item d-flex">
                <img
                  src={img4}
                  alt="Modules"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.name && (
                              <>{Math.ceil(tcounter[i].length)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Modules
                </span>
              </div>
              <div class="px-lg-3 px-2 py-2 course-item d-flex">
                <img
                  src={img3}
                  alt="Hours"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.name && (
                              <>{Math.ceil(tcounter[i].length * 8.5)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Hours
                </span>
              </div>
              <div class="px-lg-3 px-2 py-2 course-item d-flex">
                <img
                  src={img2}
                  alt="level"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>
                <span class="font-14 text-dark-gray">
                  For <strong>{item.level}</strong>
                </span>
              </div>
              <div class="d-lg-flex d-block justify-content-between p-lg-3 p-2 btn-wrapper">
                {subCourses.map((subcourse) => {
                  if (subcourse.name === item.name) {
                    return (
                      <Link
                        to={`/course/${subcourse.type.name}/courseDetails/${item.name}`}
                        class="text-primary sm-white-btn"
                        title="Course Details"
                      >
                        Course Details
                      </Link>
                    );
                  }
                })}
                <Link
                  // to={`/course/HTML/subcourse/HTML`}
                  onClick={() => {
                    handleAutoSubscribe(item._id);
                  }}
                  class="text-primary sm-btn"
                >
                  Subscribe
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const subscribedCourses = (item, index) => {
    return (
      <div class="learncourse-slider">
        <div class="card mb-3 subscribe-card">
          <div class="course-item-img">
            {item.icon === "default.jpg" ? (
              <img
                src={`./../${item.icon}`}
                class="card-img-top"
                alt={item.icon}
                height="150"
                width="150"
              ></img>
            ) : item.icon.match(/http/g) ? (
              <img
                src={item.icon}
                class="card-img-top"
                alt={item.icon}
                height="150"
                width="150"
              />
            ) : (
              <>
                <img
                  src={`${global.imgURL}/${item.icon}`}
                  className="card-img-top"
                  alt={item.name}
                  height="150"
                  width="150"
                ></img>
              </>
            )}
          </div>

          <div class="card-body d-flex flex-column p-0">
            <div class="course-content p-3 pb-3 pb-lg-0">
              <h4
                class="card-title text-black font-weight-400"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.name.replaceAll("_", " ").replaceAll("-", " ")}
              </h4>
              <p
                className="card-text font-14 text-dark "
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.desc}
              </p>
            </div>
            <div class="course-details">
              <div class="px-lg-3 px-2 py-2 course-item d-flex">
                <img
                  src={img4}
                  alt="Modules"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.name && (
                              <>{Math.ceil(tcounter[i].length)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Modules
                </span>
              </div>
              <div class="px-lg-3 px-2 py-2 course-item d-flex">
                <img
                  src={img3}
                  alt="Hours"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.name && (
                              <>{Math.ceil(tcounter[i].length * 8.5)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Hours
                </span>
              </div>
              <div class="px-lg-3 px-2 py-2 course-item d-flex">
                <img
                  src={img2}
                  alt="level"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  For <strong>{item.level}</strong>
                </span>
              </div>
              <div class="d-lg-flex d-block justify-content-between p-lg-3 p-2 btn-wrapper">
                {subCourses.map((subcourse) => {
                  if (subcourse.name === item.name) {
                    return (
                      <Link
                        to={`/course/${subcourse.type.name}/courseDetails/${item.name}`}
                        class="text-primary sm-white-btn"
                        title="Course Details"
                      >
                        Course Details
                      </Link>
                    );
                  }
                })}
                <Link
                  to={`/course/${item.type.name}/courseOverview/${item.name}/tplan#1`}
                  class="text-success sm-btn d-flex align-items-center justify-content-center"
                >
                  <img
                    width="16"
                    height="16"
                    alt="bar btn"
                    src={require("../../assets/images/check-icon.svg").default}
                  />
                  &nbsp;Continue
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const newlyAddedCourses = (item, index) => {
    return (
      <div class="learncourse-slider">
        <div class="card mb-3">
          <div class="course-item-img">
            {item.icon === "default.jpg" ? (
              <img
                src={`./../${item.icon}`}
                class="card-img-top"
                alt={item.icon}
                height="150"
                width="150"
              ></img>
            ) : item.icon.match(/http/g) ? (
              <img
                src={item.icon}
                class="card-img-top"
                alt={item.icon}
                height="150"
                width="150"
              />
            ) : (
              <>
                <img
                  src={`${global.imgURL}/${item.icon}`}
                  className="card-img-top"
                  alt={item.name}
                  height="150"
                  width="150"
                ></img>
              </>
            )}
          </div>

          <div class="card-body d-flex flex-column p-0">
            <div class="course-content p-3 pb-3 pb-lg-0">
              <h4
                class="card-title text-black font-weight-400"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.name.replaceAll("_", " ").replaceAll("-", " ")}
              </h4>
              <p
                className="card-text font-14 text-dark "
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.desc}
              </p>
            </div>
            <div class="course-details">
              <div class="px-lg-3 px-2 py-2 course-item d-flex">
                <img
                  src={img4}
                  alt="Modules"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.name && (
                              <>{Math.ceil(tcounter[i].length)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Modules
                </span>
              </div>
              <div class="px-lg-3 px-2 py-2 course-item d-flex">
                <img
                  src={img3}
                  alt="Hours"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.name && (
                              <>{Math.ceil(tcounter[i].length * 8.5)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Hours
                </span>
              </div>
              <div class="px-lg-3 px-2 py-2 course-item d-flex">
                <img
                  src={img2}
                  alt="level"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  For <strong>{item.level}</strong>
                </span>
              </div>
              <div class="d-lg-flex d-block justify-content-between p-lg-3 p-2 btn-wrapper">
                {subCourses.map((subcourse) => {
                  if (subcourse.name === item.name) {
                    return (
                      <Link
                        to={`/course/${subcourse.type.name}/courseDetails/${item.name}`}
                        class="text-primary sm-white-btn"
                        title="Course Details"
                      >
                        Course Details
                      </Link>
                    );
                  }
                })}
                <Link to={`/login`} class="text-primary  sm-btn">
                  Subscribe
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    allCourses &&
    allCourses.length > 0 && (
      <section class="container py-0">
        <div class="learn-course position-relative">
          <h2 class="text-dark-gray font-weight-400 mb-md-4 mb-3">
            Courses you might be interest in
          </h2>
          <div class="px-12px">
            {allCourses && allCourses.length > 0 && (
              <Slider {...settings}>
                {allCourses.slice(0, itemsToShow).map((item, index) => {
                  return localStorage.getItem("token") ? (
                    jwt_decode(localStorage.getItem("token")).role ===
                      "Student" &&
                      (Array.from(selectedcourses, (o) => o.name).indexOf(
                        item.name
                      ) === -1 ? (
                        <>{subscribeCourses(item, index)}</>
                      ) : (
                        <>{subscribedCourses(item, index)}</>
                      ))
                  ) : (
                    <>{newlyAddedCourses(item, index)}</>
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      </section>
    )
  );
}

export default IntrestedCources;
