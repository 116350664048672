import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import CourseTab from "../../components/courseTab/courseTab";
import jwt_decode from "jwt-decode";
import VideoTutorial from "../../components/videoTutorial/VideoTutorial";
import TrainingPlan from "../../components/trainingPlan/TrainingPlan";
import Discussions from "../../components/coursedatails/Discussions";
import {
  Link,
  useHistory,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Resources from "../../components/resources/Resources";
import CourseService from "../../services/CourseService";
import "./courseOverview.scss";
import $ from "jquery";
import datanotfoundimage from "../../assets/images/DataNotFound-image.png";
import formloader from "../../images/formloading.svg";

export default function CourseOverview(props) {
  const param = useParams();
  let { cname, sname, data, vid } = useParams();
  const scname = props.scname;
  const [videos, setVideos] = useState([]);
  const [percentages, setPercentages] = useState([]);
  const navigate = useNavigate();
  const [course, setCourse] = useState({});
  const [subcourse, setSubCourse] = useState([]);
  const [sidelg, setSideLG] = useState(true);
  const [sidesm, setSideSM] = useState(false);
  const [loading, setLoading] = useState(false);

  const { state: lastvideos } = useLocation();

  useEffect(() => {
    $(window).scrollTop(0);
  }, []);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");

    if (!token) {
      // navigate("/login");
      setLoading(false);
    } else {
      const uid = jwt_decode(token).id;

      CourseService.getVideos(sname)
        .then((res) => {
          if (res.data.length > 0) {
            setVideos(res.data);
          }
        })
        .catch((ex) => console.log(ex));
      CourseService.getProgress(token, uid)
        .then((res) => {
          const video = [];
          if (res.data.length > 0) {
            res.data.map((res1) => {
              if (res1.cname == param.cname) {
                video.push(res1);
              }
            });
            setPercentages(video);
          }
        })
        .catch((ex) => console.log(ex));
      CourseService.getCourseInformation(sname, localStorage.getItem("token"))
        .then((res) => {
          if (res.data) {
            setCourse({ ...res.data.data });
          }
        })
        .catch((err) => console.log(err));
      CourseService.getSingleSubCourse(sname).then((res) => {
        if (res.data) {
          setSubCourse(res.data);
        }
      });
      setLoading(false);
    }
  }, [vid, param.choice]);

  // if(!(videos.length > 0)) {
  //   $(".tab-contents").addClass("mx-auto");
  // }
  if (param.choice === "videos") {
    return (
      <div className="tutorial-main">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading...</div>
            </div>
          </div>
        )}
        <VideoTutorial
          videoss={videos}
          course={course}
          percentages={percentages}
          setSideSM={setSideSM}
          sidesm={sidesm}
          setSideLG={setSideLG}
          sidelg={sidelg}
        />
      </div>
    );
  } else if (Object.keys(course).length > 0) {
    return (
      <div class="tutorial-main">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading...</div>
            </div>
          </div>
        )}
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 tutorial-banner pb-0 pb-lg-3">
              <CourseTab
                videos={videos}
                course={course}
                percentages={percentages}
                setSideSM={setSideSM}
                sidesm={sidesm}
                setSideLG={setSideLG}
                sidelg={sidelg}
                lastvideos={lastvideos}
              />
            </div>

            <>
              {param.choice === "tplan" ? (
                <TrainingPlan
                  course={course}
                  videos={videos}
                  subcourse={subcourse}
                />
              ) : param.choice === "additional-resources" ? (
                <div class="col-12 mx-auto">
                  {course.ppts && course.ppts.length > 0 ? (
                    <Resources ppts={course.ppts} />
                  ) : (
                    <div class="row pt-lg-4 align-items-center">
                      <div class="text-center my-lg-5 my-3">
                        <img
                          src={datanotfoundimage}
                          width="450px"
                          className="datanotfoundimage"
                        />
                        <h3 class="mt-lg-4 mt-3">
                          There is no additional resources uploaded for this
                          course.
                        </h3>
                      </div>
                    </div>
                  )}
                </div>
              ) : param.choice === "discussion" ? (
                <div class="col-12 mx-auto">
                  <Discussions cid={subcourse.type} />
                </div>
              ) : (
                <div class="col-12 col-xl-9 mb-lg-4 mx-auto">
                  <section className="">
                    <div class="row pt-lg-3 align-items-center">
                      <div class="text-center my-lg-5 my-3">
                        <img
                          src={datanotfoundimage}
                          width="450px"
                          className="datanotfoundimage"
                        />
                      </div>
                    </div>
                  </section>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div class=" pt-lg-4 align-items-center">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading...</div>
            </div>
          </div>
        )}
        <div class="px-4">
          <button
            className="btn btn-blue"
            onClick={() => navigate(`/course/${cname}`)}
          >
            Back
          </button>
        </div>
        <div class="text-center my-lg-5 my-3">
          <img
            src={datanotfoundimage}
            width="450px"
            className="datanotfoundimage"
          />
          <h3 class="mt-lg-4 mt-3">
          This course doesn't have any Training Plan at this moment.
          </h3>
        </div>
        
      </div>
    );
  }
}
