import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ProductandCategory from '../../../services/ProductandCategory';

const ProductDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    let id = queryParams.get('id')
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: '',
        stock_quantity: '',
        manufacturer: '',
    });
    useEffect(() => {
        if (id) {
            ProductandCategory.getProductById(id).then(res => {
                setFormData(res.data)
            }).catch(err => setFormData([]))
        }
    }, [])

    return (
        <div className='container'>
            <div className="d-flex justify-content-between align-items-center">
                <h2>Product Details</h2>
                <Link to="/productlisting">
                    <button className="btn btn-success">View Product List</button>
                </Link>

            </div>
            <div className='row'>
                {formData && formData.length > 0 ? formData.map(formData => (
                    <div className="card mt-5 mx-auto" style={{ width: '35rem' }}>
                        <div className="card-body">
                            <h4>Name:</h4> <h6 className="card-title" dangerouslySetInnerHTML={{ __html: formData.name }} /><hr />
                            <h4 className='mt-3'>Description:</h4> <p className="card-text mt-1" dangerouslySetInnerHTML={{ __html: formData.description }} /><hr />
                            <h4 className='mt-3'>Price:</h4> <p className="card-text mt-1" dangerouslySetInnerHTML={{ __html: formData.price }} /><hr />
                            <h4 className='mt-3'>stock_quantity :</h4> <p className="card-text mt-1" dangerouslySetInnerHTML={{ __html: formData.stock_quantity }} /><hr />
                            <h4>manufacturer :</h4> <p className="card-text" dangerouslySetInnerHTML={{ __html: formData.manufacturer }} /><hr />
                        </div>
                    </div>)
                ) : (<>
                    <h5 dangerouslySetInnerHTML={{ __html: `No data found ${id}` }} />
                </>)
                }
            </div>
        </div>
    );
};

export default ProductDetails;
