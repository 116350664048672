import React, { useEffect, useState } from "react";
import img1 from "../../assets/images/course-img.png";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import { Link, useHistory } from "react-router-dom";
import "./FourCol.scss";
import jwt_decode from "jwt-decode";
import global from "../../servicesvariable"

export default function FourCol(props) {
  const { name, icon, desc, _id, status } = props.course;
  const [userdata, setUserdata] = useState([]);
  const [dcounter, setDcounter] = useState(-1);
  const [hcounter, setHcounter] = useState(0);
  const [confirmBox, setConfirmBox] = useState(false);
  useEffect(() => {
    setConfirmBox(false);
    const token = localStorage.getItem("token");
    if (token) {
      var data = [];
      const decoded = jwt_decode(token);
      LoginService.getSingleUser(decoded.id, token).then((res) => {
        if (res.data) {
          setUserdata(res.data);
        }
      });
      CourseService.getSinglesubcourse(_id).then((res) => {
        if (res.data) {
          setDcounter(res.data.length);
          res.data.map((subcourse) => {
            CourseService.getTrainingPlan(subcourse.name).then((res1) => {
              if (res1.data) {
                data.push(res1.data);
                setHcounter(conter => Math.ceil(conter + res1?.data?.length * 8.5));
              }
              // if (data[0]) {
              //   setHcounter(Math.ceil(data[0].length * 8.5));
              // }
            });
          });
        }
      });
    } else {
      var data = [];
      CourseService.getSinglesubcourse(_id).then((res) => {
        if (res.data) {
          setDcounter(res.data.length);
          res.data.map((subcourse) => {
            CourseService.getTrainingPlan(subcourse.name).then((res1) => {
              if (res1.data) {
                data.push(res1.data);
                setHcounter(conter => Math.ceil(conter + res1?.data?.length * 8.5));
              }
              // if (data[0]) {
              //   setHcounter(Math.ceil(data[0].length * 8.5));
              // }
            });
          });
        }
      });
    }
  }, [confirmBox]);

  return (
    <>
      <Link className="card text-decoration-none h-100" to={`/course/${name}`}>
        <div class="tech-img">
          {icon === "default.jpg" ? (
            <img
              // src={img1}
              src={`./../${icon}`}
              alt={icon}
              class="img-fluid w-100"
              width="150"
              height="150"
            />
          ) : icon.match(/http/g) ? (
            <img
              src={icon}
              alt={icon}
              class="img-fluid w-100"
              width="150"
              height="150"
            />
          ) : (
            <>
              <img
                src={`${global.imgURL}/${icon}`}
                className="img-fluid w-100"
                alt={name}
                height="150"
                width="150"
              ></img>
            </>
          )}
        </div>
        <div class="tech-details p-3 card-body d-flex flex-column">
          <div class="tech-name font-20 text-black">{name}</div>
          <div class="d-flex flex-wrap justify-content-between mt-auto">
            <span class="col-12 col-md-auto me-3">
              <strong>{dcounter === -1 ? <>0</> : <>{dcounter}</>}</strong>
              &nbsp;Courses
            </span>
            <span class="col-12 col-md-auto me-3">
              <strong>{hcounter === -1 ? <>0</> : <>{hcounter}</>}</strong>
              &nbsp;Learning Hrs
            </span>
          </div>
        </div>
      </Link>
    </>
  );
}
