import React, { useState, useEffect } from 'react';
import axios from 'axios'
import variable from "../../servicesvariable";

const InputFile = () => {

    const [formData, setFormData] = useState({
        textData: '',
    });

    const [response, setResponse] = useState({
        textData: '',
    })

    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = JSON.stringify({
            "textData": formData.textData,
        });

        let config = {
            method: 'post',
            url: variable.Tutorial_APi_ManageTextData,
            headers: {
                'Content-Type': 'application/json'
            },
            data
        };

        axios.request(config)
            .then((response) => {
                setResponse(response.data)
                setFormData({
                    textData: '',
                })
                setFormSubmitted(true)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div>
            <div className="text-center mx-auto mt-5" style={{ width: '50%', marginTop: 'calc(50vh - yourNavbarHeight)' }} >
                <h2>Text Data</h2>
            </div>

            <div className="container mt-4 w-25">
                <form onSubmit={handleSubmit}>

                    <div className="mb-3">
                        <label htmlFor="textData" className="form-label">TextData</label>
                        <textarea type="text" className="form-control" id="textData" name="textData" value={formData.textData} onChange={handleChange} required />
                    </div>

                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>


            {formSubmitted && <p>
                <div className="mt-5" style={{ height: '100vh' }}>
                    <div className="card mx-auto" style={{ width: '35rem' }}>
                        <div className="card-body">
                            {response !== '' && (
                                <h3>Your data "{response}" has been saved</h3>)
                            }
                        </div>
                    </div>
                </div>
            </p>}
        </div>
    );
};

export default InputFile;
