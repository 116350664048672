import React from "react";
import ReactHlsPlayer from "react-hls-player/dist";

import { useNavigate } from "react-router-dom";

function Videom3u8() {
  const navigate = useNavigate();
  const back = () => {
    navigate.push("/course/HTML");
  };
  return (
    <>
      <div>
        <h4>React hls player manulaay transformed video streaming</h4>
        <ReactHlsPlayer
          src="http://localhost:5000/video.m3u8"
          controls
          height="50%"
          width="50%"
          style={{ marginLeft: "200px", marginTop: "50px" }}
        />
      </div>
      <button
        className="btn btn-success"
        style={{ marginLeft: "200px" }}
        onClick={back}
      >
        Back
      </button>
    </>
  );
}

export default Videom3u8;
