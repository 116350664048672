import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import variable from '../../servicesvariable'

const UploadDocument = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [error, setError] = useState('');

    const fileInputRef = useRef(null);
    useEffect(() => {
        // Fetch the list of files when the component mounts
        fetchFileList();
    }, []);

    const fetchFileList = async () => {
        try {
            const response = await axios.get(variable.Tutorial_Api_FilesList);

            if (Array.isArray(response.data) && response.data.length > 0) {
                setFileList(response.data);
            } else {
                setFileList([]);
            }
        } catch (error) {
            console.error('Error fetching file list:', error);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && isValidFileType(file)) {
            setSelectedFile(file);
            setUploadSuccess(false);
            setError('');
        } else {
            setSelectedFile(null);
            setError('Invalid file type. Please select a docx, pdf, or txt file.');
        }
    };

    const isValidFileType = (file) => {
        const allowedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];

        return allowedFileTypes.includes(file.type);
    };

    const handleUpload = async () => {
        if (selectedFile) {
            try {
                const formData = new FormData();
                formData.append('file', selectedFile);

                // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint for file upload
                const response = await axios.post(variable.Tutorial_Api_Documents, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                setUploadSuccess(true);
                fileInputRef.current.value = '';
                // Fetch the updated file list after successful upload
                fetchFileList();

            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            console.log('No file selected');
        }
    };

    const handleView = (fileName) => {
        const url = variable.Tutorial_Api_Documents + `/?fileName=${fileName}`
        window.open(url, '_blank');
    };

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="input-group mb-3">
                        <input type="file"
                            ref={fileInputRef}
                            className="form-control" onChange={handleFileChange} />
                        <div className="input-group-append">
                            <button className="btn btn-primary" type="button" onClick={handleUpload}>
                                Upload
                            </button>
                        </div>
                    </div>
                    {error && <div className="alert alert-danger" role="alert">{error}</div>}
                    {uploadSuccess && (
                        <div className="alert alert-success" role="alert">
                            File uploaded successfully!
                        </div>
                    )}
                    {
                        fileList && fileList.length > 0 ? (
                            <div>
                                <table className="table mt-4">
                                    <thead>
                                        <tr>
                                            <th scope="col">File Name</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fileList.map((file, index) => (
                                            <tr key={index}>
                                                <td>{file}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-secondary"
                                                        onClick={() => handleView(file)}
                                                    >
                                                        View
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <><div className='text-danger text-center'>Currently No files on the servre</div></>
                        )
                    }

                </div>
            </div>
        </div>
    );
};

export default UploadDocument;
