import React, { useState, useEffect } from "react";
import LoginService from "../../services/LoginService";
import CourseService from "../../services/CourseService";
import { VscCheck } from "react-icons/vsc";
import { FcCancel } from "react-icons/fc";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import ToolTip from "../BsComponents/ToolTip";

export default function ManageSubscription(props) {
  const [msg, setMsg] = useState("");
  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      setLoading(true);
      LoginService.getTrainers(token)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            setUsers(res.data);
          }
        })
        .catch((ex) => console.log(ex));

      CourseService.getSubCourses().then((res) => {
        if (res.data && res.data.length > 0) {
          setCourses(res.data);
        }
      });
      setLoading(false);
    }
  }, [confirmBox]);

  const handleDelete = (username, courseid) => {
    LoginService.deleteSubscriptionRequest(username, courseid, token)
      .then((res) => {
        setMsg("Subscription Request Deleted...");
        setConfirmBox(true);
      })
      .catch((ex) => console.log(ex));
    {
      setTimeout(() => setConfirmBox(false), 3000);
    }
  };
  const handleSubmit = (username, courseid) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      const decoded = jwt_decode(token);

      LoginService.AddSubscribeCourse(decoded.role, username, courseid, token)
        .then((res) => {
          setMsg("Subscription Request Approved...");
          setConfirmBox(true);
        })
        .catch((ex) => console.log(ex));
      {
        setTimeout(() => setConfirmBox(false), 3000);
      }
    }
  };

  const handleTable = (size) => {
    return (
      <>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            {size == "more" && <th scope="col">Email</th>}
            <th scope="col">Course</th>
            <th scope="col" className="text-center">
              Handle
            </th>
          </tr>
        </thead>
        <tbody>
          {users.filter((u) => u.subscription.length > 0).length === 0 ? (
            <tr className="alert alert-danger">
              <td colSpan={5} className="text-center">
                No Subscription requests available.
              </td>
            </tr>
          ) : (
            users.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.subscription.map((sub, index1) => {
                    return (
                      <React.Fragment key={index1}>
                        {courses.map((cour, index2) => {
                          return (
                            <React.Fragment key={index2}>
                              {cour._id === sub._id &&
                                sub.Subscribe === false && (
                                  <>
                                    <tr key={index1}>
                                      <th scope="row">{index1 + 1}</th>
                                      <td>{item.username}</td>
                                      {size == "more" && <td>{item.email}</td>}
                                      <td>{cour.name}</td>
                                      <td>
                                        <div className="d-flex justify-content-center mx-2">
                                          <div>
                                            <ToolTip title="Approve request">
                                              <button
                                                className="btn btn-success px-2 py-1 m-0"
                                                onClick={() => {
                                                  handleSubmit(
                                                    item._id,
                                                    cour._id
                                                  );
                                                }}>
                                                <VscCheck />
                                              </button>
                                            </ToolTip>
                                          </div>
                                          <div>
                                            <ToolTip title="Reject request">
                                              <button
                                                className="btn btn-danger px-2 py-1 mx-2"
                                                onClick={() => {
                                                  handleDelete(
                                                    item._id,
                                                    cour._id
                                                  );
                                                }}>
                                                <FaTimes />
                                              </button>
                                            </ToolTip>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )}
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })
          )}
        </tbody>
      </>
    );
  };
  return (
    <>
      <div className="container vh-100 p-2 px-3">
        <h4 className="">All subscription requests</h4>
        <hr className="py-0 my-0" />
        {confirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div
                  className={`alert ${
                    msg === "Subscription Request Deleted..."
                      ? "alert-danger"
                      : "alert-success"
                  }`}
                >
                  {msg}
                </div>
              </div>
            </div>
          </div>
        )}
        <table className="table mt-4">
          {window.screen.width < 992 && handleTable("less")}
          {window.screen.width > 992 && handleTable("more")}
        </table>
      </div>
    </>
  );
}
