import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import CkEditor from "./CkEditor";
import { useNavigate } from "react-router-dom";

export default function AddTrininigPlan(props) {
  const initalExamDetails = {
    data: "",
    time: 10,
    remainder: 5,
    difficulty: "",
  };
  const [practical, setPractical] = useState(
    props.updateData || {
      cid: "",
      type: "",
      subQuestions: [],
      ...initalExamDetails,
    }
  );
  const [scnames, setSCnames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [msg, setMsg] = useState({ success: false, msg: "" });
  const navigate = useNavigate();

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setPractical((d) => ({ ...d, data: data }));
  };

  const handleSubQuestionRemove = (index) => {
    const temp = [...practical.subQuestions];
    temp.splice(index, 1);
    setPractical({ ...practical, subQuestions: temp });
  };

  const handleSubQuestionExamTimeEdit = (index, value) => {
    const temp = [...practical.subQuestions];
    let findSubQuestion = temp[index];
    findSubQuestion.time = value;
    setPractical({ ...practical, subQuestions: temp });
  };
  const handleSubQuestionRemainderEdit = (index, value) => {
    const temp = [...practical.subQuestions];
    let findSubQuestion = temp[index];
    findSubQuestion.remainder = value;
    setPractical({ ...practical, subQuestions: temp });
  };
  const handleSubQuestionDifficultyEdit = (index, value) => {
    const temp = [...practical.subQuestions];
    let findSubQuestion = temp[index];
    findSubQuestion.difficulty = value;
    setPractical({ ...practical, subQuestions: temp });
  };
  const handleSubQuestionDataEdit = (index, value) => {
    const temp = [...practical.subQuestions];
    let findSubQuestion = temp[index];
    findSubQuestion.data = value;
    setPractical({ ...practical, subQuestions: temp });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      CourseService.getSubCourses()
        .then((res) => {
          if (res.data) {
            setSCnames(res.data);
          }
        })
        .catch((ex) => console.log(ex));
    } else {
      navigate("/login");
    }
  }, []);

  const handleUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (
        practical.cid &&
        practical.data &&
        practical.type &&
        practical.time &&
        !isNaN(practical.time) &&
        !isNaN(practical.remainder) &&
        practical.remainder
        // practical.difficulty
      ) {
        try {
          const questionType = "Practical";
          const res = await CourseService.editQuestions(
            practical._id,
            questionType,
            practical,
            token
          );
          if (res.data) {
            setConfirmBox(true);
            setMsg({ success: true, message: res.data.message });
            window.scrollTo(0, 0);
            setPractical({
              cid: "",
              type: "",
              subQuestions: [],
              ...initalExamDetails,
            });
            setTimeout(() => setConfirmBox(false), 3000);
            setLoading(false);
            props.updated(true);
          }
        } catch (ex) {
          setConfirmBox(true);
          setMsg({ success: false, message: ex });
        }
      } else {
        setLoading(false);
        window.scrollTo(0, 0);
        setConfirmBox(true);
        setTimeout(() => setConfirmBox(false), 3000);
      }
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (
        practical.cid &&
        practical.data &&
        practical.type &&
        practical.time &&
        !isNaN(practical.time) &&
        !isNaN(practical.remainder) &&
        practical.remainder
        // practical.difficulty
      ) {
        try {
          const questionType = "Practical";
          const res = await CourseService.postQuestion(
            practical.cid,
            questionType,
            practical,
            token
          );
          if (res.data) {
            setConfirmBox(true);
            setMsg({ success: true, message: res.data.msg });
            window.scrollTo(0, 0);
            setPractical({
              cid: "",
              type: "",
              subQuestions: [],
              ...initalExamDetails,
            });
            setTimeout(() => setConfirmBox(false), 3000);
            setLoading(false);
          }
        } catch (ex) {
          setConfirmBox(true);
          setMsg({ success: false, message: ex });
        }
      } else {
        setLoading(false);
        window.scrollTo(0, 0);
        setConfirmBox(true);
        setTimeout(() => setConfirmBox(false), 3000);
      }
    }
  };

  return (
    <div className="container my-2 bg-white p-4">
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="200" />
            </div>
            <div className="col-12 text-white h4">
              Uploading Training plan...
            </div>
          </div>
        </div>
      )}
      {confirmBox && (
        <div className="formloade">
          <div className="row text-center">
            <div className="col-6 w-100 confirmbox">
              <div
                className={`alert ${
                  msg.success ? "alert-success" : "alert-danger"
                }`}
              >
                {msg.message}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="form px-lg-5">
        <h5 className="color-dgreen text-uppercase text-center heading">
          Add New Practical
        </h5>
        <form
          onSubmit={handleSubmit}
          className="px-lg-5 py-3"
          method="post"
          id="myform"
        >
          <div className="row">
            <div className="col position-relative mb-3">
              <label htmlFor="cname" className="form-label">
                SubCourse Name
              </label>
              <select
                className="form-select"
                id="scname"
                value={practical.cid}
                name="scname"
                onChange={(e) =>
                  setPractical((d) => ({ ...d, cid: e.target.value }))
                }
              >
                <option value="">select subcourse</option>
                {scnames.map((item, index) => {
                  return (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col position-relative mb-3">
              <label htmlFor="cname" className="form-label">
                Exam Type
              </label>
              <select
                className="form-select"
                id="scname"
                value={practical.type}
                name="scname"
                onChange={(e) =>
                  setPractical((d) => ({ ...d, type: e.target.value }))
                }
              >
                <option value="">select type</option>
                <option value="External">External</option>
                <option value="Internal">Internal</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col col-4">
              <label htmlFor="cname" className="form-label">
                Exam Time
              </label>
              <input
                className="form-control"
                id="time"
                name="time"
                value={practical.time}
                type="text"
                inputmode="numeric"
                placeholder="Enter Exam Time in Minutes (ex : 15)"
                onChange={(e) =>
                  setPractical((d) => ({ ...d, time: e.target.value }))
                }
              />
            </div>
            <div className="col col-4">
              <label htmlFor="cname" className="form-label">
                Exam Remainder
              </label>
              <input
                className="form-control"
                id="remainder"
                name="remainder"
                type="text"
                inputmode="numeric"
                placeholder="Enter Exam Remainder in Minutes (ex : 5)"
                value={practical.remainder}
                onChange={(e) =>
                  setPractical((d) => ({ ...d, remainder: e.target.value }))
                }
              />
            </div>
            <div className="col col-4">
              <label htmlFor="difficulty" className="form-label">
                Difficulty
              </label>
              <select
                className="form-select"
                id="difficulty"
                value={practical.difficulty}
                name="difficulty"
                onChange={(e) =>
                  setPractical((d) => ({ ...d, difficulty: e.target.value }))
                }
              >
                <option value="">select type</option>
                <option value="1">Easy</option>
                <option value="2">Medium</option>
                <option value="3">Hard</option>
              </select>
            </div>
            <div className="col col-12">
              <label htmlFor="xlsx" className="form-label">
                Add Data
              </label>
              <CkEditor
                data={practical.data}
                onChange={handleChange}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
              />
              <div>{practical.data && practical.data}</div>
            </div>
          </div>

          <div>
            {practical.subQuestions.length > 0 &&
              practical.subQuestions.map((item, index) => {
                return (
                  <>
                    <hr />
                    <div className="row">
                      <div className="col col-2">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => handleSubQuestionRemove(index)}
                        >
                          remove
                        </button>
                      </div>
                      <div className="col col-10">
                        <div className="row">
                          <div className="col col-4">
                            <label htmlFor="cname" className="form-label">
                              Exam Time
                            </label>
                            <input
                              className="form-control"
                              id="time"
                              name="time"
                              value={item.time}
                              type="text"
                              inputmode="numeric"
                              placeholder="Enter Exam Time in Minutes (ex : 15)"
                              onChange={(e) =>
                                handleSubQuestionExamTimeEdit(
                                  index,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="col col-4">
                            <label htmlFor="cname" className="form-label">
                              Exam Remainder
                            </label>
                            <input
                              className="form-control"
                              id="remainder"
                              name="remainder"
                              type="text"
                              inputmode="numeric"
                              placeholder="Enter Exam Remainder in Minutes (ex : 5)"
                              value={item.remainder}
                              onChange={(e) =>
                                handleSubQuestionRemainderEdit(
                                  index,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="col col-4">
                            <label htmlFor="difficulty" className="form-label">
                              Difficulty
                            </label>
                            <select
                              className="form-select"
                              id="difficulty"
                              value={item.difficulty}
                              name="difficulty"
                              onChange={(e) =>
                                handleSubQuestionDifficultyEdit(
                                  index,
                                  e.target.value
                                )
                              }
                            >
                              <option value="">select type</option>
                              <option value="1">Easy</option>
                              <option value="2">Medium</option>
                              <option value="3">Hard</option>
                            </select>
                          </div>
                          <div className="col col-12">
                            <label htmlFor="xlsx" className="form-label">
                              Add Data
                            </label>
                            <CkEditor
                              data={item.data}
                              onChange={(e, editor) =>
                                handleSubQuestionDataEdit(
                                  index,
                                  editor.getData()
                                )
                              }
                              onBlur={(event, editor) => {}}
                              onFocus={(event, editor) => {}}
                            />
                            <div>{item.data && item.data}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}
                  </>
                );
              })}
            <button
              type="button"
              className="btn btn-warning btn-sm text-white my-2"
              onClick={() => {
                const temp = [...practical.subQuestions];
                temp.push({ ...initalExamDetails });
                setPractical({
                  ...practical,
                  subQuestions: temp,
                });
              }}
            >
              Add sub question
            </button>
          </div>
          <div className="my-4">
            <button
              type="button"
              onClick={(e) =>
                props.updateData ? handleUpdate(e) : handleSubmit(e)
              }
              className={`btn  ${
                props.updateData ? "btn-success" : "btn-primary"
              }`}
            >
              {props.updateData ? "Update Question" : "Add Question"}
            </button>
            <button
              type="reset"
              className="btn btn-secondary ms-3"
              onClick={() => {
                window.scrollTo(0, 0);
                setPractical({
                  cid: "",
                  type: "",
                  subQuestions: [],
                  ...initalExamDetails,
                });
              }}
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
