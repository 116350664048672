import axios from "axios";
import variable from "../servicesvariable";

class StudentListService {
    getAllStudent() {
        return axios.get(variable.Tutorial_Api_Student)
    }
    getStudentById(id) {
        return axios.get(variable.Tutorial_Api_Student + id)
    }
    addnewStudent(data) {
        return axios.post(variable.TutorialApi_AddNewStudent,
            data)
    }
    updateStudentByID(id, data) {
        return axios.put(variable.Tutorial_Api_Student + id,
            data)
    }
    deleteStudentByID(id) {
        return axios.delete(variable.Tutorial_Api_Student + id)
    }
}

export default new StudentListService();