import React, { useState } from "react";
import "./courseBanner.scss";
import jwt_decode from "jwt-decode";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import { useParams, Link } from "react-router-dom";

export default function CourseBanner(props) {
  const {
    scourse,
    vcount,
    tcount,
    pcount,
    user,
    selectedcourses,
    userdata,
    setConfirmBox,
    handleAutoSubscribe,
    handleAutoSubscribed,
    handlePendingRequest,
    handleSubscribe,
    handleSubscribed,
  } = props;
  let { cname, sname } = useParams();

  return (
    <div class="course-banner bg-light-gray">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-xl-9 text-dark-gray">
            <h1 class="mb-2 mb-md-4">{scourse?.name?.replaceAll("_",' ')}</h1>
            <p>{scourse.desc}</p>
            <div class="d-none d-lg-block">
              <span class="btn btn-white py-2 d-inline-block">
                {user} learner have subscribed this
              </span>
            </div>
          </div>
          <div class="col-lg-4 col-xl-3">
            <div class="banner-card card">
              <h4 class="m-0">This course includes</h4>
              <div class="d-flex justify-content-md-between flex-wrap d-lg-block">
                <div class="d-flex course-item">
                  <img
                    src={
                      require("../../assets/images/material-view-module.svg")
                        .default
                    }
                    alt=""
                    width="16"
                    class="me-2 me-lg-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    <strong>{tcount}</strong> Modules
                  </span>
                </div>
                <div class="d-flex course-item">
                  <img
                    src={
                      require("../../assets/images/material-timelapse.svg")
                        .default
                    }
                    alt=""
                    width="16"
                    class="me-2 me-lg-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    <strong>{Math.round(tcount * 8.5)}</strong> Hours
                  </span>
                </div>
                <div class="d-flex course-item">
                  <img
                    src={
                      require("../../assets/images/run_video_icon.svg").default
                    }
                    alt=""
                    width="16"
                    class="me-2 me-lg-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    <strong>{vcount}</strong> Videos
                  </span>
                </div>
                <div class="d-flex course-item">
                  <img
                    src={
                      require("../../assets/images/slideshow_presentation.svg")
                        .default
                    }
                    alt=""
                    width="16"
                    class="me-2 me-lg-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    <strong>{pcount}</strong> Presentaions
                  </span>
                </div>
                <div class="d-flex course-item">
                  <img
                    src={
                      require("../../assets/images/feather-bar-chart.svg")
                        .default
                    }
                    alt=""
                    width="16"
                    class="me-2 me-lg-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    For <strong>{scourse.level}</strong>
                  </span>
                </div>
              </div>
              <div class="text-center course-item d-none d-lg-block">
                {selectedcourses !== 0 && scourse.status === true ? (
                  <>
                    {userdata.role === "Student" ? (
                      Array.from(selectedcourses, (o) => o.name).indexOf(
                        sname
                      ) === -1 ? (
                        <button
                          className="btn btn-blue py-2 btn-blue-hover"
                          onClick={() => {
                            handleAutoSubscribe();
                          }}
                        >
                          Subscribe
                        </button>
                      ) : (
                        <Link
                          className="btn btn-blue py-2 btn-blue-hover"
                          to={`/course/${cname}/courseOverview/${sname}/tplan`}
                        >
                          Learn
                        </Link>
                      )
                    ) : (
                      <Link
                        className="btn btn-blue py-2 btn-blue-hover"
                        to={`/login`}
                      >
                        Subscribe
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    {userdata.role === "Student" ? (
                      Array.from(selectedcourses, (o) => o.name).indexOf(
                        sname
                      ) === -1 ? (
                        <>
                          {userdata.subscription &&
                          Array.from(
                            userdata.subscription,
                            (o) => o.course
                          ).indexOf(scourse._id) !== -1 ? (
                            <button
                              className="btn btn-blue py-2"
                              onClick={() => {
                                handlePendingRequest();
                              }}
                            >
                              Request Pending
                            </button>
                          ) : (
                            <button
                              className="btn btn-blue py-2"
                              onClick={() => {
                                handleSubscribe();
                              }}
                            >
                              Subscribe Request
                            </button>
                          )}
                        </>
                      ) : (
                        <button
                          className="btn btn-blue py-2"
                          onClick={() => {
                            handleSubscribed();
                          }}
                        >
                          Subscribed
                        </button>
                      )
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div class="d-md-flex d-inline-block align-items-center justify-content-center d-lg-none mt-3 text-center">
            <span class="btn btn-white py-2 d-inline-block mb-2 me-2">
              {user} Learner have subscribed
            </span>
            {selectedcourses !== 0 && scourse.status === true ? (
              <>
                {userdata.role === "Student" ? (
                  Array.from(selectedcourses, (o) => o.name).indexOf(sname) ===
                  -1 ? (
                    <button
                      className="btn btn-blue py-2 mb-2 me-2"
                      onClick={() => {
                        handleAutoSubscribe();
                      }}
                    >
                      Subscribe
                    </button>
                  ) : (
                    <Link
                      className="btn btn-blue py-2 mb-2 me-2"
                      to={`/course/${cname}/courseOverview/${sname}/videos`}
                    >
                      Learn
                    </Link>
                  )
                ) : (
                  <Link className="btn btn-blue py-2 mb-2 " to={`/login`}>
                    Subscribe
                  </Link>
                )}
              </>
            ) : (
              <>
                {userdata.role === "Student" ? (
                  Array.from(selectedcourses, (o) => o.name).indexOf(sname) ===
                  -1 ? (
                    <>
                      {userdata.subscription &&
                      Array.from(
                        userdata.subscription,
                        (o) => o.course
                      ).indexOf(scourse._id) !== -1 ? (
                        <button
                          className="btn btn-blue py-2 mb-2 me-2"
                          onClick={() => {
                            handlePendingRequest();
                          }}
                        >
                          Request Pending
                        </button>
                      ) : (
                        <button
                          className="btn btn-blue py-2 mb-2 me-2"
                          onClick={() => {
                            handleSubscribe();
                          }}
                        >
                          Subscribe Request
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className="btn btn-blue py-2 mb-2 me-2"
                      onClick={() => {
                        handleSubscribed();
                      }}
                    >
                      Subscribed
                    </button>
                  )
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
