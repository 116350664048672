import React from "react";
import { Table, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const ExportCsv = ({ csvData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCsv = (csvData, fileName) => {
    // const ws = XLSX.utils.aoa_to_sheet(csvData);
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button variant="warning" onClick={(e) => exportToCsv(csvData, fileName)}>
      Export
    </Button>
  );
};

export default function Excel() {
  //   const getCustomers = () => {
  //     let custs = [];
  //     for (let i = 0; i <= 25; i++) {
  //       custs.push({
  //         firstName: `first${i}`,
  //         lastName: `last${i}`,
  //         email: `abc${i}@gmail.com`,
  //         address: `000${i} street city, ST`,
  //         zipcode: `0000${i}`,
  //       });
  //     }
  //     return custs;
  //   };
  //   const customers = getCustomers();
  //   const customers = [
  //     {
  //       name: "bhargav modi",
  //       avg: "0",
  //       grade: "A",
  //       // "html.im": 12,
  //       // "html.em": 12,
  //       // "html.ip": 12,
  //       // "html.ep": 12,
  //       //   html: { im: 12, em: 12 },
  //     },
  //     {
  //       name: "rohit",
  //       avg: "0",
  //       grade: "A",
  //       "html.im": 12,
  //       "html.em": 12,
  //       "html.ip": 12,
  //       "html.ep": 12,
  //       //   html: { im: 12, em: 12 },
  //     },
  //   ];
  const customers = [
    {
      rating: 5,
      comment: "Imagine all the eatables, living in conFusion!",
      author: "John Lemon",
      date: "2012-10-16T17:57:28.556094Z",
      name: "Uthappizza",
    },
    {
      rating: 4,
      comment:
        "Sends anyone to heaven, I wish I could get my mother-in-law to eat it!",
      author: "Paul McVites",
      date: "2014-09-05T17:57:28.556094Z",
      name: "Uthappizza",
    },
    {
      rating: 3,
      comment: "Eat it, just eat it!",
      author: "Michael Jaikishan",
      date: "2015-02-13T17:57:28.556094Z",
      name: "Uthappizza",
    },
    {
      rating: 4,
      comment: "Ultimate, Reaching for the stars!",
      author: "Ringo Starry",
      date: "2013-12-02T17:57:28.556094Z",
      name: "Uthappizza",
    },
    {
      rating: 2,
      comment: "It's your birthday, we're gonna party!",
      author: "25 Cent",
      date: "2011-12-02T17:57:28.556094Z",
      name: "Uthappizza",
    },
    {
      rating: 5,
      comment: "Imagine all the eatables, living in conFusion!",
      author: "John Lemon",
      date: "2012-10-16T17:57:28.556094Z",
      name: "Zucchipakoda",
    },
    {
      rating: 4,
      comment:
        "Sends anyone to heaven, I wish I could get my mother-in-law to eat it!",
      author: "Paul McVites",
      date: "2014-09-05T17:57:28.556094Z",
      name: "Zucchipakoda",
    },
    {
      rating: 3,
      comment: "Eat it, just eat it!",
      author: "Michael Jaikishan",
      date: "2015-02-13T17:57:28.556094Z",
      name: "Zucchipakoda",
    },
    {
      rating: 4,
      comment: "Ultimate, Reaching for the stars!",
      author: "Ringo Starry",
      date: "2013-12-02T17:57:28.556094Z",
      name: "Zucchipakoda",
    },
    {
      rating: 2,
      comment: "It's your birthday, we're gonna party!",
      author: "25 Cent",
      date: "2011-12-02T17:57:28.556094Z",
      name: "Zucchipakoda",
    },
    {
      rating: 5,
      comment: "Imagine all the eatables, living in conFusion!",
      author: "John Lemon",
      date: "2012-10-16T17:57:28.556094Z",
      name: "Vadonut",
    },
    {
      rating: 4,
      comment:
        "Sends anyone to heaven, I wish I could get my mother-in-law to eat it!",
      author: "Paul McVites",
      date: "2014-09-05T17:57:28.556094Z",
      name: "Vadonut",
    },
    {
      rating: 3,
      comment: "Eat it, just eat it!",
      author: "Michael Jaikishan",
      date: "2015-02-13T17:57:28.556094Z",
      name: "Vadonut",
    },
    {
      rating: 4,
      comment: "Ultimate, Reaching for the stars!",
      author: "Ringo Starry",
      date: "2013-12-02T17:57:28.556094Z",
      name: "Vadonut",
    },
    {
      rating: 2,
      comment: "It's your birthday, we're gonna party!",
      author: "25 Cent",
      date: "2011-12-02T17:57:28.556094Z",
      name: "Vadonut",
    },
    {
      rating: 5,
      comment: "Imagine all the eatables, living in conFusion!",
      author: "John Lemon",
      date: "2012-10-16T17:57:28.556094Z",
      name: "ElaiCheese Cake",
    },
    {
      rating: 4,
      comment:
        "Sends anyone to heaven, I wish I could get my mother-in-law to eat it!",
      author: "Paul McVites",
      date: "2014-09-05T17:57:28.556094Z",
      name: "ElaiCheese Cake",
    },
    {
      rating: 3,
      comment: "Eat it, just eat it!",
      author: "Michael Jaikishan",
      date: "2015-02-13T17:57:28.556094Z",
      name: "ElaiCheese Cake",
    },
    {
      rating: 4,
      comment: "Ultimate, Reaching for the stars!",
      author: "Ringo Starry",
      date: "2013-12-02T17:57:28.556094Z",
      name: "ElaiCheese Cake",
    },
    {
      rating: 2,
      comment: "It's your birthday, we're gonna party!",
      author: "25 Cent",
      date: "2011-12-02T17:57:28.556094Z",
      name: "ElaiCheese Cake",
    },
  ];
  const CustomerTable = customers.map((cus, index) => CustomerRow(cus, index));

  return (
    <div className="container m-5 p-5">
      <div className="m-5 p-5">
        <div className="row">
          <div className="col-md-8">
            <h2>Customers</h2>
          </div>
          <div className="col-md-4 center">
            <ExportCsv csvData={customers} fileName={"myFile"} />
          </div>
        </div>
        <Table striped bordered hover>
          {tableHeader}
          {/* <tbody>{CustomerTable}</tbody> */}
        </Table>
      </div>
    </div>
  );
}

const CustomerRow = (customer, index) => {
  return (
    <tr key={index} className="even">
      <td>{index + 1}</td>
      <td>{customer.name}</td>
      <td>{customer.avg}</td>
      <td>{customer.grade}</td>
      {/* <td>{customer.html && customer.html.im}</td>
      <td>{customer.html && customer.html.em}</td> */}
      {/* <td>{customer.address}</td>
      <td>{customer.zipcode}</td> */}
    </tr>
  );
};

const tableHeader = (
  <thead>
    <tr>
      <th>#</th>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Email</th>
      <th>Address</th>
      <th>Zipcode</th>
    </tr>
  </thead>
);
