// CreateOrUpdateStudent.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import StudentListService from '../../services/Student-List-Service.js';

const CreateOrUpdateStudent = () => {
    const [Student, setStudent] = useState({
        studentname: '',
        studentemail: '',
        birthdate: '',
    });

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            StudentListService.getStudentById(id).then((res) => {

                const formattedDate = new Date(res.data[0].birthdate).toISOString().split('T')[0];
                setStudent({
                    ...res.data[0],
                    birthdate: formattedDate
                });
            });
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStudent((prevStudent) => ({
            ...prevStudent,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id) {
                await StudentListService.updateStudentByID(id, Student);
                alert('Student updated successfully');
            } else {
                await StudentListService.addnewStudent(Student);
                alert('Student created successfully');
            }
            navigate('/admin/Student-list');
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    return (
        <div className="container mt-4">
            <div className='row'>
                <div className='col-6'><h2>{id ? 'Edit Student' : 'Add Student'}</h2></div>

                <div className='col-6 d-flex justify-content-end'>
                    <Link to="/admin/student-list">
                        <button className='btn btn-md btn-success '>View studentlist</button></Link>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="studentname">Student Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="studentname"
                        name="studentname"
                        value={Student.studentname}
                        onChange={handleChange}
                    />
                    <div dangerouslySetInnerHTML={{ __html: Student.studentname }} />
                </div>
                <div className="form-group">
                    <label htmlFor="studentemail">studentemail</label>
                    <input
                        type="email"
                        className="form-control"
                        id="studentemail"
                        name="studentemail"
                        value={Student.studentemail}
                        onChange={handleChange}
                    />
                    <div dangerouslySetInnerHTML={{ __html: Student.studentemail }} />
                </div>
                <div className="form-group">
                    <label htmlFor="birthdate">Birthdate</label>
                    <input
                        type="date"
                        className="form-control"
                        id="birthdate"
                        name="birthdate"
                        value={Student.birthdate}
                        onChange={handleChange}
                    />

                </div>

                <button type="submit" className="btn btn-primary mt-3">
                    {id ? 'Update Student' : 'Create Student'}
                </button>
            </form>
        </div>
    );
};

export default CreateOrUpdateStudent;
