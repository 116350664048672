import { React, useState } from "react";
import CourseService from "../../services/CourseService";
import { useNavigate } from "react-router-dom";

export default function UpdateResult(props) {
  const [examCode, setExamCode] = useState("");
  const [examStatus, setExamStatus] = useState({ msg: "", show: false });
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      if (examCode !== "") {
        let res = await CourseService.getUpdateResult(examCode);
        if (res.data.success === true) {
          setExamStatus({ msg: res.data.msg, show: true });
          setTimeout(() => {
            setExamStatus({ msg: "", show: false });
            setExamCode("");
          }, 5000);
        } else {
          setExamStatus({ msg: res.data.msg, show: false });
          setTimeout(() => setExamStatus({ msg: "", show: false }), 5000);
        }
      } else {
        setExamStatus({ msg: "Please ExamId to add", show: false });
        setTimeout(() => setExamStatus({ msg: "", show: false }), 5000);
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <div class="container">
      <h1 class="pt-3 text-center">Please enter exam code update the result</h1>
      <div class="d-flex justify-content-center mt-3">
        <input
          className="w-25 py-1"
          type="text"
          placeholder="enter exam code"
          onChange={(e) => setExamCode(e.target.value)}
          value={examCode}
        />
        <div class="ms-2">
          <button onClick={handleSubmit} class="btn btn-success">
            Submit
          </button>
        </div>
      </div>
      {examStatus.msg !== "" && (
        <div
          class={
            examStatus.show === true
              ? "text-center mt-3 text-success h3"
              : "text-center mt-3 text-danger h3"
          }
        >
          {examStatus.msg}
        </div>
      )}
    </div>
  );
}
