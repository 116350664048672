import React, { useState } from "react";
import "./courseListBanner.scss";
import jwt_decode from "jwt-decode";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import { useParams, Link } from "react-router-dom";
import global from "../../servicesvariable";

export default function CourseBanner(props) {
  const {
    course,
    vcounter,
    tcounter,
    users,
    selectedcourses,
    setConfirmBox,
    allsubcourse,
  } = props;
  let { cname } = useParams();
  const data = [];
  allsubcourse.map((res) => {
    if (res.type.name == cname) {
      data.push(res);
    }
  });
  let sumTotalHours = 0;
  const getTotalHours = tcounter.map((i) => (sumTotalHours += Math.ceil(i.length * 8.5)));
  return (
    <div class="course-banner bg-light-gray">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-xl-9 text-dark-gray order-2 order-lg-1">
            <h1 class="mb-2 mb-md-4">{course?.name?.replaceAll("_", " ")}</h1>
            <p>{course.desc}</p>
            <div class="d-none d-lg-block">
              <span class="btn btn-white py-2 me-2 me-lg-3 mb-2 d-inline-block">
                {data.length > 1 ? `${data.length} courses` : `${data.length} course`}
              </span>
              <span class="btn btn-white py-2 me-2 me-lg-3 mb-2 d-inline-block">
                {/* {tcounter[0] && Math.ceil(tcounter[0].length * 8.5)}  */}
                {sumTotalHours + " "}
                Learning Hours
              </span>

              <span class="btn btn-blue py-2 d-inline-block mb-2">
                {users > 0 ? (
                  <>{users} Course you have subscribed</>
                ) : (
                  <>You have not subscribed any course</>
                )}
              </span>
            </div>
          </div>
          <div class="col-lg-4 col-xl-3 text-lg-center order-1 order-lg-2">
            {course.icon &&
              (course.icon == "default.jpg" ? (
                <img
                  src={`./../${course.icon}`}
                  class="card-img-top sub-course-img mb-lg-0 mb-4"
                  alt={course.icon}
                  height="150"
                  width="150"
                ></img>
              ) : course.icon.match(/http/g) ? (
                <img
                  src={course.icon}
                  class="card-img-top sub-course-img mb-lg-0 mb-4"
                  alt={course.icon}
                  height="150"
                  width="150"
                />
              ) : (
                <>
                  <img
                    src={`${global.imgURL}/${course.icon}`}
                    class="card-img-top sub-course-img mb-lg-0 mb-4"
                    alt={course.name}
                    height="150"
                    width="150"
                  ></img>
                </>
              ))}
          </div>
          <div class="d-block d-lg-none mt-3 text-center order-3">
            <span class="btn btn-white py-2 d-inline-block me-2 my-2">
              {data.length} courses
            </span>
            <span class="btn btn-white py-2 d-inline-block me-2 my-2">
              {tcounter[0] && Math.ceil(tcounter[0].length * 8.5)} Learning
              Hours
            </span>
            <span class="btn btn-blue py-2 d-inline-block me-2 my-2">
              {users > 0 ? (
                <>{users} Course you have subscribed</>
              ) : (
                <>You have not subscribed any course</>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
