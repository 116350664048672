import React, { useEffect, useState } from "react";
import CourseService from "../../services/CourseService";
import { useNavigate } from "react-router-dom";
import ToolTip from "../BsComponents/ToolTip";

const CloneTrainingPlan = (props) => {
  const [cloneDetail, setCloneDetail] = useState({
    courseId: "",
    subCourseId: "",
    tp_day: null,
    create_day: "",
    subcourse_Name: "",
    CourseLevel: "",
  });
  const [cloneDetailErrors, setCloneDetailErrors] = useState({
    courseId: [],
    subCourseId: [],
    create_day: [],
    tp_day: [],
    subcourse_Name: [],
    CourseLevel: [],
  });
  const [getAllCources, setGetAllCources] = useState([]);
  const [getSubCourses, setSubCoures] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [msg, setMsg] = useState({ msg: "", status: false });
  const level = ["Beginner", "Intermediate", "Advanced"];
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData(){
      const token = localStorage.getItem("token");
      if (token) {
        let cources = await CourseService.getCourses();
        setGetAllCources(cources.data);
        await CourseService.getSubCourses()
          .then((res) => {
            if (res.data) {
              res.data = res.data.filter((d)=>d.type != null)
              setSubCoures(res.data);
            }
          })
          .catch((ex) => console.log(ex));
      } else {
        navigate("/login");
      }
    }
    fetchData()
  }, []);

  useEffect(() => {
    async function fetchData(){
      if (cloneDetail.subCourseId !== "") {
        const token = localStorage.getItem("token");
        if (token) {
          const res = await CourseService.getTrainingPlanByID(
            cloneDetail.subCourseId,
            token
          );
          if (res.data && res.data.length > 0) {
            setCloneDetail({ ...cloneDetail, tp_day: res.data.length });
          } else {
            setCloneDetail({ ...cloneDetail, tp_day: 0 });
          }
        } else {
          navigate("/login");
        }
      }
    }
    fetchData()
    
  }, [cloneDetail.subCourseId]);

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "courseId":
        if (value.length == 0) {
          setCloneDetail({
            ...cloneDetail,
            courseId: "",
          });
          cloneDetailErrors.courseId.push("Required field For Course");
        } else {
          setCloneDetailErrors({
            ...cloneDetailErrors,
            courseId: [],
          });
          setCloneDetail({
            ...cloneDetail,
            courseId: value,
          });
        }
        break;
      case "subCourseId":
        if (value.length == 0) {
          setCloneDetail({
            ...cloneDetail,
            subCourseId: "",
          });
          cloneDetailErrors.subCourseId.push("Required field For subCourse");
        } else {
          setCloneDetailErrors({
            ...cloneDetailErrors,
            subCourseId: [],
          });
          setCloneDetail({
            ...cloneDetail,
            subCourseId: value,
          });
        }
        break;
      case "create_day":
        if (
          parseInt(value) > cloneDetail.tp_day ||
          parseInt(value) <= 0 ||
          value.length == 0
        ) {
          setCloneDetail({
            ...cloneDetail,
            create_day: "",
          });
          cloneDetailErrors.create_day.push(
            "Day value should be less than and equal to training plan day"
          );
        } else {
          setCloneDetailErrors({
            ...cloneDetailErrors,
            create_day: [],
          });
          setCloneDetail({
            ...cloneDetail,
            create_day: parseInt(value),
          });
        }
        break;
      case "subcourse_Name":
        if (value.length == 0) {
          setCloneDetail({
            ...cloneDetail,
            subcourse_Name: "",
          });
          cloneDetailErrors.subcourse_Name.push(
            "Required Field For Create Clone Course Name"
          );
        } else {
          if (value.includes(" ")) {
            setCloneDetail({
              ...cloneDetail,
              subcourse_Name: "",
            });
            cloneDetailErrors.subcourse_Name.push(
              "Create Clone SubCourse Name White Spaces Not Required"
            );
          } else {
            setCloneDetailErrors({
              ...cloneDetailErrors,
              subcourse_Name: [],
            });
            setCloneDetail({
              ...cloneDetail,
              subcourse_Name: value,
            });
          }
        }
        break;
      case "CourseLevel":
        if (value.length == 0) {
          setCloneDetail({
            ...cloneDetail,
            CourseLevel: "",
          });
          cloneDetailErrors.CourseLevel.push("Required field For CourseLevel");
        } else {
          setCloneDetailErrors({
            ...cloneDetailErrors,
            CourseLevel: [],
          });
          setCloneDetail({
            ...cloneDetail,
            CourseLevel: value,
          });
        }
        break;
      default:
        return true;
    }
  };

  const checkValid = (cloneDetails) => {
    const cloneErrors = {
      courseId: [],
      subCourseId: [],
      tp_day: [],
      create_day: [],
      subcourse_Name: [],
      CourseLevel: [],
    };
    let valid = true;
    const keys = Object.keys(cloneDetails);
    for (let cloneData of keys) {
      const value = cloneDetails[cloneData];
      if (value === "") {
        valid = false;
        cloneErrors[cloneData].push(`Required field`);
      }
      if (
        cloneData === "create_day" &&
        parseInt(cloneDetails["create_day"]) > parseInt(cloneDetails["tp_day"])
      ) {
        valid = false;
        cloneErrors[cloneData].push(
          "Create Day value less than training plan day value"
        );
      }
    }
    setCloneDetailErrors(cloneErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      const token = localStorage.getItem("token");
      if (token) {
        const validForm = checkValid(cloneDetail);
        if (
          validForm &&
          parseInt(cloneDetail.create_day) <= parseInt(cloneDetail.tp_day)
        ) {
          CourseService.createClone(cloneDetail, token)
            .then((res) => {
              if (res.data) {
                setMsg({ msg: res.data.msg, status: true });
                setConfirmBox(true);
                e.target.reset();
                setCloneDetail({
                  courseId: "",
                  subCourseId: "",
                  tp_day: null,
                  create_day: "",
                  subcourse_Name: "",
                  CourseLevel: "",
                });
                setTimeout(() => {
                  setConfirmBox(false);
                }, 5000);
              }
            })
            .catch((ex) => console.log(ex));
        } else {
          checkValid(cloneDetail);
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="container my-2 bg-white p-4">
      {confirmBox && (
        <div className="formloade">
          <div className="row text-center">
            <div className="col-6 w-100 confirmbox">
              <div
                className={`alert ${
                  msg.status ? "alert-success" : "alert-danger"
                }`}
              >
                {msg.msg}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="form px-lg-5">
        <h5 className="color-dgreen text-uppercase text-center heading">
          CREATE TRAINING PLAN CLONE
        </h5>
        <form onSubmit={handleSubmit} className="px-lg-5 py-3" id="myform">
          <div className="mb-4">
            <div className="row my-4">
              <div className="col-12 col-sm-12 col-md-4 col-lg-6 position-relative">
                <label htmlFor="scname" className="form-label">
                  Course name<span className="text-danger mx-1">*</span>
                </label>
                <select
                  className="form-select"
                  id="courseId"
                  name="courseId"
                  onChange={handleOnChange}
                >
                  <option value="">Select Course</option>
                  {getAllCources.map((item, index) => {
                    return (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>

                {cloneDetailErrors.courseId.length > 0 && (
                  <small className="form-text d-block text-red">
                    {cloneDetailErrors.courseId[0]}
                  </small>
                )}
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-6 position-relative">
                <label htmlFor="scname" className="form-label">
                  SubCourse name<span className="text-danger mx-1">*</span>
                </label>
                <select
                  className="form-select"
                  id="subCourseId"
                  name="subCourseId"
                  onChange={handleOnChange}
                >
                  <option value="">Select Subcourse</option>
                  {getSubCourses.map((item, index) => {
                    if (item.type._id === cloneDetail.courseId) {
                      return (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      );
                    }
                  })}
                </select>
                {cloneDetailErrors.subCourseId.length > 0 && (
                  <small className="form-text d-block text-red">
                    {cloneDetailErrors.subCourseId[0]}
                  </small>
                )}
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-sm-12 col-md-4 col-lg-6 position-relative">
                <label htmlFor="tp_day" className="form-label">
                  Training plan day<span className="text-danger mx-1">*</span>
                </label>
                <input
                  type="number"
                  name="tp_day"
                  value={cloneDetail.tp_day}
                  id="tp_day"
                  className="form-control"
                  disabled
                />
                {cloneDetail.tp_day == 0 && (
                  <small className="form-text d-block py-1 text-red">
                    Minimum 1 Training Plan Required For Create Clone
                  </small>
                )}
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-6 position-relative">
                <label htmlFor="tp_day" className="form-label">
                  Create Day<span className="text-danger mx-1">*</span>
                </label>
                <input
                  type="number"
                  name="create_day"
                  inputtype="numeric"
                  value={cloneDetail.create_day}
                  placeholder="Add Clone Days"
                  id="create_day"
                  className="form-control"
                  onChange={handleOnChange}
                />
                {cloneDetailErrors.create_day.length > 0 && (
                  <small className="form-text d-block text-red">
                    {cloneDetailErrors.create_day[0]}
                  </small>
                )}
              </div>
            </div>
            <div class="row my-2">
              <div className="col-12 col-sm-12 col-md-4 col-lg-6 position-relative">
                <label htmlFor="tp_day" className="form-label">
                  Create Clone Name<span className="text-danger mx-1">*</span>
                </label>
                <input
                  type="text"
                  name="subcourse_Name"
                  value={cloneDetail.subcourse_Name}
                  id="subcourse_Name"
                  placeholder="Create Clone SubCourse Name"
                  className="form-control"
                  onChange={handleOnChange}
                />

                {cloneDetailErrors.subcourse_Name.length > 0 && (
                  <small className="form-text d-block text-red">
                    {cloneDetailErrors.subcourse_Name[0]}
                  </small>
                )}
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-6 position-relative">
                <label htmlFor="level" className="form-label">
                  Select Course Level<span className="text-danger mx-1">*</span>
                </label>
                <select
                  className="form-select"
                  id="level"
                  name="CourseLevel"
                  value={cloneDetail.CourseLevel}
                  onChange={handleOnChange}
                >
                  <option value="">Select Level</option>
                  {level.map((LevelName, index) => {
                    return (
                      <option value={LevelName} key={LevelName}>
                        {LevelName}
                      </option>
                    );
                  })}
                </select>

                {cloneDetailErrors.CourseLevel.length > 0 && (
                  <small className="form-text d-block text-red">
                    {cloneDetailErrors.CourseLevel[0]}
                  </small>
                )}
              </div>
            </div>
          </div>
          <ToolTip title={"Create Training Plan Clone"}>
            <button
              type="submit"
              className="btn btn-submit"
              onClick={scrollToTop}
            >
              Create Clone
            </button>
          </ToolTip>
          <ToolTip title={"Reset"}>
            <button
              type="reset"
              className="btn btn-secondary ms-3"
              onClick={() => {
                setCloneDetail({
                  courseId: "",
                  subCourseId: "",
                  tp_day: null,
                  create_day: "",
                  subcourse_Name: "",
                  CourseLevel: "",
                });
                setCloneDetailErrors({
                  courseId: [],
                  subCourseId: [],
                  tp_day: [],
                  create_day: [],
                  subcourse_Name: [],
                  CourseLevel: [],
                });
              }}
            >
              Reset
            </button>
          </ToolTip>
        </form>
      </div>
    </div>
  );
};

export default CloneTrainingPlan;