import React, { useState, useEffect } from 'react';
import ProductandCategory from '../../../services/ProductandCategory';
import { useNavigate, Link } from 'react-router-dom';

const CategoryList = () => {
    const [products, setProducts] = useState([]);
    const Navigate = useNavigate()

    // Set initial state
    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = () => {
        ProductandCategory.getProduct().then(res => {
            setProducts(res.data)
        }).catch(err => console.log(err))
    }

    const handleDelete = (categoryId) => {
        // Implement delete functionality here
        ProductandCategory.deleteProduct(categoryId).then(res => {
            if (res.status === 200) {
                alert("Data Deleted succefully")
                fetchData()
            }
        }).catch(error => alert(error))
    };

    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center">
                <h2>Product List</h2>
                <Link to="/createProduct">
                    <button className="btn btn-success" >Add Products</button>
                </Link>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Stock Quantity</th>
                        <th>Manufacturer</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product, index) => (
                        <tr key={product.id}>
                            <td dangerouslySetInnerHTML={{ __html: index + 1 }} />
                            <td dangerouslySetInnerHTML={{ __html: product.name }} />
                            <td dangerouslySetInnerHTML={{ __html: product.description }} />
                            <td dangerouslySetInnerHTML={{ __html: product.price }} />
                            <td dangerouslySetInnerHTML={{ __html: product.stock_quantity }} />
                            <td dangerouslySetInnerHTML={{ __html: product.manufacturer }} />
                            <td>
                                <div className='d-flex'>
                                    <Link to={`/productdetails?id=${product.id}`}>
                                        <button title="view" className='btn me-1' onClick={() => Navigate(`/ productdetails ? id = ${product.id}`)} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                            </svg>
                                        </button>
                                    </Link>
                                    <Link to={`/updateproduct/${product.id}`}>
                                        <button className="btn btn-primary" >Edit</button>
                                    </Link>

                                    <button className="btn btn-danger ml-2 ms-1" onClick={() => handleDelete(product.id)}>Delete</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table >
        </div >
    );
};

export default CategoryList;
