import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import "./courseDetails.scss";
import CourseBanner from "../../components/courseBanner/courseBanner";
import Accordion from "../../components/accordion/accordionDetails";
import { useParams, Link } from "react-router-dom";
import { join } from "lodash";
import $ from "jquery";
import formloader from "../../images/formloading.svg";

export default function CourseDetails(props) {
  let { cname, sname } = useParams();
  const [scourse, setSCourse] = useState({});
  const [userdata, setUserdata] = useState([]);
  const [trainingplan, setTrainingplan] = useState([]);
  const [selectedcourses, setSelectedcourses] = useState([]);
  const [hcounter, setHcounter] = useState(0);
  const [pcounter, setPcounter] = useState(0);
  const [vcounter, setVcounter] = useState(0);
  const [users, setUser] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setConfirmBox(false);
    setLoading(true);
    let tmpcid = "";
    const token = localStorage.getItem("token");
    const data = [];
    CourseService.getSingleSubCourse(sname)
      .then((res) => {
        if (res.data) {
          tmpcid = res.data._id;
          setSCourse(res.data);
          // console.log("sdflksdf", res.data);
          LoginService.getUsers()
            .then((res1) => {
              if (res1.data) {
                res1.data.map((user) => {
                  user.courses.map((element) => {
                    if (element.name === res.data.name) {
                      data.push(user);
                    }
                  });
                });
                setUser(data.length);
              }
            })
            .catch((ex) => console.log(ex));
        }
      })
      .catch((ex) => console.log(ex));

    if (token) {
      setLoading(true);
      const decoded = jwt_decode(token);
      LoginService.getSingleUser(decoded.id, token).then((res) => {
        //Subcribed Courses
        if (res.data) {
          setUserdata(res.data);
          CourseService.getSubCoursesIn(
            { courses: res.data.courses },
            token
          ).then((result) => {
            if (result.data) {
              setSelectedcourses(result.data);
            } else {
              setSelectedcourses([]);
            }
          });
        }
        setLoading(false);
      });
    }
    CourseService.getDescription(sname)
      .then((res) => {
        res.data.sort((a, b) => {
          return a.tp_day - b.tp_day;
        });
        setTrainingplan(res.data);
        // setHcounter(res.data.length * 8);
      })
      .catch((ex) => console.log(ex));
    CourseService.getPpts(sname, token)
      .then((res) => {
        if (res.data != null) {
          setPcounter(res.data.ppts.length);
        }
      })
      .catch((ex) => console.log(ex));
    let vcount = 0;
    CourseService.getVideos(sname, token)
      .then((res) => {
        if (res.data.length > 0) {
          res.data.map((item) => {
            return (vcount += item.videos.length);
          });
          setVcounter(vcount);
        }
      })
      .catch((ex) => console.log(ex));
    setLoading(false);
  }, [sname, confirmBox]);

  useEffect(() => {
    $(window).scrollTop(0);
  }, []);

  const handleAutoSubscribe = () => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.AddSubscribeCourse(
      decoded.role,
      decoded.id,
      scourse._id,
      token
    )
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => console.log(ex));
  };

  const handleAutoSubscribed = () => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.deleteSubscriptionCourse(decoded.id, scourse._id, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => console.log(ex));
  };

  const handleSubscribe = () => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.addSubscriptionRequest(decoded.id, scourse._id, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => console.log(ex));
  };

  const handlePendingRequest = () => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.deleteSubscriptionRequest(decoded.id, scourse._id, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => console.log(ex));
  };
  const handleSubscribed = () => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.deleteSubscriptionCourse(decoded.id, scourse._id, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => console.log(ex));
  };

  return (
    <div>
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="200" />
            </div>
            <div className="col-12 text-white h4">Loading...</div>
          </div>
        </div>
      )}
      <CourseBanner
        scourse={scourse}
        vcount={vcounter}
        tcount={trainingplan.length}
        pcount={pcounter}
        user={users}
        selectedcourses={selectedcourses}
        userdata={userdata}
        setConfirmBox={setConfirmBox}
        handleAutoSubscribe={handleAutoSubscribe}
        handleAutoSubscribed={handleAutoSubscribed}
        handlePendingRequest={handlePendingRequest}
        handleSubscribe={handleSubscribe}
        handleSubscribed={handleSubscribed}
      />
      <section class="container">
        {trainingplan && trainingplan.length > 0 && (
          <h2 class="text-dark-gray font-weight-400 mb-0 mb-lg-3 mb-xl-4 pb-lg-2">
            What you will learn
          </h2>
        )}
        <Accordion tplan={trainingplan} />
        <div class="d-flex align-items-center justify-content-between mt-4">
          <div>
            <Link
              class="text-primary text-decoration-none"
              to={`/course/${cname}`}
              title="Back to course list"
            >
              Back to course list
            </Link>
          </div>
          {trainingplan && trainingplan.length > 0 && (
            <div>
              {selectedcourses !== 0 && scourse.status === true ? (
                <>
                  {userdata.role === "Student" ? (
                    Array.from(selectedcourses, (o) => o.name).indexOf(
                      sname
                    ) === -1 ? (
                      <button
                        className="btn btn-blue py-2"
                        onClick={() => {
                          handleAutoSubscribe();
                        }}
                      >
                        Subscribe
                      </button>
                    ) : (
                      <Link
                        className="btn btn-blue py-2"
                        to={`/course/${cname}/courseOverview/${sname}/tplan`}
                      >
                        Learn
                      </Link>
                    )
                  ) : (
                    <Link className="btn btn-blue py-2" to={`/login`}>
                      Subscribe
                    </Link>
                  )}
                </>
              ) : (
                <>
                  {userdata.role === "Student" ? (
                    Array.from(selectedcourses, (o) => o.name).indexOf(
                      sname
                    ) === -1 ? (
                      <>
                        {userdata.subscription &&
                        Array.from(
                          userdata.subscription,
                          (o) => o.course
                        ).indexOf(scourse._id) !== -1 ? (
                          <button
                            className="btn btn-blue py-2"
                            onClick={() => {
                              handlePendingRequest();
                            }}
                          >
                            Request Pending
                          </button>
                        ) : (
                          <button
                            className="btn btn-blue py-2"
                            onClick={() => {
                              handleSubscribe();
                            }}
                          >
                            Subscribe Request
                          </button>
                        )}
                      </>
                    ) : (
                      <button
                        className="btn btn-blue py-2"
                        onClick={() => {
                          handleSubscribed();
                        }}
                      >
                        Subscribed
                      </button>
                    )
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
