import {
  FaHome,
  FaLaptopCode,
  FaCalendarAlt,
  FaVideo,
  FaFileAlt,
  FaUserFriends,
  FaPlusCircle,
  FaPenNib,
  FaQuestionCircle,
  FaQuestion,
} from "react-icons/fa";
import { IoCreate } from "react-icons/io5";
import { AiFillSetting, AiFillBell, AiOutlineSolution } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

export const trainerRoutes = {
  course: [
    {
      navigation: "/admin/course/update-training-plan-request",
      parentPath: "course",
      childPath: "update-training-plan-request",
      children: (
        <>
          <AiFillSetting className="me-2 fs-5" /> Update Training Plan Request
        </>
      ),
    },
  ],
};

export const adminRoutes = {
  user: [
    {
      navigation: "/admin/user/manage",
      parentPath: "user",
      childPath: "manage",
      children: (
        <>
          <AiFillSetting className="me-2 fs-5" /> Manage User
        </>
      ),
    },
    {
      navigation: "/admin/user/subscription",
      parentPath: "user",
      childPath: "subscription",

      children: (
        <>
          <AiFillBell className="me-2 fs-5" /> Subscription Request
        </>
      ),
    },
    {
      navigation: "/admin/user/approval",
      parentPath: "user",
      childPath: "approval",
      children: (
        <>
          <AiFillBell className="me-2 fs-5" /> Approval Request
        </>
      ),
    },
    {
      navigation: "/admin/user/training-progress",
      parentPath: "user",
      childPath: "training-progress",
      children: (
        <>
          <AiFillBell className="me-2 fs-5" /> Training Progress
        </>
      ),
    },
    {
      navigation: "/admin/user/manage-user-analysis",
      parentPath: "user",
      childPath: "manage-user-analysis",
      children: (
        <>
          <FontAwesomeIcon icon={faChartLine} className="me-2 fs-5" /> User
          Analysis
        </>
      ),
    },
    {
      navigation: "/admin/user/video-progress",
      parentPath: "user",
      childPath: "video-progress",
      children: (
        <>
          <FontAwesomeIcon icon={faChartLine} className="me-2 fs-5" /> Video
          Progress
        </>
      ),
    },
  ],
  course: [
    {
      navigation: "/admin/course/manage-course",
      parentPath: "course",
      childPath: "manage-course",
      children: (
        <>
          <AiFillSetting className="me-2 fs-5" /> Manage Course
        </>
      ),
    },
    {
      navigation: "/admin/course/manage-subcourse",
      parentPath: "course",
      childPath: "manage-subcourse",
      children: (
        <>
          <AiFillSetting className="me-2 fs-5" /> Manage SubCourse
        </>
      ),
    },
    {
      navigation: "/admin/course/manage-category",
      parentPath: "course",
      childPath: "manage-category",
      children: (
        <>
          <AiFillSetting className="me-2 fs-5" /> Manage Category
        </>
      ),
    },
    {
      navigation: "/admin/course/ppt",
      parentPath: "course",
      childPath: "ppt",
      children: (
        <>
          <FaFileAlt className="me-2 fs-5" /> PPTs
        </>
      ),
    },
    {
      navigation: "/admin/course/m3u8video",
      parentPath: "course",
      childPath: "m3u8video",
      children: (
        <>
          <FaVideo className="me-2 fs-5" /> M3U8 Video
        </>
      ),
    },
    {
      navigation: "/admin/course/video",
      parentPath: "course",
      childPath: "video",
      children: (
        <>
          <FaVideo className="me-2 fs-5" /> Videos
        </>
      ),
    },
    {
      navigation: "/admin/course/training-plan",
      parentPath: "course",
      childPath: "training-plan",
      children: (
        <>
          <FaCalendarAlt className="me-2 fs-5" /> TrainingPlan
        </>
      ),
    },
    // {
    //   navigation: "/admin/course/update-content-request",
    //   parentPath: "course",
    //   childPath: "update-content-request",
    //   children: (
    //     <>
    //       <AiFillSetting className="me-2 fs-5" /> Content Requests
    //     </>
    //   ),
    // },
    {
      navigation: "/admin/course/manage-pdf",
      parentPath: "course",
      childPath: "manage-pdf",
      children: (
        <>
          <FaFileAlt className="me-2 fs-5" /> Manage Training Process
        </>
      ),
    },
    {
      navigation: "/admin/course/manage-plan",
      parentPath: "course",
      childPath: "manage-plan",
      children: (
        <>
          <FaFileAlt className="me-2 fs-5" /> Manage Batch Plan
        </>
      ),
    },
  ],
  ["batch-management"]: [
    {
      navigation: "/admin/batch-management/manage",
      parentPath: "batch-management",
      childPath: "manage",
      children: (
        <>
          <AiFillSetting className="me-2 fs-5" /> Manage Batch
        </>
      ),
    },
    {
      navigation: "/admin/batch-management/create",
      parentPath: "batch-management",
      childPath: "create",
      children: (
        <>
          <FaPlusCircle className="me-2 fs-5" /> Create New Batch
        </>
      ),
    },
    {
      navigation: "/admin/batch-management/add-day",
      parentPath: "batch-management",
      childPath: "add-day",
      children: (
        <>
          <FaPlusCircle className="me-2 fs-5" /> Add Day in Batch
        </>
      ),
    },
    {
      navigation: "/admin/batch-management/add-parent-batch",
      parentPath: "batch-management",
      childPath: "add-parent-batch",
      children: (
        <>
          <FaPlusCircle className="me-2 fs-5" /> Manage Parent Batch
        </>
      ),
    },
  ],
  test: [
    {
      navigation: "/admin/test/manage-questions",
      parentPath: "test",
      childPath: "manage-questions",
      children: (
        <>
          <FaPlusCircle className="me-2 fs-5" /> Import Question Bank
        </>
      ),
    },
    {
      navigation: "/admin/test/create-exam",
      parentPath: "test",
      childPath: "create-exam",
      children: (
        <>
          <IoCreate className="me-2 fs-5" /> Create Exam Schedule
        </>
      ),
    },
    {
      navigation: "/admin/test/manage-practical-questions",
      parentPath: "test",
      childPath: "manage-practical-questions",
      children: (
        <>
          <FaQuestion className="me-2 fs-5" /> Practical QuestionList
        </>
      ),
    },
    {
      navigation: "/admin/test/manage-mcq-questions",
      parentPath: "test",
      childPath: "manage-mcq-questions",
      children: (
        <>
          <FaQuestionCircle className="me-2 fs-5" /> Mcq QuestionList
        </>
      ),
    },
  ],
  result: [
    // {
    //   navigation: "/admin/result/parent-batch",
    //   parentPath: "result",
    //   childPath: "parent-batch",
    //   children: (
    //     <>
    //       <AiOutlineSolution className="me-2 fs-5" /> Result sheet
    //     </>
    //   ),
    // },
    {
      navigation: "/admin/result/update-request",
      parentPath: "result",
      childPath: "update-request",
      children: (
        <>
          <IoCreate className="me-2 fs-5" /> Update Result Request
        </>
      ),
    },
  ],
};
