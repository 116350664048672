import React, { useEffect, useState } from "react";
import CourseService from "../../services/CourseService";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import LoginService from "../../services/LoginService";

const CoursesList = ({ title }) => {
  const [cnames, setCnames] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (jwt_decode(token).role === "Trainer") {
      if (title === "PPTs" || title === "Courses") {
        LoginService.getSingleUser(jwt_decode(token).id, token)
          .then((res) => {
            if (res.data && res.data.courses?.length > 0) {
              res.data.courses.map((item) => {
                CourseService.getSinglesubcourse(item)
                  .then((result) => {
                    if (result.data && result.data.length > 0) {
                      setCnames((d) => [...d, result.data[0]]);
                    }
                  })
                  .catch((ex) => console.log(ex));
              });
            }
          })
          .catch((ex) => console.log(ex));
      }
    } else {
      CourseService.getCourses()
        .then((res) => {
          if (res.data) {
            setCnames(res.data);
          }
        })
        .catch((ex) => console.log(ex));
    }
  }, []);

  const handleCourseChange = (cname, id) => {
    let path;
    if (title === "Courses") {
      path = `/admin/user/manage-user-analysis/courses/${id}`;
    } else if (title === "PPTs") {
      path = `/admin/course/${id}/ppt`;
    } else if ((title = "Videos")) {
      path = `/admin/course/${id}/video`;
    }
    navigate(path, {
      state: cname,
    });
  };
  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li className={"breadcrumb-item active"}>{title}</li>
          </ol>
        </nav>
      </div>

      <div className="container p-2 px-3 mb-5">
        <div className="form px-lg-5 pt-3">
          <div className="row g-4 listcourses py-4">
            {cnames.map((item, index) => {
              return (
                <div key={index} className="col-lg-4">
                  <div
                    className="bg-white p-3 rounded citem"
                    onClick={() => {
                      handleCourseChange(item.name, item._id);
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursesList;
