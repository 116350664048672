import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { ImCancelCircle, ImArrowLeft } from "react-icons/im";
import datanotfoundimage from "../../assets/images/DataNotFound-image.png";
import BatchManagementServices from "../../services/BatchManagementServices";
import HandleMessage from "../../components/HandleMessage/HandleMessages";
// import "./form.scss";

export default function CorrectAnswer(props) {
  const [examQuestion, setExamQuestion] = useState([]);
  const [userName, setuserName] = useState("");
  const { examId, userId, examType } = useParams()
  const navigate = useNavigate()
  const [confirmBox, setConfirmBox] = useState(false)
  const [message, setMessage] = useState({ msg: "", status: false })
  const token = localStorage.getItem('token')

  useEffect(() => {
    getMcqsList()
  }, [])

  const handleMessage = (message, status, magtime = false) => {
    setConfirmBox(true)
    setMessage({ msg: message, status: status })
    if (magtime) {
      setTimeout(() => setConfirmBox(false), 3000)
    }
  }

  const handleCatchError = (err) => {
    if (err["response"]) {
      if (err["response"].status === 401) {
        if (err["response"]?.data?.message === "You are not authorized to view this content") {
          handleMessage(err["response"].data.message, false)
        } else {
          localStorage.removeItem("token");
          navigate("/login");
        }
      } else if (err["response"].status === 404) {
        handleMessage(err["response"]?.data?.message, false, true)
      } else {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  }

  const getMcqsList = () => {
    BatchManagementServices.getUserExamMcqs(examId, userId, examType, token).then((res) => {
      if (res.data) {
        setExamQuestion(res.data.data)
        setuserName(res.data.userName)
      }
    }).catch((error) => {
      handleCatchError(error)
    });
  }

  return (
    <>
      <div className="container my-2 bg-white p-4">
        {confirmBox && <HandleMessage message={message} />}
        {examQuestion?.length > 0 ? (
          <>
            <div className="mx-auto d-flex justify-content-center mt-2">
              <div className="me-5 d-flex">
                <div>Exam Type : {examType}</div>
                <div className="mx-4">UserName: {userName} </div>
              </div>
            </div>
            <div className="pt-3 ms-5">
              {examQuestion.map((Question, index) => {
                var char = 64;
                if (Question.id) {
                  return (
                    <div className="my-5">
                      {index + 1} . {Question.id.Question}
                      {Question.id.Option.map((option) => {
                        char = char + 1;
                        return (
                          <p
                            className={`mx-5 my-2 ${Question.option.find(
                              (item) => item.Id == option.Id
                            )
                              ? Question.id.Answer.find(
                                (item) => item.Id == option.Id
                              )
                                ? "text-success"
                                : "text-danger"
                              : Question.id.Answer.find(
                                (item) => item.Id == option.Id
                              )
                                ? "text-success"
                                : ""
                              }`}
                          >
                            {String.fromCharCode(char)}) {option.Value}{" "}
                            {Question.option.find(
                              (item) => item.Id == option.Id
                            ) ? (
                              Question.id.Answer.find(
                                (item) => item.Id == option.Id
                              ) ? (
                                <IoMdCheckmarkCircleOutline />
                              ) : (
                                <ImCancelCircle />
                              )
                            ) : Question.id.Answer.find(
                              (item) => item.Id == option.Id
                            ) ? (
                              <IoMdCheckmarkCircleOutline />
                            ) : (
                              ""
                            )}
                          </p>
                        );
                      })}
                    </div>
                  );
                }
              })}
            </div>
          </>
        ) : (
          <div class="col-12 col-xl-9 mb-lg-4 mx-auto">
            <section className="">
              <div class="row pt-lg-3 align-items-center">
                <div class="text-center my-lg-5 my-3">
                  <img
                    src={datanotfoundimage}
                    width="450px"
                    className="datanotfoundimage"
                  />
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
}
