import React, { useEffect, useState } from "react";
import LoginService from "../../services/LoginService";

export default function AccessRequest(props) {
  const [msg, setMsg] = useState("");
  useEffect(() => {
    LoginService.acceptAccessRequest(props.match.params.token)
      .then((res) => {
        
        if (res.status === 200 && res.data.success) {
          setMsg("success");
        }
      })
      .catch((err) => {
        console.log(err.response)
        if (err.response.data.error === "TokenExpired") {
          setMsg("expired");
        } else if (err.response.data.error === "AlreadyActivated") {
          setMsg("already");
        } else {
          setMsg("something");
        }
      });
  }, []);

  return (
    <div className="container mt-5 pt-5">
      {msg === "" && (
        <div>
          <h2>Please wait...</h2>
        </div>
      )}
      {msg === "success" && (
        <div>
          <h2>Account activated</h2>
          <p>
            You can now login into <a href="/login">Rxtutorial</a>
          </p>
        </div>
      )}
      {msg === "already" && (
        <div>
          <h2>Account already activated</h2>
          <p>
            You can now login into <a href="/login">Rxtutorial</a>
          </p>
        </div>
      )}
      {msg === "expired" && (
        <div>
          <h2>Token Expired</h2>
          <p>
            Please sign up again to activate account at{" "}
            <a href="/signup">Sign up</a>
          </p>
        </div>
      )}
      {msg === "something" && (
        <div>
          <h2>Something went wrong</h2>
          <p>You can come back later</p>
        </div>
      )}
    </div>
  );
}
