import React, { Component } from 'react';
import CanvasJSReact from '../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
class Chart extends Component {
	render() {
        var y = this.props.y;
        var data

        if (y !== 0) {
          data = [{ y: this.props.y, label: "Users who subscribe this course" },{ y: 100-this.props.y, label: "Users who Not subscribe this course" }]  
        }    
        else data = [{ y: 100-this.props.y, label: "Users who Not subscribe this course" }];
		const options = {
			theme: "dark2",
			animationEnabled: true,
			exportFileName: "New Year Resolutions",
			exportEnabled: true,
			title:{
				text: this.props.label + " course"
			},
			data: [{
				type: "pie",
				showInLegend: true,
				legendText: "{label}",
				toolTipContent: "{label}: <strong>{y}%</strong>",
				indexLabel: "{y}%",
				indexLabelPlacement: "inside",
				dataPoints: data
			}]
		}
		
		return (
		<div>
			<h5 style={{textAlign:"center",fontWeight:"bold"}}>
                how many user subscribe this course?
            </h5>
			<CanvasJSChart options = {options} 
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}
}

export default Chart;