import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import variable from '../../servicesvariable'

const AccessKey = () => {
    const accessKeyFileName = [
        "Amazon_AWS_Access_Secret_Key_Id"
        // "Amazon_AWS_Access_Key_Id"
        // , "Amazon_AWS_Secret_Key"
        // , "Amazon_MWS_Auth_Token"
        , "Amazon_SES_SMTP_Password"
        , "Consul_Token"
        , "Database_Connection_String_MongoDB_MySQL"
        , "Database_Connection_String_PostgreSQL"
        , "Devise_Secret_Key"
        , "Facebook_Access_Token"
        , "Facebook_App_ID"
        , "Facebook_App_Secret"
        , "Gitlab_Personal_Access_Token"
        , "Google_Cloud_API_Key"
        , "Google_OAuth_Access_Token"
        , "Heroku_API_Key"
        // , "JDBC_Database_Connection_String"
        //, "LinkedIn_API_Key"
        , "MailChimp_API_Key"
        //, "MailGun_API_Key"
        , "Mapbox_Token"
        //, "Nexmo_Secret"
        , "NPM_Access_Token"
        , "NuGet_API_Key"
        , "Omise_Secret_Key"
        , "Paypal_Access_Token"
        //, "Picatic_API_key"
        , "SendGrid_API_Key"
        , "Sentry_Auth_Token"
        , "Slack_Token"  // , "Slack_v1.x_Token"
        , "Slack_Webhook"
        , "Slack_App_Token"
        , "SonarQube_User_Token"
        , "Square_OAuth_Secret"
        , "Square_Personal_Access_Token"
        , "SSH_Key"
        , "Stripe_API_key"
        , "Symfony_Application_Secret"
        , "Teams_Webhook"
        , "Telegram_Bot_API_Token"
        , "Twilio_API_Key"
        , "Twitter_Tokens"
        // , "Twitter_Access_Token_Secret"
        // , "Twitter_API_Secret_Key"
    ]

    // const handleOpen = (access) => {
    //  window.open(access)
    // }
    return (
        <div className='container'>

            {
                accessKeyFileName.map((access) => {
                    return (
                        <div>  <a href={`/${access}.html`} target="_blank">
                            {access}
                        </a></div>
                    )
                })
            }

        </div>
    );
};

export default AccessKey;
