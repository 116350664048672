import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import variable from "../../servicesvariable";

export default function CkEditor(props) {
  const editorConfiguration = {
    toolbar: [
      "heading",
      "|",
      "fontfamily",
      "fontsize",
      "|",
      "alignment",
      "|",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "strikethrough",
      "underline",
      "subscript",
      "superscript",
      "|",
      "link",
      "|",
      "outdent",
      "indent",
      "|",
      "bulletedList",
      "numberedList",
      "todoList",
      "|",
      "code",
      "codeBlock",
      "|",
      "insertTable",
      "|",
      "uploadImage",
      "blockQuote",
      "|",
      "undo",
      "redo",
    ],
    // ckfinder: {
    //   // Upload the images to the server using the CKFinder QuickUpload command.
    //   uploadUrl: "http://localhost:5000/uploader",
    // },
    extraPlugins: [uploadPlugin],
  };
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            const API_URL = `${variable.TUTORIAL_API_LOGIN_URL}`;
            const UPLOAD_ENDPOINT = "upload_files";
            fetch(`${API_URL}${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${variable.uploadUrl}/${res.filename}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  return (
    <CKEditor
      editor={ClassicEditor}
      data={props.data || ""}
      onChange={props.onChange}
      onBlur={(event, editor) => {}}
      onFocus={(event, editor) => {}}
      config={editorConfiguration}
    />
  );
}

// plugins: [
// Essentials,
// Bold,
// Italic,
// Underline,
// Strikethrough,
// Code,
// Subscript,
// Superscript,
// Alignment,
// Autoformat,
// BlockQuote,
// Bold,
// CloudServices,
// Code,
// CodeBlock,
// DataFilter,
// DataSchema,
// Essentials,
// FindAndReplace,
// FontBackgroundColor,
// FontColor,
// FontFamily,
// FontSize,
// GeneralHtmlSupport,
// Heading,
// Highlight,
// HorizontalLine,
// HtmlComment,
// HtmlEmbed,
// Image,
// ImageCaption,
// ImageInsert,
// ImageResize,
// ImageStyle,
// ImageToolbar,
// ImageUpload,
// Indent,
// IndentBlock,
// Italic,
// Link,
// LinkImage,
// List,
// ListProperties,
// Markdown,
// MediaEmbed,
// MediaEmbedToolbar,
// Mention,
// PageBreak,
// Paragraph,
// PasteFromOffice,
// RemoveFormat,
// SourceEditing,
// SpecialCharacters,
// SpecialCharactersArrows,
// SpecialCharactersCurrency,
// SpecialCharactersEssentials,
// SpecialCharactersLatin,
// SpecialCharactersMathematical,
// SpecialCharactersText,
// StandardEditingMode,
// Strikethrough,
// Subscript,
// Superscript,
// Table,
// TableCaption,
// TableCellProperties,
// TableColumnResize,
// TableProperties,
// TableToolbar,
// TextPartLanguage,
// TextTransformation,
// Title,
// TodoList,
// Underline,
// WordCount,
// EditorWatchdog,
// ],

// import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";

// import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
// import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
// import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
// import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
// import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
// import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
// import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";

// import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment.js";
// import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
// import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
// // import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold.js";
// import CloudServices from "@ckeditor/ckeditor5-cloud-services/src/cloudservices.js";
// // import Code from "@ckeditor/ckeditor5-basic-styles/src/code.js";
// import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock.js";
// import DataFilter from "@ckeditor/ckeditor5-html-support/src/datafilter.js";
// import DataSchema from "@ckeditor/ckeditor5-html-support/src/dataschema.js";
// // import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials.js";
// import FindAndReplace from "@ckeditor/ckeditor5-find-and-replace/src/findandreplace.js";
// import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js";
// import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor.js";
// import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily.js";
// import FontSize from "@ckeditor/ckeditor5-font/src/fontsize.js";
// import GeneralHtmlSupport from "@ckeditor/ckeditor5-html-support/src/generalhtmlsupport.js";
// import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
// import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight.js";
// import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js";
// import HtmlComment from "@ckeditor/ckeditor5-html-support/src/htmlcomment.js";
// import HtmlEmbed from "@ckeditor/ckeditor5-html-embed/src/htmlembed.js";
// import Image from "@ckeditor/ckeditor5-image/src/image.js";
// import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
// import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert.js";
// import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
// import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
// import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
// import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
// import Indent from "@ckeditor/ckeditor5-indent/src/indent.js";
// import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock.js";
// // import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic.js";
// import Link from "@ckeditor/ckeditor5-link/src/link.js";
// import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage.js";
// import List from "@ckeditor/ckeditor5-list/src/list.js";
// import ListProperties from "@ckeditor/ckeditor5-list/src/listproperties.js";
// import Markdown from "@ckeditor/ckeditor5-markdown-gfm/src/markdown.js";
// import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed.js";
// import MediaEmbedToolbar from "@ckeditor/ckeditor5-media-embed/src/mediaembedtoolbar.js";
// import Mention from "@ckeditor/ckeditor5-mention/src/mention.js";
// import PageBreak from "@ckeditor/ckeditor5-page-break/src/pagebreak.js";
// import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph.js";
// import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js";
// import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat.js";
// import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting.js";
// import SpecialCharacters from "@ckeditor/ckeditor5-special-characters/src/specialcharacters.js";
// import SpecialCharactersArrows from "@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows.js";
// import SpecialCharactersCurrency from "@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency.js";
// import SpecialCharactersEssentials from "@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials.js";
// import SpecialCharactersLatin from "@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin.js";
// import SpecialCharactersMathematical from "@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical.js";
// import SpecialCharactersText from "@ckeditor/ckeditor5-special-characters/src/specialcharacterstext.js";
// import StandardEditingMode from "@ckeditor/ckeditor5-restricted-editing/src/standardeditingmode.js";
// // import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough.js";
// // import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript.js";
// // import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript.js";
// import Table from "@ckeditor/ckeditor5-table/src/table.js";
// import TableCaption from "@ckeditor/ckeditor5-table/src/tablecaption.js";
// import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
// import TableColumnResize from "@ckeditor/ckeditor5-table/src/tablecolumnresize.js";
// import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
// import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
// import TextPartLanguage from "@ckeditor/ckeditor5-language/src/textpartlanguage.js";
// import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation.js";
// import Title from "@ckeditor/ckeditor5-heading/src/title.js";
// import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
// // import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline.js";
// import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount.js";
// import EditorWatchdog from "@ckeditor/ckeditor5-watchdog/src/editorwatchdog.js";
