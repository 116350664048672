import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import { useParams, Link, useNavigate } from "react-router-dom";
import "./twoColCard.scss";
import jwt_decode from "jwt-decode";
import global from "../../servicesvariable";

export default function TwoColCard(props) {
  const navigate = useNavigate();
  const [tcounter, setTcounter] = useState([]);
  const [newlyAdded, setNewlyAdded] = useState([]);
  const [selectedcourses, setSelectedcourses] = useState([]);
  const token = localStorage.getItem("token");
  const [confirmBox, setConfirmBox] = useState(false);
  useEffect(() => {
    CourseService.getSubCourses().then((res) => {
      if (res.data) {
        setNewlyAdded(res.data.slice(-4));
        res.data.slice(-4).map((item) => {
          CourseService.getTrainingPlan(item.name).then((result) => {
            if (result.data) {
              let length = { length: result.data.length, name: item.name };
              setTcounter((d) => [...d, length]);
            } else {
              let length = {
                length: 0,
                name: item.name,
              };
              setTcounter((d) => [...d, length]);
            }
          });
        });
      }
    });
  }, []);

  useEffect(() => {
    setConfirmBox(false);
    if (token) {
      const decoded = jwt_decode(token);
      LoginService.getSingleUser(decoded.id, token)
        .then((res) => {
          if (res.data) {
            CourseService.getSubCoursesIn(
              { courses: res.data.courses },
              token
            ).then((result) => {
              if (result.data) {
                setSelectedcourses(result.data);
              } else {
                setSelectedcourses([]);
              }
            });
          } else {
            navigate("/login");
          }
        })
        .catch((ex) => { });
    }
  }, [confirmBox]);
  const handleAutoSubscribe = (sid) => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.AddSubscribeCourse(decoded.role, decoded.id, sid, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => { });
  };
  const newlyAddedCourses = (item, index) => {
    let sumTotalHours = 0;
    let totalModules = 0;
    const getHours = tcounter.find((i) => i.name === item.name);
    if (getHours) {
      sumTotalHours = Math.ceil(getHours.length * 8.5);
      totalModules = getHours.length;
    }
    return (
      <div class="col-lg-6 pt-xl-4 pt-lg-3 pt-3 two-col-item">
        <div class="card mb-lg-3 h-100">
          <div class="row g-0 h-100">
            <div class="col-xl-4 col-lg-4 col-md-3 course-img">
              {item.icon === "default.jpg" ? (
                <img src={`./../${item.icon}`} class="img-fluid" alt=""></img>
              ) : item.icon.match(/http/g) ? (
                <img
                  src={item.icon}
                  alt={item.name}
                  class="img-fluid"
                  height="150"
                  width="150"
                />
              ) : (
                <>
                  <img
                    src={`${global.imgURL}/${item.icon}`}
                    class="img-fluid"
                    alt={item.name}
                    height="150"
                    width="150"
                  ></img>
                </>
              )}
            </div>
            <div class="col-xl-8 col-lg-8 col-md-9">
              <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                <div class="d-flex flex-column flex-wrap h-100">
                  <h4 class="card-title text-black font-weight-400">
                    {item.name.replaceAll("_", "  ").replaceAll("-", " ")}
                  </h4>
                  <p className="card-text font-14 text-dark card-small-content mb-3 mb-lg-0">
                    {item.desc}
                  </p>

                  <div class="course-details flex-wrap d-flex pb-lg-3 pb-2 mt-auto">
                    <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                      <img
                        src={
                          require("../../assets/images/material-view-module.svg")
                            .default
                        }
                        alt="module"
                        width="16"
                        height="16"
                        class="me-2"
                      ></img>{" "}
                      <span class="font-14 text-dark-gray">
                        <strong>
                          {/* {(() => {
                            const t = [];
                            for (let i = 0; i < tcounter.length; i++) {
                              t.push(
                                <>
                                  {tcounter[i].name == item.name && (
                                    <>{tcounter[i].length}</>
                                  )}
                                </>
                              );
                            }
                            return t;
                          })()} */}
                          {totalModules}
                        </strong>{" "}
                        Modules
                      </span>
                    </div>
                    <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                      <img
                        src={
                          require("../../assets/images/material-timelapse.svg")
                            .default
                        }
                        alt="Hours"
                        width="16"
                        height="16"
                        class="me-2"
                      ></img>{" "}
                      <span class="font-14 text-dark-gray">
                        <strong>
                          {/* {(() => {
                            const t = [];
                            for (let i = 0; i < tcounter.length; i++) {
                              t.push(
                                <>
                                  {tcounter[i].name == item.name && (
                                    <>{Math.ceil(tcounter[i].length * 8.5)}</>
                                  )}
                                </>
                              );
                            }
                            return t;
                          })()} */}
                          {sumTotalHours}
                        </strong>{" "}
                        Hours
                      </span>
                    </div>
                    <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                      {item.level && (
                        <>
                          <img
                            src={
                              require("../../assets/images/feather-bar-chart.svg")
                                .default
                            }
                            alt="chart"
                            width="16"
                            height="16"
                            class="me-2"
                          ></img>{" "}
                          <span class="font-14 text-dark-gray">
                            For <strong>{item.level}</strong>
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between pt-lg-3 pt-2 btn-wrapper">
                  {item.type && (
                    <Link
                      to={`/course/${item.type.name}/courseDetails/${item.name}`}
                      class="text-primary sm-white-btn"
                    >
                      Course Details
                    </Link>
                  )}

                  <Link to={`/login`} class="text-primary sm-btn">
                    Subscribe
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const subscribeCourses = (item, index) => {
    let sumTotalHours = 0;
    let totalModules = 0;
    const getHours = tcounter.find((i) => i.name === item.name);
    if (getHours) {
      sumTotalHours = Math.ceil(getHours.length * 8.5);
      totalModules = getHours.length;
    }
    return (
      <div class="col-lg-6 pt-xl-4 pt-lg-3 pt-3 two-col-item">
        <div class="card mb-lg-3 h-100">
          <div class="row g-0 h-100">
            <div class="col-xl-4 col-lg-4 col-md-3 course-img">
              {item.icon === "default.jpg" ? (
                <img src={`./../${item.icon}`} class="img-fluid" alt=""></img>
              ) : item.icon.match(/http/g) ? (
                <img
                  src={item.icon}
                  alt={item.name}
                  class="img-fluid w-100"
                  height="150"
                  width="150"
                />
              ) : (
                <>
                  <img
                    src={`${global.imgURL}/${item.icon}`}
                    class="img-fluid"
                    alt={item.name}
                    height="150"
                    width="150"
                  ></img>
                </>
              )}
            </div>
            <div class="col-xl-8 col-lg-8 col-md-9">
              <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                <h4 class="card-title text-black font-weight-400">
                  {item.name.replaceAll("_", "  ").replaceAll("-", " ")}
                </h4>
                <p className="card-text font-14 text-dark card-small-content mb-3 mb-lg-0">
                  {item.desc}
                </p>

                <div class="course-details flex-wrap d-flex pb-lg-3 pb-2 mt-auto">
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    <img
                      src={
                        require("../../assets/images/material-view-module.svg")
                          .default
                      }
                      alt="module"
                      width="16"
                      height="16"
                      class="me-2"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      <strong>
                        {/* {(() => {
                          const t = [];
                          for (let i = 0; i < tcounter.length; i++) {
                            t.push(
                              <>
                                {tcounter[i].name == item.name && (
                                  <>{tcounter[i].length}</>
                                )}
                              </>
                            );
                          }
                          return t;
                        })()} */}
                        {totalModules}
                      </strong>{" "}
                      Modules
                    </span>
                  </div>
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    <img
                      src={
                        require("../../assets/images/material-timelapse.svg")
                          .default
                      }
                      alt="Hours"
                      width="16"
                      height="16"
                      class="me-2"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      <strong>
                        {/* {(() => {
                          const t = [];
                          for (let i = 0; i < tcounter.length; i++) {
                            t.push(
                              <>
                                {tcounter[i].name == item.name && (
                                  <>{Math.ceil(tcounter[i].length * 8.5)}</>
                                )}
                              </>
                            );
                          }
                          return t;
                        })()} */}
                        {sumTotalHours}
                      </strong>{" "}
                      Hours
                    </span>
                  </div>
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    {item.level && (
                      <>
                        <img
                          src={
                            require("../../assets/images/feather-bar-chart.svg")
                              .default
                          }
                          alt="chart"
                          width="16"
                          height="16"
                          class="me-2"
                        ></img>{" "}
                        <span class="font-14 text-dark-gray">
                          For <strong>{item.level}</strong>
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between pt-lg-3 pt-2 btn-wrapper">
                  {item.type && (
                    <Link
                      to={`/course/${item.type.name}/courseDetails/${item.name}`}
                      class="text-primary sm-white-btn"
                    >
                      Course Details
                    </Link>
                  )}
                  <Link
                    onClick={() => {
                      handleAutoSubscribe(item._id);
                    }}
                    class="text-primary sm-btn"
                  >
                    Subscribe
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const subscribedCourses = (item, index) => {
    let sumTotalHours = 0;
    let totalModules = 0;
    const getHours = tcounter.find((i) => i.name === item.name);
    if (getHours) {
      sumTotalHours = Math.ceil(getHours.length * 8.5);
      totalModules = getHours.length;
    }
    return (
      <div class="col-lg-6 pt-xl-4 pt-lg-3 pt-3 two-col-item">
        <div class="card mb-lg-3 h-100 subscribe-card">
          <div class="row g-0 h-100">
            <div class="col-xl-4 col-lg-4 col-md-3 course-img">
              {item.icon === "default.jpg" ? (
                <img src={`./../${item.icon}`} class="img-fluid" alt=""></img>
              ) : item.icon.match(/http/g) ? (
                <img
                  src={item.icon}
                  alt={item.name}
                  class="img-fluid"
                  height="150"
                  width="150"
                />
              ) : (
                <>
                  <img
                    src={`${global.imgURL}/${item.icon}`}
                    class="img-fluid"
                    alt={item.name}
                    height="150"
                    width="150"
                  ></img>
                </>
              )}
            </div>
            <div class="col-xl-8 col-lg-8 col-md-9">
              <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                <h4 class="card-title text-black font-weight-400">
                  {item.name.replaceAll("_", "  ").replaceAll("-", " ")}
                </h4>
                <p className="card-text font-14 text-dark card-small-content mb-3 mb-lg-0">
                  {item.desc}
                </p>

                <div class="course-details flex-wrap d-flex pb-lg-3 pb-2 mt-auto">
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    <img
                      src={
                        require("../../assets/images/material-view-module.svg")
                          .default
                      }
                      alt="module"
                      width="16"
                      height="16"
                      class="me-2"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      <strong>
                        {/* {(() => {
                          const t = [];
                          for (let i = 0; i < tcounter.length; i++) {
                            t.push(
                              <>
                                {tcounter[i].name == item.name && (
                                  <>{tcounter[i].length}</>
                                )}
                              </>
                            );
                          }
                          return t;
                        })()} */}
                        {totalModules}
                      </strong>{" "}
                      Modules
                    </span>
                  </div>
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    <img
                      src={
                        require("../../assets/images/material-timelapse.svg")
                          .default
                      }
                      alt="Hours"
                      width="16"
                      height="16"
                      class="me-2"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      <strong>
                        {/* {(() => {
                          const t = [];
                          for (let i = 0; i < tcounter.length; i++) {
                            t.push(
                              <>
                                {tcounter[i].name == item.name && (
                                  <>{Math.ceil(tcounter[i].length * 8.5)}</>
                                )}
                              </>
                            );
                          }
                          return t;
                        })()} */}
                        {sumTotalHours}
                      </strong>{" "}
                      Hours
                    </span>
                  </div>
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    {item.level && (
                      <>
                        <img
                          src={
                            require("../../assets/images/feather-bar-chart.svg")
                              .default
                          }
                          alt="chart"
                          width="16"
                          height="16"
                          class="me-2"
                        ></img>{" "}
                        <span class="font-14 text-dark-gray">
                          For <strong>{item.level}</strong>
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between pt-lg-3 pt-2 btn-wrapper">
                  {item.type && (
                    <Link
                      to={`/course/${item.type.name}/courseDetails/${item.name}`}
                      class="text-primary sm-white-btn"
                    >
                      Course Details
                    </Link>
                  )}
                  <Link
                    to={`/course/${item.type.name}/courseOverview/${item.name}/tplan#1`}
                    class="text-success sm-btn d-flex align-items-center justify-content-center"
                  >
                    <img
                      width="16"
                      height="16"
                      alt="bar btn"
                      src={
                        require("../../assets/images/check-icon.svg").default
                      }
                    />
                    &nbsp;Continue
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div class="two-col-card-wrapper">
      <h2 class="text-dark-gray font-weight-400 mb-lg-2 mb-0">
        New courses we have added
      </h2>
      <div class="row new-course">
        {newlyAdded.map((item, index) => {
          return localStorage.getItem("token") ? (
            jwt_decode(localStorage.getItem("token")).role === "Student" ? (
              Array.from(selectedcourses, (o) => o.name).indexOf(item.name) ===
                -1 ? (
                <>{subscribeCourses(item, index)}</>
              ) : (
                <>{subscribedCourses(item, index)}</>
              )
            ) : (
              <>{subscribedCourses(item, index)}</>
            )
          ) : (
            <>{newlyAddedCourses(item, index)}</>
          );
        })}
      </div>
    </div>
  );
}
