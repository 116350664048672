import React from "react";

export default function HandleMessage({ message }) {

    return (
        <>
            <div className="formloade">
                <div className="row text-center">
                    <div className="col-6 w-100 confirmbox">
                        <div
                            className={`alert ${message.status ? "alert-success" : "alert-danger"
                                }`}
                        >
                            {message.msg}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


