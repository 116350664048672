import React, { useState, useEffect } from "react";

export default function MyToast({
  children,
  show = false,
  id,
  status,
  msg,
  header,
  icon,
  setShow,
}) {
  const [showToast, setShowToast] = useState(show);
  setTimeout(() => {
    setShow(false);
  }, 3000);

  return (
    <>
      {children}
      <div
        className={`position-fixed bottom-0 end-0 px-3 py-5 mb-5 `}
        style={{ zIndex: 11 }}
      >
        <div
          id={id}
          className={`toast ${showToast ? "show" : "hide"} `}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            {/* <img src="..." className="rounded me-2" alt="..." /> */}
            <span className="me-2">{icon}</span>
            <strong className="me-auto">{header}</strong>
            {/* <small>11 mins ago</small> */}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={() => setShowToast(true)}
            ></button>
          </div>
          <div className={`toast-body bg-${status}`}>{msg}</div>
        </div>
      </div>
    </>
  );
}
