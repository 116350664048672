import React, { useState, useEffect } from 'react';
import ProductandCategory from '../../../services/ProductandCategory';
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom';

const CreateProduct = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const initialData = location.state?.initialData || null;
    const { id } = useParams();
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: '',
        stock_quantity: '',
        manufacturer: '',
        category_id: '',
    });

    const [categories, setCategories] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {

        // Fetch categories from the API
        ProductandCategory.getCateGory().then(res => {
            setCategories(res.data);
        });
        if (id) {
            ProductandCategory.getProductById(id).then(res => {
                if (res.data) {
                    setFormData(res.data[0])
                    setIsEditing(true)
                }
            }).catch(err => console.log(err))
        }

    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { name,
            description,
            price,
            stock_quantity,
            manufacturer,
            category_id } = formData

        const productData = {
            name,
            description,
            price,
            stock_quantity,
            manufacturer,
            category_id
        }

        if (isEditing) {
            // If initialData exists, it's an edit operation
            ProductandCategory.editProduct(id, productData)
                .then(res => {
                    if (res.status === 200) {
                        alert("Product updated successfully");
                        navigate('/productlisting');
                    }
                })
                .catch(err => alert(err));
        } else {
            // If initialData doesn't exist, it's a create operation
            ProductandCategory.addNewProduct(productData)
                .then(res => {
                    if (res.status === 201) {
                        alert("Product created successfully");
                        navigate('/productlisting');
                    }
                })
                .catch(err => alert(err));
        }
    };

    return (
        <div className='container'>
            <div className="d-flex justify-content-between align-items-center">
                <h2>{initialData ? 'Edit Product' : 'Add Product'}</h2>
                <Link to="/productlisting">
                    <button className="btn btn-success">View Product Listing</button>
                </Link>

            </div>

            <div className="container mt-4">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Product Name</label>
                        <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
                        <div dangerouslySetInnerHTML={{ __html: formData.name }} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea className="form-control" id="description" name="description" value={formData.description} onChange={handleChange}></textarea>
                        <div dangerouslySetInnerHTML={{ __html: formData.description }} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="price" className="form-label">Price</label>
                        <input type="number" className="form-control" id="price" name="price" value={formData.price} onChange={handleChange} required />

                    </div>
                    <div className="mb-3">
                        <label htmlFor="stock_quantity" className="form-label">Stock Quantity</label>
                        <input type="number" className="form-control" id="stock_quantity" name="stock_quantity" value={formData.stock_quantity} onChange={handleChange} required />

                    </div>
                    <div className="mb-3">
                        <label htmlFor="manufacturer" className="form-label">Manufacturer</label>
                        <input type="text" className="form-control" id="manufacturer" name="manufacturer" value={formData.manufacturer} onChange={handleChange} required />
                        <div dangerouslySetInnerHTML={{ __html: formData.manufacturer }} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="category_id" className="form-label">Category</label>
                        <select className="form-control" id="category_id" name="category_id" value={formData.category_id} onChange={handleChange} required>
                            <option value="" disabled>Select a category</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </select>
                    </div>
                    <button type="submit" className="btn btn-primary">{isEditing ? 'Update Product' : 'Add Product'}</button>
                </form>
            </div>
        </div >
    );
};

export default CreateProduct;
