import React from "react";
import "./accordionList.scss";
import { useParams, Link } from "react-router-dom";
import global from "../../servicesvariable";

export default function AccordionList(props) {
  const {
    allsubcourse,
    course,
    vcounter,
    tcounter,
    handleAutoSubscribe,
    jwt_decode,
    selectedcourses,
  } = props;

  // console.log(allsubcourse, course, vcounter, tcounter, handleAutoSubscribe, jwt_decode, selectedcourses)
  const handleSubcourses = (item, index) => {
    let sumTotalHours = 0;
    const getHours = tcounter.find((i) => i.name === item.name);
    if (getHours) {
      sumTotalHours = Math.ceil(getHours.length * 8.5);
    }
    return (
      <div class="col-lg-6 pt-xl-4 pt-lg-3 pt-3 two-col-item">
        <div class="card mb-lg-3 h-100">
          <div class="row g-0 h-100">
            <div class="col-xl-4 col-lg-4 col-md-3 course-img">
              {item.icon === "default.jpg" ? (
                <img src={`./../${item.icon}`} class="img-fluid" alt=""></img>
              ) : item.icon.match(/http/g) ? (
                <img
                  src={item.icon}
                  alt={item.name}
                  class="img-fluid"
                  height="150"
                  width="150"
                />
              ) : (
                <>
                  <img
                    src={`${global.imgURL}/${item.icon}`}
                    class="img-fluid"
                    alt={item.name}
                    height="150"
                    width="150"
                  ></img>
                </>
              )}{" "}
            </div>
            <div class="col-xl-8 col-lg-8 col-md-9">
              <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                <div class="d-flex flex-column flex-wrap h-100">
                  <h4 class="card-title text-black font-weight-400">
                    {item.name.replaceAll("_", "  ").replaceAll("-", " ")}
                  </h4>
                  <p className="card-text font-14 text-dark card-small-content mb-3 mb-lg-0">
                    {item.desc}
                  </p>
                  <div class="course-details flex-wrap d-flex pb-lg-3 pb-2 mt-auto">
                    <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                      <img
                        src={
                          require("../../assets/images/material-view-module.svg")
                            .default
                        }
                        alt=""
                        width="16"
                        height="16"
                        class="me-2"
                      ></img>{" "}
                      <span class="font-14 text-dark-gray">
                        <strong>
                          {(() => {
                            const t = [];
                            for (let i = 0; i < tcounter.length; i++) {
                              t.push(
                                <>
                                  {tcounter[i].name == item.name && (
                                    <>{tcounter[i].length}</>
                                  )}
                                </>
                              );
                            }
                            return t;
                          })()}
                        </strong>{" "}
                        Modules
                      </span>
                    </div>
                    <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                      <img
                        src={
                          require("../../assets/images/material-timelapse.svg")
                            .default
                        }
                        alt=""
                        width="16"
                        height="16"
                        class="me-2"
                      ></img>{" "}
                      <span class="font-14 text-dark-gray">
                        <strong>
                          {/* {(() => {
                            const t = [];
                            for (let i = 0; i < tcounter.length; i++) {
                              t.push(
                                <>
                                  {tcounter[i].name == item.name && (
                                    <>{Math.ceil(tcounter[i].length * 8.5)}</>
                                  )}
                                </>
                              );
                            }
                            return t;
                          })()} */}
                          {sumTotalHours}
                        </strong>{" "}
                        Hours
                      </span>
                    </div>
                    {item.level && (
                      <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                        <img
                          src={
                            require("../../assets/images/feather-bar-chart.svg")
                              .default
                          }
                          alt=""
                          width="16"
                          height="16"
                          class="me-2"
                        ></img>{" "}
                        <span class="font-14 text-dark-gray">
                          For <strong>{item.level}</strong>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between pt-lg-3 pt-2 btn-wrapper">
                  {item.type && (
                    <Link
                      to={`/course/${course.name}/courseDetails/${item.name}`}
                      class="text-primary sm-white-btn"
                    >
                      Course Details
                    </Link>
                  )}
                  <Link to={`/login`} class="text-primary sm-btn">
                    Subscribe
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const subscribeCourses = (item, index) => {
    let sumTotalHours = 0;
    const getHours = tcounter.find((i) => i.name === item.name);
    if (getHours) {
      sumTotalHours = Math.ceil(getHours.length * 8.5);
    }
    const CountModule = () => {
      const t = [];
      for (let i = 0; i < tcounter.length; i++) {
        t.push(
          <>
            {tcounter[i].name == item.name && (
              <><strong>{tcounter[i].length}</strong> {tcounter[i].length > 1 ? ` Modules` : ` Module`}</>
            )}
          </>
        );
      }
      return t
    }
    return (
      <div class="col-lg-6 pt-xl-4 pt-lg-3 pt-3 two-col-item">
        <div class="card mb-lg-3 h-100">
          <div class="row g-0 h-100">
            <div class="col-xl-4 col-lg-4 col-md-3 course-img">
              {item.icon === "default.jpg" ? (
                <img src={`./../${item.icon}`} class="img-fluid" alt=""></img>
              ) : item.icon.match(/http/g) ? (
                <img
                  src={item.icon}
                  alt={item.name}
                  class="img-fluid"
                  height="150"
                  width="150"
                />
              ) : (
                <>
                  <img
                    src={`${global.imgURL}/${item.icon}`}
                    class="img-fluid"
                    alt={item.name}
                    height="150"
                    width="150"
                  ></img>
                </>
              )}{" "}
            </div>
            <div class="col-xl-8 col-lg-8 col-md-9">
              <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                <h4 class="card-title text-black font-weight-400">
                  {item.name.replaceAll("_", "  ").replaceAll("-", " ")}
                </h4>
                <p className="card-text font-14 text-dark card-small-content mb-3 mb-lg-0">
                  {item.desc}
                </p>
                <div class="course-details flex-wrap d-flex pb-lg-3 pb-2 mt-auto">
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    <img
                      src={
                        require("../../assets/images/material-view-module.svg")
                          .default
                      }
                      alt=""
                      width="16"
                      class="me-2"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      <span>
                        {CountModule()}
                      </span>{" "}
                    </span>
                  </div>
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    <img
                      src={
                        require("../../assets/images/material-timelapse.svg")
                          .default
                      }
                      alt=""
                      width="16"
                      class="me-2"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      <strong>
                        {/* {(() => {
                          const t = [];
                          for (let i = 0; i < tcounter.length; i++) {
                            t.push(
                              <>
                                {tcounter[i].name == item.name && (
                                  <>{Math.ceil(tcounter[i].length * 8.5)}</>
                                )}
                              </>
                            );
                          }
                          return t;
                        })()} */}
                        {sumTotalHours}
                      </strong>{" "}
                      {sumTotalHours > 1 ? 'Hours' : 'Hour'}
                    </span>
                  </div>
                  {item.level && (
                    <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                      <img
                        src={
                          require("../../assets/images/feather-bar-chart.svg")
                            .default
                        }
                        alt=""
                        width="16"
                        class="me-2"
                      ></img>{" "}
                      <span class="font-14 text-dark-gray">
                        For <strong>{item.level}</strong>
                      </span>
                    </div>
                  )}
                </div>
                <div class="d-flex flex-wrap justify-content-between pt-lg-3 pt-2 btn-wrapper">
                  {item.type && (
                    <Link
                      to={`/course/${course.name}/courseDetails/${item.name}`}
                      class="text-primary sm-white-btn"
                    >
                      Course Details
                    </Link>
                  )}
                  <Link
                    onClick={() => {
                      handleAutoSubscribe(item._id);
                    }}
                    class="text-primary sm-btn"
                  >
                    Subscribe
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const subscribedCourses = (item, index) => {
    let sumTotalHours = 0;
    const getHours = tcounter.find((i) => i.name === item.name);
    if (getHours) {
      sumTotalHours = Math.ceil(getHours.length * 8.5);
    }
    const CountModule = () => {
      const t = [];
      for (let i = 0; i < tcounter.length; i++) {
        t.push(
          <>
            {tcounter[i].name == item.name && (
              <><strong>{tcounter[i].length}</strong> {tcounter[i].length > 1 ? ` Modules` : ` Module`}</>
            )}
          </>
        );
      }
      return t
    }
    return (
      <div class="col-lg-6 pt-xl-4 pt-lg-3 pt-3 two-col-item">
        <div class="card subscribe-card mb-lg-3 h-100">
          <div class="row g-0 h-100">
            <div class="col-xl-4 col-lg-4 col-md-3 course-img">
              {item.icon === "default.jpg" ? (
                <img src={`./../${item.icon}`} class="img-fluid" alt=""></img>
              ) : item.icon.match(/http/g) ? (
                <img
                  src={item.icon}
                  alt={item.name}
                  class="img-fluid"
                  height="150"
                  width="150"
                />
              ) : (
                <>
                  <img
                    src={`${global.imgURL}/${item.icon}`}
                    class="img-fluid"
                    alt={item.name}
                    height="150"
                    width="150"
                  ></img>
                </>
              )}
            </div>
            <div class="col-xl-8 col-lg-8 col-md-9">
              <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                <h4 class="card-title text-black font-weight-400">
                  {item.name.replaceAll("_", "  ").replaceAll("-", " ")}
                </h4>
                <p className="card-text font-14 text-dark card-small-content mb-3 mb-lg-0">
                  {item.desc}
                </p>
                <div class="course-details flex-wrap d-flex pb-lg-3 pb-2 mt-auto">
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    <img
                      src={
                        require("../../assets/images/material-view-module.svg")
                          .default
                      }
                      alt=""
                      width="16"
                      class="me-2"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      {" "}
                      {CountModule()}
                    </span>
                  </div>
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    <img
                      src={
                        require("../../assets/images/material-timelapse.svg")
                          .default
                      }
                      alt=""
                      width="16"
                      class="me-2"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      <strong>
                        {/* {(() => {
                          const t = [];
                          for (let i = 0; i < tcounter.length; i++) {
                            t.push(
                              <>
                                {tcounter[i].name == item.name && (
                                  <>{Math.ceil(tcounter[i].length * 8.5)}</>
                                )}
                              </>
                            );
                          }
                          return t;
                        })()} */}
                        {sumTotalHours + " "}
                      </strong>{" "}
                      {sumTotalHours > 1 ? 'Hours' : 'Hour'}
                    </span>
                  </div>
                  {item.level && (
                    <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                      <img
                        src={
                          require("../../assets/images/feather-bar-chart.svg")
                            .default
                        }
                        alt=""
                        width="16"
                        class="me-2"
                      ></img>{" "}
                      <span class="font-14 text-dark-gray">
                        For <strong>{item.level}</strong>
                      </span>
                    </div>
                  )}
                </div>
                <div class="d-flex flex-wrap justify-content-between pt-lg-3 pt-2 btn-wrapper">
                  {item.type && (
                    <Link
                      to={`/course/${course.name}/courseDetails/${item.name}`}
                      class="text-primary sm-white-btn"
                    >
                      Course Details
                    </Link>
                  )}
                  <Link
                    to={`/course/${item.type.name}/courseOverview/${item.name}/tplan#1`}
                    class="text-success sm-btn d-flex align-items-center justify-content-center"
                  >
                    <img
                      width="16"
                      height="16"
                      alt="bar btn"
                      src={
                        require("../../assets/images/check-icon.svg").default
                      }
                    />
                    &nbsp;Continue
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div class="two-col-card-wrapper">
      <div class="row new-course">
        {allsubcourse.map((item, index) => {
          return localStorage.getItem("token")
            ? jwt_decode(localStorage.getItem("token")).role === "Student"
              ? Array.from(selectedcourses, (o) => o.name).indexOf(
                item.name
              ) === -1
                ? course._id == item.type._id && subscribeCourses(item, index)
                : course._id == item.type._id && subscribedCourses(item, index)
              : course._id == item.type._id && subscribedCourses(item, index)
            : course._id == item.type._id && handleSubcourses(item, index);
        })}
      </div>
    </div>
  );
}
