import React, { useEffect, useState } from "react";
import img1 from "../../assets/images/course-img.png";
import "./subscribeSlider.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LoginService from "../../services/LoginService";
import global from "../../servicesvariable";

export default function SubscribeSlider(props) {
  const [latestUser, setLatestUser] = useState([]);
  const getRecentlySubscribedUsers = async () => {
    try {
      const users = await LoginService.getRecentlySubscribedUsers();
      if (!users.data.success) {
        console.log("Error getRecentlySubscribedUsers", users.data.msg);
      }
      setLatestUser(users.data.data);
    } catch (error) {
      console.log("Error getRecentlySubscribedUsers", error);
    }
  };
  useEffect(() => {
    getRecentlySubscribedUsers();
  }, []);
  var settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    dots: true,
    arrows: false,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const recentlySubscribe = (item, index) => {
    // var l = item.courses.length - 1;
    return (
      <>
        <div class="col-4 col-sm-3 course-img">
          {item.courses.length > 0 &&
          item.courses[0].icon &&
          item.courses[0].icon === "default.jpg" ? (
            <img
              src={`./../${item.courses[0].icon}`}
              class="img-fluid h-100"
              alt={item.courses[0].icon}
              width="150"
              height="150"
            ></img>
          ) : item.courses.length > 0 &&
            item.courses[0].icon &&
            item.courses[0].icon.match(/http/g) ? (
            <img
              src={item.courses[0].icon}
              alt={item.courses[0].icon}
              class="img-fluid w-100"
              width="150"
              height="150"
            />
          ) : (
            item.courses.length > 0 &&
            item.courses[0].icon && (
              <>
                <img
                  src={`${global.imgURL}/${item.courses[0].icon}`}
                  class="img-fluid h-100"
                  alt={item.courses[0].name}
                  height="150"
                  width="150"
                ></img>
              </>
            )
          )}
        </div>
        <div class="col-8 col-sm-9">
          <div class="card-body p-3">
            <h4 class="card-title text-black font-weight-400">
              {item.courses[0] &&
                item.courses[0].name
                  .replaceAll("_", "iframe")
                  .replaceAll("-", " ")}
            </h4>
            <p class="card-text m-0 font-12 text-gray">Subscribed by</p>
            <p class="card-text text-dark text-capitalize">{item.username}</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <div class="subscribe-slider position-relative">
      <h2 class="text-dark-gray font-weight-400 mb-xl-4 mb-lg-3 mb-2 pb-2">
        Who subscribed recently
      </h2>

      <div class="px-12px">
        {latestUser && latestUser.length > 0 && (
          <Slider {...settings}>
            {latestUser.map((item, index) => {
              return (
                <div class="subscribe-slide h-100">
                  <div class="card mb-3 h-100">
                    <div class="row g-0 h-100">
                      {recentlySubscribe(item, index)}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
}
