import axios from "axios";
import variable from "../servicesvariable";

class PrductCategoryService {
    getCateGory() {
        return axios.get(variable.Tutorial_API_GetCategory)
    }

    addCategory(categorydata) {
        return axios.post(variable.Tutorial_Api_AddCategory,
            categorydata
        )
    }

    editCategory(id, updatedData) {
        return axios.put(variable.Tutorial_Api_EditCategory + id,
            updatedData)
    }

    getCategoryById(id) {
        return axios.get(variable.Tutorial_API_GetCategory + id)
    }

    deleteCategory(id) {
        return axios.delete(variable.Tutorial_Api_deleteCategory + id)
    }

    getProduct() {
        return axios.get(variable.Tutorial_API_GetProducts)
    }

    getProductById(id) {
        return axios.get(variable.Tutorial_API_GetProducts + 'getProducts/' + id)
    }

    addNewProduct(productData) {
        return axios.post(variable.Tutorial_API_AddProduct,
            productData
        )
    }

    editProduct(id, poductData) {
        return axios.put(variable.Tutorial_Api_EditProduct + id,
            poductData)
    }

    deleteProduct(id) {
        return axios.delete(variable.Tutorial_Api_deleteProduct + id)
    }
}

export default new PrductCategoryService();