import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Batch from '../../services/Batch';

const BatchDetails = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    let id = queryParams.get('id')
    const [formData, setFormData] = useState([{ name: '', description: '', department: '' }]);
    useEffect(() => {
        if (id) {
            Batch.getBatchById(id).then(res => {
                setFormData(res.data)
            }).catch(err => { setFormData([]) })
        }
    }, [])
    return (
        <div className='container'>
            <div className="d-flex justify-content-between align-items-center">
                <h2>Batch Details</h2>
                <Link to="/admin/Batch/list">
                    <button className="btn btn-success">View Batch List</button></Link>
            </div>
            <div className='row'>
                {formData && formData.length > 0 ? formData.map(batch => {
                    return (

                        <div className="card mt-5 mx-auto" style={{ width: '36rem' }}>
                            <div className="card-body">
                                <h4> BatchName:</h4>  <h6 className="card-title" dangerouslySetInnerHTML={{ __html: batch.Name }} /><hr />
                                <h4>Description:</h4> <p className="card-text" dangerouslySetInnerHTML={{ __html: batch.Description }} /><hr />
                                <h4>Department:</h4> <p className="card-text" dangerouslySetInnerHTML={{ __html: batch.Department }} /><hr />
                            </div>
                        </div>)
                }) : (
                    <>
                        <h5 dangerouslySetInnerHTML={{ __html: `No data found ${id}` }} />
                    </>
                )
                }
            </div>
        </div>
    );
};

export default BatchDetails;