import React, { useEffect, useState } from "react";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import { BsCheckCircle } from "react-icons/bs";
import jwt_decode from "jwt-decode";
import { useParams, Link } from "react-router-dom";
import { FaClock, FaFileAlt, FaVideo } from "react-icons/fa";
import Header from "../../components/header/Header";

export default function Discription(props) {
  let { cname, sname } = useParams();
  const [scourse, setSCourse] = useState({});
  const [userdata, setUserdata] = useState([]);
  const [trainingplan, setTrainingplan] = useState([]);
  const [selectedcourses, setSelectedcourses] = useState([]);
  const [hcounter, setHcounter] = useState(0);
  const [pcounter, setPcounter] = useState(0);
  const [vcounter, setVcounter] = useState(0);
  const [confirmBox, setConfirmBox] = useState(false);

  useEffect(() => {
    setConfirmBox(false);
    let tmpcid = "";
    const token = localStorage.getItem("token");
    CourseService.getSingleSubCourse(sname)
      .then((res) => {
        if (res.data) {
          tmpcid = res.data._id;
          setSCourse(res.data);
        }
      })
      .catch((ex) => console.log(ex));

    if (token) {
      const decoded = jwt_decode(token);
      LoginService.getSingleUser(decoded.id, token).then((res) => {
        //Subcribed Courses
        if (res.data) {
          setUserdata(res.data);
          CourseService.getSubCoursesIn({ courses: res.data.courses },token).then(
            (result) => {
              if (result.data) {
                setSelectedcourses(result.data);
              } else {
                setSelectedcourses([]);
              }
            }
          );
        }
      });
    }
    CourseService.getDescription(sname)
      .then((res) => {
        res.data.sort((a, b) => {
          return a.tp_day - b.tp_day;
        });
        setTrainingplan(res.data);
        setHcounter(res.data.length * 8);
      })
      .catch((ex) => console.log(ex));
    CourseService.getPpts(sname, token)
      .then((res) => {
        if (res.data != null) {
          setPcounter(res.data.ppts.length);
        }
      })
      .catch((ex) => console.log(ex));
    let vcount = 0;
    CourseService.getVideos(sname, token)
      .then((res) => {
        if (res.data.length > 0) {
          res.data.map((item) => {
            return (vcount += item.videos.length);
          });
          setVcounter(vcount);
        }
      })
      .catch((ex) => console.log(ex));
  }, [sname, confirmBox]);

  const handleAutoSubscribe = () => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.AddSubscribeCourse(
      decoded.role,
      decoded.id,
      scourse._id,
      token
    )
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => console.log(ex));
  };

  const handleAutoSubscribed = () => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.deleteSubscriptionCourse(decoded.id, scourse._id, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => console.log(ex));
  };

  const handleSubscribe = () => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.addSubscriptionRequest(decoded.id, scourse._id, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => console.log(ex));
  };

  const handlePendingRequest = () => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.deleteSubscriptionRequest(decoded.id, scourse._id, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => console.log(ex));
  };
  const handleSubscribed = () => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.deleteSubscriptionCourse(decoded.id, scourse._id, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => console.log(ex));
  };
  return (
    <>
      {/* <Header /> */}
      <div className="container discription">
        <h3 className="mb-5">{sname}</h3>
        <div className="dataframe bg-white p-3">
          <div className="pt-3  row">
            <p className="col-lg-1"></p>
            <strong className=" col-lg-11  py-1">About this course</strong>
            <p className="col-lg-1"></p>
            <p className=" small py-2 col-lg-8 col-md-8 col-sm-8 col-12">
              {scourse.desc} <br />
            </p>
            <p className="col-lg-3 col-md-3 col-sm-3 col-12 ">
              <FaClock className="me-2 my-2 fs-3" /> <strong>Hours :</strong>
              {hcounter}
              <br />
              <FaVideo className="me-2 my-2 fs-3" />
              <strong>Videos :</strong>
              {vcounter}
              <br />
              <FaFileAlt className="me-2 my-2 fs-3" />
              <strong>PPTs :</strong>
              {pcounter}
              <br />
              {selectedcourses !== 0 && scourse.status === true ? (
                <>
                  <div className="d-flex justify-content-center pt-3 footer w-100">
                    {userdata.role === "Student" ? (
                      Array.from(selectedcourses, (o) => o.name).indexOf(
                        sname
                      ) === -1 ? (
                        <>
                          <button
                            className="btn bg-lblue text-light py-1 w-100"
                            onClick={() => {
                              handleAutoSubscribe();
                            }}
                          >
                            Subscribe
                          </button>
                        </>
                      ) : (
                        <div className="w-100">
                          <button
                            className="btn bg-secondary text-light py-1 w-100 mb-1"
                            // onClick={() => {
                            //   handleAutoSubscribed();
                            // }}
                          >
                            Subscribed
                          </button>
                          <br />
                          <Link
                            to={`/course/${cname}/subcourse/${sname}`}
                            style={{ float: "right", textDecoration: "none" }}
                          >
                            TrainingPlan &#x25BA;
                          </Link>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center pt-3 footer w-100">
                    {userdata.role === "Student" ? (
                      Array.from(selectedcourses, (o) => o.name).indexOf(
                        sname
                      ) === -1 ? (
                        <>
                          {userdata.subscription &&
                          Array.from(
                            userdata.subscription,
                            (o) => o.course
                          ).indexOf(scourse._id) !== -1 ? (
                            <button
                              className="btn bg-white fw-bold color-lblue border border-lblue py-1 form-control"
                              onClick={() => {
                                handlePendingRequest();
                              }}
                            >
                              Request Pending
                            </button>
                          ) : (
                            <button
                              className="btn bg-dblue text-light py-1 form-control"
                              onClick={() => {
                                handleSubscribe();
                              }}
                            >
                              Subscribe Request
                            </button>
                          )}
                        </>
                      ) : (
                        <button
                          className="btn bg-secondary  text-light py-1 form-control"
                          onClick={() => {
                            handleSubscribed();
                          }}
                        >
                          Subscribed
                        </button>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </p>
            <hr />
            <div className="col-lg-1"></div>
            <div className="row col-lg-10 my-3 accordion " id="accordionDay">
              {trainingplan.map((item, index) => {
                return (
                  <div
                    className="accordion-item col-lg-10 align-content-center justify-content-between center "
                    key={item._id}
                  >
                    <strong className="accordion-header">
                      <button
                        className="accordion-button bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#desc" + index}
                        aria-expanded="false"
                        aria-controls={"desc" + index}
                      >
                        Module {item.tp_day}
                      </button>
                    </strong>
                    <div
                      id={"desc" + index}
                      className={
                        index === 0
                          ? "accordion-collapse collapse show"
                          : "accordion-collapse collapse"
                      }
                      data-bs-parent="#accordionDay"
                    >
                      <div className="accordion-body">
                        <ul>
                          {item.tp_whattolearn &&
                            item.tp_whattolearn
                              .split("\n")
                              .map((learn, index) => (
                                <p key={index}>
                                  <BsCheckCircle className="fs-5 me-2 color-green" />
                                  {learn}
                                </p>
                              ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
