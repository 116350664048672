import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CourseService from "../../services/CourseService";
import { FiDownload } from "react-icons/fi";

export default function ExamVideo() {
  let { batchName, skillName, examCode, hostId, userId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [userName, setUserName] = useState("");
  const [questionNumber, setQuestionNumber] = useState("Q001");
  const [examType, setExamType] = useState("");
  const [examSkill, setExamSkill] = useState("");
  const [videoType, setVideoType] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      CourseService.getSingleExamSchedule(examCode, userId, token)
        .then((res) => {
          if (res.data) {
            if (res.data.success) {
              setQuestions(res.data.Questions);
              setUserName(res.data.Name);
              setExamType(res.data.typeExam);
              setExamSkill(res.data.ExamSkill);
              setVideoType(res.data.VideoType);
            } else {
              setMsg(res.data.msg);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const mystyle = {
    fontSize: "0.9rem",
    fontWeight: "500",
    fontFamily: "Poppins, sans-serif",
  };
  const videoWidth = {
    width: "auto",
  };

  const handleDwonloadZip = () => {
    var src =
      "https://d2c967qfslpzim.cloudfront.net/trainees/" +
      batchName +
      "/" +
      skillName +
      "/" +
      examCode +
      "/" +
      hostId +
      "/" +
      questionNumber +
      "/Source.zip";
    window.location.href = src;
  };

  const questionVideo =
    "https://d2c967qfslpzim.cloudfront.net/trainees/" +
    batchName +
    "/" +
    skillName +
    "/" +
    examCode +
    "/" +
    hostId +
    "/" +
    examType +
    "_" +
    questionNumber +
    videoType;
  const allQuestionVideo =
    "https://d2c967qfslpzim.cloudfront.net/trainees/" +
    batchName +
    "/" +
    skillName +
    "/" +
    examCode +
    "/" +
    hostId +
    "/" +
    examType +
    videoType;

  return (
    <div className="container-fluid">
      {msg === "" ? (
        <>
          <section className="ExamVideo-module">
            <div className="container-fluid">
              <div className="d-flex justify-content-between flex-wrap align-items-center">
                <h1 className="text-center h3 me-3 mb-0">{examSkill} Questions</h1>

                <p className="text-dark me-2 mt-2 h3">
                  {userName}
                </p>
              </div>
              <hr className="mt-4 mb-5" />
              <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-4">

                  <div class="list-group mb-4">
                    {questions &&
                      questions.length > 0 &&
                      questions.map((item, index) => {
                        return (
                          <a
                            className={`list-group-item cursor-pointer
                              ${item === questionNumber ? "bg-blue text-white" : ""}`
                            }
                            onClick={(e) => setQuestionNumber(item)}
                          >
                            Question {index + 1}
                          </a>
                        );
                      })
                    }
                  </div>
                </div>
                <div className="col-xl-10 col-lg-9 col-md-8">
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="tab" href="#qa-content"><i class="fas fa-laptop-code me-2"></i>Question</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#qa-video">Video</a>
                    </li>
                    <li class="nav-item">
                      <button className="btn btn-primary bg-blue" onClick={handleDwonloadZip}>
                        Source Code
                        <i class="fas fa-download mx-2"></i>
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content pt-3">
                    <div id="qa-content" class="tab-pane fade in active show">
                      {questionNumber !== "" && (
                        <>
                          <div class="embed-responsive embed-responsive-21by9">
                            <iframe
                              src={
                                "https://d2c967qfslpzim.cloudfront.net/trainees/" +
                                batchName +
                                "/" +
                                skillName +
                                "/" +
                                examCode +
                                "/" +
                                hostId +
                                "/" +
                                questionNumber +
                                "/" +
                                questionNumber +
                                ".html"
                              }
                              width="100%"
                              height="600"
                              title="Source Code"
                            ></iframe>
                          </div>
                        </>

                      )}
                    </div>
                    <div id="qa-video" class="tab-pane fade">
                      <div className="embed-responsive embed-responsive-16by9">
                        <video
                          id="video"
                          className="w-100"
                          src={questionNumber !== "" ? questionVideo : allQuestionVideo}
                          height="600"
                          controls
                        ></video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div class="text-danger text-center pt-3 fs-3">{msg}</div>
      )}
    </div>
  );
}
