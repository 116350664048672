import React from "react";
import ReactHlsPlayer from "react-hls-player";
import { useNavigate } from "react-router-dom";

function CloudVideom3u8() {
  const navigate = useNavigate();
  const back = () => {
    navigate.push("/course/HTML");
  };
  return (
    <>
      <div>
        <h4 style={{ marginLeft: "200px", marginTop: "100px" }}>
          Cloudinary M3u8
        </h4>
        <ReactHlsPlayer
          src="http://res.cloudinary.com/tutorialradix/video/upload/sp_sd/v1630564597/shekhar/myvideo1.m3u8"
          controls
          height="50%"
          width="50%"
          style={{ marginLeft: "200px", marginTop: "50px" }}
        />
      </div>
      <button
        className="btn btn-success"
        style={{ marginLeft: "200px" }}
        onClick={back}
      >
        Back
      </button>
    </>
  );
}

export default CloudVideom3u8;
