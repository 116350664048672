import React from "react";
import formloader from "../../images/formloading.svg";

export default function Loader({ loaderStatus = false, message = "Loading..." }) {

    return (
        <>
            <div className="formloader">
                <div className="row text-center">
                    <div className="col-12">
                        <img src={formloader} alt="" height="100" />
                    </div>
                    <div className="col-12 text-white h4">{message}</div>
                </div>
            </div>
        </>
    )
}


