import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { imgURL } from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import CkEditor from "./CkEditor";
// import { GrUpdate } from "react-icons/gr";
import { FaCheck, FaMinus, FaPen, FaTimes, FaTrash } from "react-icons/fa";
import ToolTip from "../BsComponents/ToolTip";
import { GrPowerReset } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

export default function UpdateTrainingPlan() {
  const navigate = useNavigate();
  const initialTrainingPlan = {
    cid: "",
    tp_day: "",

    tp_onlineref: "",
    tp_note: "",

    tp_practiceimgarr: [],
    tp_assignmentimgarr: [],
    tp_supportedfiles: [],
    formattedWhatToLearn: "",
    formattedNotePlan: "",
    formattedPractices: [],
    formattedAssignments: [],
  };
  const [upTplan, setUpTplan] = useState(initialTrainingPlan);
  const [prevTraningPlan, setPrevTrainingPlan] = useState(initialTrainingPlan);
  const [upTplanDay, setUpTplanDay] = useState({
    value: "",
    label: "Select Training Plan Day",
  });
  const [subCourseDropdownValue, setSubCourseDropDownValue] = useState({
    value: "",
    label: "Select Subcourse",
  });
  const [videos, setVideos] = useState([]);
  const [ppts, setPpts] = useState([]);
  const token = localStorage.getItem("token");
  const [tplans, setTplans] = useState([]);
  const [scnames, setSCnames] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [courseId, setCourseId] = useState("");
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState([]);
  const [videoupload, setupload] = useState("");
  const [pptupload, setpptupload] = useState("");
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);

  const initialUpdateState = {
    formattedWhatToLearn: false,
    formattedPractices: false,
    formattedAssignments: false,
    tp_onlineref: false,
    formattedNotePlan: false,
    videoUpload: false,
    pptUpload: false,
    supportedFilesUpload: false,
    tp_practiceimgarr: false,
    tp_assignmentimgarr: false,
  };
  const [update, setUpdate] = useState(initialUpdateState);
  const [allVideos, setAllVideos] = useState([]);
  const [planVideos, setPlanVideos] = useState([]);
  const [allPpts, setAllPpts] = useState([]);
  const [planPpts, setPlanPpts] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      jwt_decode(localStorage.getItem("token")).role === "Trainer"
    ) {
      LoginService.getSingleUser(
        jwt_decode(localStorage.getItem("token")).id,
        localStorage.getItem("token")
      )
        .then((res) => {
          res.data.courses.map((item) => {
            CourseService.getSinglesubcourse(item)
              .then((result) => {
                if (result.data && result.data.length > 0) {
                  setSCnames((d) => [...d, result.data[0]]);
                }
              })
              .catch((ex) => console.log(ex));
          });
        })
        .catch((ex) => console.log(ex));
    } else if (
      localStorage.getItem("token") &&
      jwt_decode(localStorage.getItem("token")).role === "Admin"
    ) {
      CourseService.getSubCourses()
        .then((res) => {
          if (res.data) {
            setSCnames(res.data);
          }
        })
        .catch((ex) => console.log(ex));
    }
  }, []);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const getCourseVideos = async (name) => {
    try {
      const courseVideos = await CourseService.getVideos(
        name,
        localStorage.getItem("token")
      );

      setAllVideos(courseVideos?.data);
      if (!!upTplan.tp_day && upTplan.tp_day === prevTraningPlan.tp_day) {
        const temp = courseVideos?.data.flatMap((video) =>
          video.videos.filter((videoItem) => videoItem.day === upTplan.tp_day)
        );
        setVideos([]);
        setPlanVideos(temp);
      }
    } catch (error) {
      console.log("error getting course videos", error);
    }
  };
  const getCoursePlanVideos = async (tpDay) => {
    try {
      const temp = allVideos.flatMap((video) =>
        video.videos.filter((videoItem) => videoItem.day === tpDay)
      );
      setPlanVideos(temp);
    } catch (error) {
      console.log("Error getting course plan videos:", error);
    }
  };

  const getCoursePpts = async (id, flag) => {
    try {
      const coursePpts = await CourseService.getCoursePpts(
        id,
        localStorage.getItem("token")
      );
      setAllPpts(coursePpts?.data);
      if (flag) {
        const allPpts = coursePpts.data;
        const temp = [];
        for (let i = 0; i < allPpts.length; i++) {
          for (let j = 0; j < allPpts[i].ppts.length; j++) {
            if (allPpts[i].ppts[j].day === upTplan.tp_day) {
              temp.push(allPpts[i].ppts[j]);
            }
          }
        }

        setPlanPpts(temp);
      }
    } catch (error) {
      console.log("error getting course ppts", error);
    }
  };

  const getCoursePlanPpts = (tpDay) => {
    const temp = [];
    for (let i = 0; i < allPpts.length; i++) {
      for (let j = 0; j < allPpts[i].ppts.length; j++) {
        if (allPpts[i].ppts[j].day === tpDay) {
          temp.push(allPpts[i].ppts[j]);
        }
      }
    }
    setPlanPpts(temp);
  };

  const handlecheck = async (e) => {
    if (e.target.checked === true) {
      setCourses((d) => [...d, e.target.value]);
    } else {
      var cor = [];
      if (courses.length !== 0) {
        cor = courses.filter((j) => j === e.target.value);
      }
      if (cor) {
        const course = courses.filter((j) => j !== e.target.value);
        setCourses(course);
      }
    }
  };

  const handlepptcheck = async (e) => {
    if (e.target.checked === true) {
      setCourse((d) => [...d, e.target.value]);
    } else {
      var cor = [];
      if (course.length !== 0) {
        cor = course.filter((j) => j === e.target.value);
      }
      if (cor) {
        const coursess = course.filter((j) => j !== e.target.value);
        setCourse(coursess);
      }
    }
  };

  const videouploaded = async () => {
    try {
      const findCourse = scnames.find((i) => i._id === upTplan.cid);
      if (findCourse) {
        CourseService.getVideos(findCourse.name, token)
          .then((res) => {
            if (res.data.length > 0) {
              const temp = [];
              for (let i = 0; i < res.data.length; i++) {
                for (let j = 0; j < res.data[i].videos.length; j++) {
                  if (res.data[i].videos[j]?.day === undefined) {
                    temp.push(res.data[i].videos[j]);
                  }
                }
              }
              setVideos(temp);
            }
          })
          .catch((ex) => console.log(ex));
      }
    } catch (error) {
      console.log("error getting videos", error);
    }
  };

  const pptuploaded = async () => {
    try {
      const findCourse = scnames.find((i) => i._id === upTplan.cid);
      if (findCourse) {
        CourseService.getPpts(findCourse.name, token)
          .then((res) => {
            if (res.data) {
              if (typeof res.data === "object") {
                const pptsWithDay = res.data.ppts.filter(
                  (ppt, index) => ppt.day === undefined
                );
                setPpts(pptsWithDay);
              } else {
                res.data.map((item, index) => {
                  res.data.ppts.map(async (ppt, index) => {
                    if (ppt.day === undefined) {
                      await setPpts((d) => [...d, ppt]);
                    }
                  });
                });
              }
            }
          })
          .catch((ex) => console.log(ex));
      }
    } catch (error) {
      console.log("error getting ppts", error);
    }
  };

  const handleCourseChange = async (scname) => {
    if (scname) {
      const res = await CourseService.getTrainingPlan(scname, token);
      if (res.data.length > 0) {
        setTplans(res.data);
      } else {
        setTplans([]);
      }
    } else {
      setTplans([]);
    }
  };

  const handleDelete = async () => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure, you want to delete this training plan?"
      );
      if (!confirmDelete) {
        return;
      }

      setLoading(true);
      if (!upTplan._id) {
        return;
      }

      const removeTrainingPlan = await CourseService.removeTplan(
        upTplan,
        token
      );
      if (removeTrainingPlan.data.msg) {
        setUpTplan(initialTrainingPlan);
        setUpTplanDay({ value: "", label: "Select Training Plan Day" });
        setSubCourseDropDownValue({
          value: "",
          label: "Select Subcourse",
        });

        setSuccessMsg("Training plan data deleted successfully");
        scrollToTop();
        const temp = [...tplans];
        const findTpDay = temp.findIndex((i) => i.tp_day === upTplan.tp_day);
        temp.splice(findTpDay, 1);
        setTplans(temp);
        setTimeout(() => {
          setSuccessMsg("");
        }, 2000);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMsg("Failed to delete training plan data.");
      scrollToTop();
      setTimeout(() => {
        setErrorMsg("");
      }, 2000);
      console.log("error deleting training plan", error);
    }
  };

  const handleUpdate = async (field) => {
    setPrevTrainingPlan((prev) => ({
      ...prev,
      [field]: upTplan[field],
    }));

    const formdata = new FormData();
    const {
      cid,
      tp_day,
      tp_practiceimgarr,
      tp_assignmentimgarr,
      tp_supportedfiles,
    } = upTplan;

    if (!token) {
      navigate("/login");
    } else {
      setValidate(true);
      if (cid && tp_day) {
        setLoading(true);
        // Uploading practiceImgArr to cloudinary
        if (tp_practiceimgarr.length > 0) {
          for (const item of tp_practiceimgarr) {
            formdata.append("tp_practiceimgarr", item);
          }
        }
        if (tp_assignmentimgarr.length > 0) {
          for (const item of tp_assignmentimgarr) {
            formdata.append("tp_assignmentimgarr", item);
          }
        }
        if (tp_supportedfiles.length > 0) {
          for (const item of tp_supportedfiles) {
            formdata.append("tp_supportedfiles", item);
          }
        }
        formdata.append("trainingplan", JSON.stringify(upTplan));
        // upTplan.tp_onlineref = upTplan.tp_onlineref
        //   .toString()
        //   .replace(/,/g, "")
        //   .split("\n");
        try {
          if (field === "videoUpload") {
            if (videoupload === "yes") {
              await Promise.all(
                courses.map(async (video) => {
                  const result = await CourseService.putCoursevideos(
                    video,
                    tp_day,
                    token
                  );
                  return result;
                })
              );
            }
            await videouploaded();
            await getCourseVideos(courseName);
            setupload("");
          }

          if (field === "pptUpload") {
            if (pptupload === "yes") {
              await Promise.all(
                course.map(async (ppt) => {
                  const result = await CourseService.putPpts(
                    ppt,
                    tp_day,
                    token
                  );
                  return result;
                })
              );

              setPpts([]);
              getCoursePpts(courseId, true);
              setpptupload("");
            }
          }

          upTplan.formattedPractices = upTplan.formattedPractices.filter(
            (i) => i !== null && i !== ""
          );
          upTplan.formattedAssignments = upTplan.formattedAssignments.filter(
            (i) => i !== null && i !== ""
          );
          const res = await CourseService.putTrainingPlan(
            upTplan,
            formdata,
            token
          );

          if (res.data) {
            setSuccessMsg(`Training plan data updated successfully.`);
            setUpTplan({ ...res.data?.Trainingplan });

            setUpdate({
              ...update,
              [field]: !update[field],
            });
            setLoading(false);
            setValidate(false);
            handleCourseChange(courseName);
            setTimeout(() => {
              setSuccessMsg("");
            }, 2000);
          }
        } catch (ex) {
          setErrorMsg("Failed to update training plan data.");
          scrollToTop();
          setUpdate({
            ...update,
            [field]: !update[field],
          });
          setLoading(false);
          setValidate(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 2000);
          console.log("error updating plan", ex);
        }
      }
    }
  };

  const discardChanges = (state) => {
    setUpTplan((prev) => ({ ...prev, [state]: prevTraningPlan[state] }));
    setUpdate({ ...update, [state]: !update[state] });
  };

  return (
    <div className="my-3 container">
      <div className="bg-white py-2 px-4">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">
                Updating Training plan...
              </div>
            </div>
          </div>
        )}

        <h5 className="text-uppercase text-center">Update Training Plan</h5>
        {!!successMsg && (
          <div class="alert alert-success p-3" role="alert">
            {successMsg}
          </div>
        )}
        {!!errorMsg && (
          <div class="alert alert-danger p-3" role="alert">
            {errorMsg}
          </div>
        )}
        <div className="row my-3">
          <div className="col col-5">
            <Select
              options={scnames.map((i) => {
                return { value: i.name, label: i.name, ...i };
              })}
              onChange={(e) => {
                setPrevTrainingPlan({});
                setCourses([]);
                setCourse([]);
                setVideos([]);
                setPpts([]);
                if (!e?.value) {
                  setUpTplan({});
                  setUpTplanDay({
                    value: "",
                    label: "Select Training Plan Day",
                  });
                  setSubCourseDropDownValue({
                    value: "",
                    label: "Select Subcourse",
                  });
                  setupload("");
                  setpptupload("");
                } else {
                  setCourseName(e.value);
                  setCourseId(e._id);
                  setUpdate(initialUpdateState);
                  const setInitialValues = { ...initialTrainingPlan };
                  delete setInitialValues.cid;
                  setUpTplan({ ...setInitialValues });
                  setUpTplanDay({
                    value: "",
                    label: "Select Training Plan Day",
                  });
                  handleCourseChange(e.value);
                  setSubCourseDropDownValue({ value: e.value, label: e.value });

                  getCourseVideos(e?.name);
                  getCoursePpts(e?._id);
                }
              }}
              isClearable={true}
              value={subCourseDropdownValue}
            />
          </div>
          <div className="col col-5">
            <Select
              options={tplans.map((i) => {
                return { value: i.tp_day, label: i.tp_day, ...i };
              })}
              onChange={(e) => {
                setCourses([]);
                setCourse([]);
                setVideos([]);
                setPpts([]);
                if (e?.value) {
                  const cplan = tplans.find(
                    (i) => i.tp_day === Number(e.value)
                  );
                  setUpTplanDay({ value: cplan.tp_day, label: cplan.tp_day });
                  if (cplan) {
                    setUpTplan(cplan);
                    setPrevTrainingPlan(cplan);
                  }
                  getCoursePlanVideos(e.value);
                  getCoursePlanPpts(e.value);
                } else {
                  setUpTplan({});
                  setPrevTrainingPlan({});
                  setUpTplanDay({
                    value: "",
                    label: "Select Training Plan Day",
                  });
                }
                setUpdate(initialUpdateState);
                setupload("");
                setpptupload("");
              }}
              isClearable={true}
              value={upTplanDay}
              isDisabled={!!subCourseDropdownValue.value ? false : true}
            />
          </div>
          <div className="col col-2">
            <div className="d-flex">
              <div>
                <ToolTip title="Delete training plan">
                  <button
                    className="btn btn-danger "
                    type="button"
                    disabled={
                      subCourseDropdownValue.value && upTplanDay?.value
                        ? false
                        : true
                    }
                    onClick={handleDelete}
                  >
                    <FaTrash />
                  </button>
                </ToolTip>
              </div>
              <div>
                <ToolTip title="Reset Training Plan">
                  <button
                    className="btn btn-secondary text-light  ms-1"
                    onClick={() => {
                      setValidate(false);
                      setUpTplan(initialTrainingPlan);
                      setPrevTrainingPlan(initialTrainingPlan);
                      setUpTplanDay({
                        value: "",
                        label: "Select Training Plan Day",
                      });
                      setSubCourseDropDownValue({
                        value: "",
                        label: "Select Subcourse",
                      });
                    }}
                  >
                    <GrPowerReset />
                  </button>
                </ToolTip>
              </div>
            </div>
          </div>
        </div>

        {courseName && upTplan.tp_day ? (
          <div className="form px-lg-5">
            <div className="">
              <div className="d-flex">
                <div className="me-4">
                  <p className="fw-bold">What to Learn</p>
                </div>
                <div className="">
                  {update.formattedWhatToLearn ? (
                    <div className="d-flex">
                      <div>
                        <ToolTip title={"Save changes"}>
                          <button
                            type="submit"
                            className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                            onClick={() => {
                              handleUpdate("formattedWhatToLearn");
                            }}
                          >
                            <FaCheck />
                          </button>
                        </ToolTip>
                      </div>
                      <div>
                        <ToolTip title={"Discard changes"}>
                          <button
                            type="button"
                            className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                            onClick={() => {
                              discardChanges("formattedWhatToLearn");
                            }}
                          >
                            <FaTimes />
                          </button>
                        </ToolTip>
                      </div>
                    </div>
                  ) : (
                    <ToolTip title={"Edit"}>
                      <button
                        type="submit"
                        className={`btn btn-submit me-2 bg-warning text-white btn-sm`}
                        onClick={() =>
                          setUpdate({
                            ...update,
                            formattedWhatToLearn: !update.formattedWhatToLearn,
                          })
                        }
                      >
                        <FaPen />
                      </button>
                    </ToolTip>
                  )}
                </div>
              </div>
              {update.formattedWhatToLearn ? (
                <div className="mb-3">
                  <CkEditor
                    data={upTplan.formattedWhatToLearn}
                    onChange={(e, editor) => {
                      const data = editor.getData();
                      setUpTplan((d) => ({
                        ...d,
                        formattedWhatToLearn: data,
                      }));
                    }}
                  />
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: upTplan.formattedWhatToLearn,
                  }}
                ></div>
              )}
            </div>

            <hr />

            <div>
              <div className="d-flex my-2">
                <div className="fw-bold me-4">
                  <p>Practice Exercise</p>
                </div>
                <div className="">
                  {update.formattedPractices ? (
                    <div className="d-flex">
                      <div>
                        <ToolTip title={"Save Changes"}>
                          <button
                            type="submit"
                            className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                            onClick={() => {
                              handleUpdate("formattedPractices");
                            }}
                          >
                            <FaCheck />
                          </button>
                        </ToolTip>
                      </div>
                      <div>
                        <ToolTip title={"Discard changes"}>
                          <button
                            type="button"
                            className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                            onClick={() => {
                              discardChanges("formattedPractices");
                            }}
                          >
                            <FaTimes />
                          </button>
                        </ToolTip>
                      </div>
                    </div>
                  ) : (
                    <ToolTip title={"Edit"}>
                      <button
                        type="button"
                        className={`btn btn-submit me-2 bg-warning text-white btn-sm`}
                        onClick={() =>
                          setUpdate({
                            ...update,
                            formattedPractices: !update.formattedPractices,
                          })
                        }
                      >
                        <FaPen />
                      </button>
                    </ToolTip>
                  )}
                </div>
              </div>
              {update.formattedPractices ? (
                <div className="mb-3">
                  {upTplan.formattedPractices &&
                    upTplan.formattedPractices.length > 0 &&
                    upTplan.formattedPractices.map((item, index) => {
                      return (
                        <div className="row mt-2" key={index}>
                          <div className="col-md-1">
                            <p>{index + 1}</p>
                          </div>
                          <div className="col-md-10">
                            <CkEditor
                              data={item}
                              onChange={(e, editor) => {
                                const data = editor.getData();
                                // const data = e.target.value;
                                const temp = [...upTplan.formattedPractices];
                                temp[index] = data;
                                setUpTplan({
                                  ...upTplan,
                                  formattedPractices: temp,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-1 my-auto">
                            <ToolTip title="Remove">
                              <button
                                className="btn my-2 btn-danger btn-sm"
                                onClick={(e) => {
                                  upTplan.formattedPractices.splice(index, 1);
                                  setUpTplan({ ...upTplan });
                                }}
                                type="button"
                              >
                                <FaMinus />
                              </button>
                            </ToolTip>
                          </div>
                        </div>
                      );
                    })}
                  <button
                    type="button"
                    className="btn btn-primary btn-sm mt-2"
                    onClick={() => {
                      const temp = [...upTplan.formattedPractices];
                      temp.push("");
                      setUpTplan({
                        ...upTplan,
                        formattedPractices: temp,
                      });
                    }}
                  >
                    {upTplan.formattedPractices &&
                    upTplan.formattedPractices.length < 1
                      ? "Add"
                      : "Add More"}
                  </button>
                </div>
              ) : (
                <>
                  {upTplan.formattedPractices &&
                    upTplan.formattedPractices.length > 0 &&
                    upTplan.formattedPractices.map((item, index) => (
                      <div className="row mt-2" key={index}>
                        <div className="col-md-1">{index + 1}</div>
                        <div className="col-md-11">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item,
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>

            <hr />

            <div className="my-2">
              <div className="d-flex">
                <div className="fw-bold me-4">
                  <p>Assignment Exercise</p>
                </div>
                <div className="">
                  {update.formattedAssignments ? (
                    <div className="d-flex">
                      <div>
                        <ToolTip title={"Save changes"}>
                          <button
                            type="submit"
                            className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                            onClick={() => {
                              handleUpdate("formattedAssignments");
                            }}
                          >
                            <FaCheck />
                          </button>
                        </ToolTip>
                      </div>
                      <div>
                        <ToolTip title={"Discard changes"}>
                          <button
                            type="submit"
                            className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                            onClick={() =>
                              discardChanges("formattedAssignments")
                            }
                          >
                            <FaTimes />
                          </button>
                        </ToolTip>
                      </div>
                    </div>
                  ) : (
                    <ToolTip title={"Edit"}>
                      <button
                        type="submit"
                        className={`btn btn-submit me-2 bg-warning text-white btn-sm`}
                        onClick={() =>
                          setUpdate({
                            ...update,
                            formattedAssignments: !update.formattedAssignments,
                          })
                        }
                      >
                        <FaPen />
                      </button>
                    </ToolTip>
                  )}
                </div>
              </div>
              {update.formattedAssignments ? (
                <div className="mb-3">
                  {upTplan.formattedAssignments &&
                    upTplan.formattedAssignments.length > 0 &&
                    upTplan.formattedAssignments.map((item, index) => {
                      return (
                        <div className="row mt-2" key={index}>
                          <div className="col-md-1">
                            <p>{index + 1}</p>
                          </div>
                          <div className="col-md-10">
                            <CkEditor
                              data={item}
                              onChange={(e, editor) => {
                                const data = editor.getData();
                                // const data = e.target.value;
                                const temp = [...upTplan.formattedAssignments];
                                temp[index] = data;
                                setUpTplan({
                                  ...upTplan,
                                  formattedAssignments: temp,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-1 my-auto">
                            <ToolTip title="Remove">
                              <button
                                className="btn my-2 btn-danger btn-sm"
                                onClick={(e) => {
                                  const temp = [
                                    ...upTplan.formattedAssignments,
                                  ];
                                  temp.splice(index, 1);
                                  setUpTplan({
                                    ...upTplan,
                                    formattedAssignments: temp,
                                  });
                                }}
                                type="button"
                              >
                                <FaMinus />
                              </button>
                            </ToolTip>
                          </div>
                        </div>
                      );
                    })}
                  <button
                    type="button"
                    className="btn btn-primary btn-sm mt-2"
                    onClick={() => {
                      const temp = [...upTplan.formattedAssignments];
                      temp.push("");
                      setUpTplan({
                        ...upTplan,
                        formattedAssignments: temp,
                      });
                    }}
                  >
                    {" "}
                    {upTplan.formattedAssignments &&
                    upTplan.formattedAssignments.length < 1
                      ? "Add"
                      : "Add More"}
                  </button>
                </div>
              ) : (
                <>
                  {upTplan.formattedAssignments &&
                    upTplan.formattedAssignments.length > 0 &&
                    upTplan.formattedAssignments.map((item, index) => (
                      <div className="row mt-2" key={index}>
                        <div className="col-md-1">{index + 1}</div>
                        <div className="col-md-11">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item,
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>

            <hr />

            <div className="d-flex my-2">
              <div className="d-flex">
                <div className="fw-bold me-4">
                  <p>Online References</p>
                </div>
              </div>
              <div className="">
                {update.tp_onlineref ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="submit"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            handleUpdate("tp_onlineref");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => discardChanges("tp_onlineref")}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <ToolTip title={"Edit"}>
                    <button
                      type="submit"
                      className={`btn btn-submit me-2 bg-warning text-white btn-sm`}
                      onClick={() =>
                        setUpdate({
                          ...update,
                          tp_onlineref: !update.tp_onlineref,
                        })
                      }
                    >
                      <FaPen />
                    </button>
                  </ToolTip>
                )}
              </div>
              <div>
                {update.tp_onlineref && (
                  <small className="">
                    (Please add comma(,) between two link)
                  </small>
                )}
              </div>
            </div>

            {update.tp_onlineref ? (
              <div className="mb-3">
                <textarea
                  id="tp_onlineref"
                  rows="10"
                  className="form-control"
                  name="tp_onlineref"
                  value={upTplan.tp_onlineref[0]}
                  onChange={(e) => {
                    setUpTplan((d) => ({
                      ...d,
                      tp_onlineref: [e.target.value],
                    }));
                  }}
                ></textarea>
              </div>
            ) : (
              <>
                {upTplan.tp_onlineref &&
                  upTplan.tp_onlineref.length > 0 &&
                  upTplan.tp_onlineref.map((item, index) => {
                    return (
                      <div className="row mt-2" key={index}>
                        {!!item &&
                          item
                            .split(",")
                            .map((onlineChildItem, onlineChildIndex) => {
                              return (
                                <>
                                  <div className="col-md-1">
                                    {index + onlineChildIndex + 1}
                                  </div>
                                  <div className="col-md-11">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: onlineChildItem,
                                      }}
                                      className="text-break"
                                    ></div>
                                  </div>
                                </>
                              );
                            })}
                      </div>
                    );
                  })}
              </>
            )}

            <hr />

            <div className="d-flex my-2">
              <div className="d-flex gap-4">
                <div className="fw-bold me-4">
                  <p>Video uploaded or not ?</p>
                </div>
                <div className="">
                  <input
                    type="radio"
                    name="upload"
                    id="yes"
                    onChange={(e) => {
                      setupload("yes");
                      videouploaded();
                      setUpdate({
                        ...update,
                        videoUpload: true,
                      });
                    }}
                    checked={videoupload === "yes" ? true : false}
                  />
                  <label
                    htmlFor="yes"
                    style={{ marginLeft: "2px", marginRight: "10px" }}
                  >
                    yes
                  </label>
                  <input
                    type="radio"
                    name="upload"
                    id="no"
                    onChange={(e) => {
                      setupload("no");
                      setVideos([]);
                      setUpdate({ ...update, videoUpload: true });
                    }}
                    checked={videoupload === "no" ? true : false}
                  />
                  <label htmlFor="no" style={{ marginLeft: "2px" }}>
                    no
                  </label>
                </div>
                {update.videoUpload && videos.length ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="submit"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            setUpdate({
                              ...update,
                              videoUpload: false,
                            });
                            handleUpdate("videoUpload");
                            setupload("");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => {
                            discardChanges("videoUpload");
                            setupload("");
                          }}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className=""></div>
            </div>

            <div className="row">
              <div className="col col-6">
                {planVideos.length > 0 ? (
                  <div>
                    {planVideos.map((item, index) => {
                      return (
                        <>
                          <ul className="" key={index}>
                            <li htmlFor={item.filename} className="">
                              {item.filename.split("-").slice(1, 5).join(" ")}{" "}
                            </li>
                          </ul>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <p>No videos set in this training plan</p>
                )}
              </div>
              <div className="col col-6">
                <div className={videoupload === "yes" ? "mb-3" : "d-none"}>
                  <>
                    {videos.length > 0 ? (
                      <>
                        {videos.map((item, index) => {
                          return (
                            <>
                              {(item.day === 0 || !item.day) && (
                                <div className="col form-check" key={index}>
                                  <input
                                    type="checkbox"
                                    id={item.filename}
                                    placeholder="Password"
                                    className="form-check-input"
                                    value={item.filename}
                                    onChange={(e) => {
                                      return handlecheck(e);
                                    }}
                                  />
                                  <label
                                    htmlFor={item.filename}
                                    className="form-check-label color-dback"
                                  >
                                    {item.filename}
                                  </label>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <p>No videos in this course or all videos are set</p>
                    )}
                  </>
                </div>
              </div>
            </div>

            <hr />

            <div className="d-flex">
              <div className="d-flex gap-4">
                <div className="fw-bold me-4">
                  <p>Ppt uploaded or not ?</p>
                </div>
                <div className="mb-3">
                  <input
                    type="radio"
                    name="pptupload"
                    id="pptUploadYes"
                    onChange={(e) => {
                      setpptupload("yes");
                      pptuploaded();
                      setUpdate({
                        ...update,
                        pptUpload: true,
                      });
                    }}
                    checked={pptupload === "yes" ? true : false}
                  />
                  <label
                    htmlFor="yes"
                    style={{ marginLeft: "2px", marginRight: "10px" }}
                  >
                    yes
                  </label>
                  <input
                    type="radio"
                    name="pptupload"
                    id="pptUploadNo"
                    onChange={(e) => {
                      setpptupload("no");
                      setUpdate({
                        ...update,
                        pptUpload: true,
                      });
                      setPpts([]);
                    }}
                    checked={pptupload === "no" ? true : false}
                  />
                  <label htmlFor="no" style={{ marginLeft: "2px" }}>
                    no
                  </label>
                </div>
                {update.pptUpload && ppts.length ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="submit"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            setUpdate({
                              ...update,
                              pptUpload: false,
                            });
                            handleUpdate("pptUpload");
                            setpptupload("");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => {
                            discardChanges("pptUpload");
                            setpptupload("");
                          }}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col col-6">
                {!!planPpts && planPpts.length > 0 ? (
                  <div>
                    {planPpts.map((item, index) => {
                      return (
                        <ul className="" key={index}>
                          <li htmlFor={item.filename} className="">
                            {item.filename.split("/")[7]}
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                ) : (
                  <p>No ppts set in this training plan</p>
                )}
              </div>
              <div className="col col-6">
                <div className={pptupload === "yes" ? "mb-3" : "d-none"}>
                  <>
                    {ppts.length > 0 ? (
                      <>
                        {ppts.map((item, index) => {
                          return (
                            <>
                              {(item.day === 0 || !item.day) && (
                                <div className="col form-check" key={index}>
                                  <input
                                    type="checkbox"
                                    id={item.filename}
                                    placeholder="Password"
                                    className="form-check-input"
                                    value={item.filename}
                                    onChange={(e) => {
                                      return handlepptcheck(e);
                                    }}
                                  />
                                  <label
                                    htmlFor={item.filename}
                                    className="form-check-label color-dback"
                                  >
                                    {item.filename.split("/")[7]}
                                  </label>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <p>No ppts in this course or all videos are set</p>
                    )}
                  </>
                </div>
              </div>
            </div>

            <hr />

            <div className="my-2">
              <div className="">
                <div className="fw-bold me-4">
                  <p>Supported Files</p>
                </div>
              </div>
              <div className="">
                {update.supportedFilesUpload ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="submit"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            handleUpdate("supportedFilesUpload");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => {
                            discardChanges("supportedFilesUpload");
                          }}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex">
                    {upTplan?.tp_supportedfiles?.length > 0 &&
                      typeof upTplan.tp_supportedfiles[0] === "string" &&
                      upTplan.tp_supportedfiles.map((item, onindex) => {
                        const splitItems = item.split("-");
                        const imgText = splitItems
                          .slice(1, splitItems.length)
                          .join("-");
                        return (
                          <div className="me-2" key={onindex}>
                            <button
                              onClick={() =>
                                window.open(`${imgURL}/${item}`, "_blank")
                              }
                              className="btn m-0 p-0"
                              data-toggle="tooltip"
                              data-placement="right"
                              title={imgText}
                              type="button"
                            >
                              <img
                                src={`${imgURL}/${item}`}
                                width="100"
                                height="100"
                                className="border"
                                alt={item}
                              ></img>
                            </button>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <input
                type="file"
                id="tp_supportedfiles"
                className="form-control"
                name="tp_supportedfiles"
                multiple
                onChange={(e) => {
                  setUpTplan((d) => ({
                    ...d,
                    tp_supportedfiles: e.target.files,
                  }));
                  setUpdate({
                    ...update,
                    supportedFilesUpload: true,
                  });
                }}
              />
            </div>

            <hr />

            <div className="my-2">
              <div className="">
                <div className="fw-bold me-4">
                  <p>Practice Reference Images</p>
                </div>
              </div>
              <div className="">
                {update.tp_practiceimgarr ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="submit"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            // setUpdate({
                            //   ...update,
                            //   tp_practiceimgarr: false,
                            // });
                            handleUpdate("tp_practiceimgarr");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => {
                            discardChanges("tp_practiceimgarr");
                          }}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <div>
                    {upTplan?.tp_practiceimgarr?.length > 0 &&
                      typeof upTplan.tp_practiceimgarr[0] === "string" && (
                        <div className="d-flex">
                          {upTplan?.tp_practiceimgarr?.map((item, pindex) => {
                            const splitItems = item.split("-");
                            const imgText = splitItems
                              .slice(1, splitItems.length)
                              .join("-");
                            return (
                              <div className="me-2" key={pindex}>
                                <button
                                  onClick={() =>
                                    window.open(`${imgURL}/${item}`, "_blank")
                                  }
                                  className="btn m-0 p-0"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title={imgText}
                                  type="button"
                                >
                                  <img
                                    src={`${imgURL}/${item}`}
                                    width="100"
                                    height="100"
                                    className="border"
                                    alt={item}
                                  ></img>
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <input
                type="file"
                id="tp_practiceimgarr"
                className="form-control"
                name="tp_practiceimgarr"
                accept="image/*"
                multiple
                onChange={(e) => {
                  setUpTplan((d) => ({
                    ...d,
                    tp_practiceimgarr: e.target.files,
                  }));
                  setUpdate({
                    ...update,
                    tp_practiceimgarr: true,
                  });
                }}
              />
            </div>

            <hr />

            <div className="my-2">
              <div className="">
                <div className="fw-bold me-4">
                  <p>Assignment Reference Images</p>
                </div>
              </div>
              <div className="">
                {update.tp_assignmentimgarr ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="submit"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            // setUpdate({
                            //   ...update,
                            //   tp_assignmentimgarr: false,
                            // });
                            handleUpdate("tp_assignmentimgarr");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => {
                            discardChanges("tp_assignmentimgarr");
                          }}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <div className="">
                    {upTplan?.tp_assignmentimgarr?.length > 0 &&
                      typeof upTplan.tp_assignmentimgarr[0] === "string" && (
                        <div className="d-flex">
                          {upTplan?.tp_assignmentimgarr?.map((item, pindex) => {
                            const splitItems = item.split("-");
                            const imgText = splitItems
                              .slice(1, splitItems.length)
                              .join("-");
                            return (
                              <div className="me-2" key={pindex}>
                                <button
                                  onClick={() =>
                                    window.open(`${imgURL}/${item}`, "_blank")
                                  }
                                  className="btn m-0 p-0"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title={imgText}
                                  type="button"
                                >
                                  <img
                                    src={`${imgURL}/${item}`}
                                    width="100"
                                    height="100"
                                    className="border"
                                    alt={item}
                                  ></img>
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <input
                type="file"
                accept="image/*"
                id="tp_assignmentimgarr"
                className="form-control"
                name="tp_assignmentimgarr"
                multiple
                onChange={(e) => {
                  setUpTplan((d) => ({
                    ...d,
                    tp_assignmentimgarr: e.target.files,
                  }));
                  setUpdate({
                    ...update,
                    tp_assignmentimgarr: true,
                  });
                }}
              />
            </div>

            <hr />

            <div className="d-flex my-2">
              <div className="me-4">
                <p className="fw-bold">Note</p>
              </div>
              <div className="">
                {update.formattedNotePlan ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="submit"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            handleUpdate("formattedNotePlan");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => discardChanges("formattedNotePlan")}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <ToolTip title={"Edit"}>
                    <button
                      type="submit"
                      className={`btn btn-submit me-2 bg-warning text-white btn-sm`}
                      onClick={() =>
                        setUpdate({
                          ...update,
                          formattedNotePlan: !update.formattedNotePlan,
                        })
                      }
                    >
                      <FaPen />
                    </button>
                  </ToolTip>
                )}
              </div>
            </div>
            {update.formattedNotePlan ? (
              <div className="mb-3">
                <CkEditor
                  data={upTplan.formattedNotePlan}
                  onChange={(e, editor) => {
                    const data = editor.getData();
                    setUpTplan((d) => ({
                      ...d,
                      formattedNotePlan: data,
                    }));
                  }}
                />
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: upTplan.formattedNotePlan,
                }}
              ></div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
