import React, { useState, useEffect } from 'react';
import CourseListService from '../../services/Course-List-Service';
import { Link } from 'react-router-dom';

const CourseList = () => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        fetchCourses();
    }, []); // Fetch courses when the component mounts

    const fetchCourses = async () => {
        try {
            CourseListService.getAllCourse().then(res => {
                setCourses(res.data)
            }).catch(err => console.log(err))

        } catch (error) {
            console.error('Error fetching courses:', error.message);
        }
    };



    const deleteCourse = async (id) => {
        CourseListService.deleteCourseByID(id).then(res => {
            if (res.status === 200) {
                alert("Data deleted successfully");
                fetchCourses()
            } else {
                alert("something wen wrong")
            }
        }).catch(error => alert(error))
    };


    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center">
                <h2>Course Details</h2>
                <Link to="/admin/create-course">
                    <button className="btn btn-success">Add Course</button>
                </Link>

            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Course Name</th>
                        <th>Category</th>
                        <th>Level</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {courses.map((course) => (
                        <tr key={course.id}>
                            <td>{course.course_name}</td>
                            <td>{course.category}</td>
                            <td>{course.level}</td>
                            <td>{course.description}</td>
                            <td>
                                <div className="d-flex">
                                    <Link to={`/admin/coursedetails?id=${course.id}`}>
                                        <button
                                            data-toggle="tooltip" data-placement="top" title='view' className="btn me-1"
                                        ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                            </svg>
                                        </button>
                                    </Link>
                                    <Link to={`/admin/update-course/${course.id}`}>
                                        <button className="btn btn-primary me-1">
                                            Edit
                                        </button>
                                    </Link>
                                    <button className="btn btn-danger ms-1" onClick={() => deleteCourse(course.id)}>
                                        Delete
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CourseList;
