import axios from "axios";
import variable from "../servicesvariable";

class BatchService {
    getBatch() {
        return axios.get(variable.Tutorial_API_GetBatch)
    }

    getBatchById(id) {
        return axios.get(variable.Tutorial_API_GetBatch + id)
    }

    addBatch(Batchdata) {
        return axios.post(variable.Tutorial_Api_AddBatch,
            Batchdata
        )
    }

    editBatch(id, updatedData) {
        return axios.put(variable.Tutorial_Api_EditBatch + id,
            updatedData)
    }

    deleteBatch(id) {
        return axios.delete(variable.Tutorial_Api_deleteBatch + id)
    }
}

export default new BatchService();