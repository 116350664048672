import React, { useState, useEffect } from "react";
import "./Resources.scss";
import $ from "jquery";

export default function Resources(props) {
  const [sidelg, setSideLG] = useState(true);
  const [sidesm, setSideSM] = useState(false);

  useEffect(() => {
    $(window).scrollTop(0);
  }, [window.location.href]);

  $(document).ready(function () {
    $("a.scrollLink").click(function () {
      setTimeout(function () {
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          0.0001
        );
      }, 0);
    });
    var headerHeight = $("header").height();
    var bannerHeight = $(".tutorial-banner").height();
    var footerHeight = $("footer").height();
    var totalHeight = headerHeight + bannerHeight + footerHeight;
    if ($(window).width() > 1025) {
      $(".sidebar").css("height", "calc(99vh - " + totalHeight + "px - 90px");
      $(".video-tutorial").css(
        "height",
        "calc(99vh - " + totalHeight + "px - 50px"
      );
      sidebarlg();
      setSideSM(false);
    } else {
      sidebarsm();
      setSideLG(false);
    }
    $(window).scroll(function (e) {
      topbarSticky();
      sideBarSticky();
    });
    $(window).resize(function (e) {
      if ($(window).width() > 1025) {
        $(".sidebar").css("height", "calc(99vh - " + totalHeight + "px - 90px");
        $(".video-tutorial").css(
          "height",
          "calc(99vh - " + totalHeight + "px - 50px"
        );
        $(".sidebar-wrapper").css({
          top: $("header").height() + $(".tutorial-banner").height() + 20,
        });
        $(".sidebar-wrapper").css(
          "height",
          "calc(99vh - " + totalHeight + "px - 90px"
        );

        sidebarlg();
        setSideSM(false);
      } else {
        sidebarsm();
        setSideLG(false);
      }
      $(window).scroll(function (e) {
        topbarSticky();
        sideBarSticky();
      });
    });
    $(".sidebar-wrapper").css({
      top: $("header").height() + $(".tutorial-banner").height() + 50,
    });
  });

  const hidebar = () => {
    $(document).ready(function () {
      if ($(window).width() < 1025) {
        setSideSM(false);
        $(".sidebar-wrapper").addClass("collapse-sidebar");
        $("body").removeClass("overflow-hidden");
        $(".sidebar-bg").addClass("d-none");
      }
    });
  };
  function sideBarSticky() {
    var headerHeight = $("header").height();
    var bannerHeight = $(".tutorial-banner").height();
    var footerHeight = $("footer").height();
    var totalHeight = headerHeight + bannerHeight + footerHeight;

    if ($(window).scrollTop() > 0) {
      $(".sidebar-wrapper").css({
        top: $("header").height() + $(".tutorial-banner").height() + 50,
      });
    } else {
      $(".sidebar-wrapper").css({
        top: $("header").height() + $(".tutorial-banner").height() + 50,
      });
    }
  }
  function topbarSticky() {
    var $el = $(".tutorial-banner");
    if ($(window).scrollTop() > 50) {
      $("header").css({ height: "0px", overflow: "hidden" });
      $el.css({ top: "0px" });
      $el.addClass("position-sticky");
    } else {
      $el.removeClass("position-sticky");
      $("header").css({ height: "", overflow: "" });
    }
  }
  function sidebarsm() {
    $(".smsidebar").css("height", "99vh");
    $(".sidebar-wrapper").addClass("collapse-sidebar");
    $(".sidebar-wrapper").removeClass("col-lg-3 col-12  pt-4");
    $(".toggle-sidebar").click(function (e) {
      setSideSM(true);
      $(".sidebar-wrapper").removeClass("collapse-sidebar");
      $("body").addClass("overflow-hidden");
      $(".sidebar-bg").removeClass("d-none");
    });
    $(".close-sidebar").click(function (e) {
      setSideSM(false);
      $(".sidebar-wrapper").addClass("collapse-sidebar");
      $("body").removeClass("overflow-hidden");
      $(".sidebar-bg").addClass("d-none");
    });
  }
  function sidebarlg() {
    $(".collapse-btn").click(function () {
      setSideLG(false);
      $(".sidebar-wrapper").addClass("collapse-sidebar");
      $(".sidebar-wrapper").removeClass("col-lg-3 col-12  pt-4");
      $(".tab-contents").addClass("mx-auto");
    });
    $(".slider-right-btn").click(function () {
      setSideLG(true);
      $(".sidebar-wrapper").removeClass("collapse-sidebar");
      $(".sidebar-wrapper").addClass("col-lg-3 col-12  pt-4");
      $(".tab-contents").removeClass("mx-auto");
    });
  }
  const getPPtLink = (filename) => {
    return `https://res.cloudinary.com/change/raw/upload/ppt/${filename}`
  }

  return (
    <>
      <div class="col-12 col-xl-3 col-lg-4 sidebar-wrapper">
        <div class="sidebar px-4 pb-4 sidebar-module">
          {sidelg == true && (
            <div class="accordion-content course-detail-accordion ">
              <p className="">Additional Resources</p>
            </div>
          )}
        </div>

        {sidesm == true && (
          <div class="col-12 col-xl-3 col-lg-4 sidebar-wrapper">
            <div class="accordion-content course-detail-accordion">
              Additional Resources
            </div>
          </div>
        )}
      </div>

      <div className="col-12 col-xl-9 col-lg-8 mb-lg-4 tab-contents sidebar-course-content pt-1">
        <section class="text-dark-gray px-lg-0 px-xl-4 pt-4 pb-0">
          <div class="resource-wrapper p-0">
            <div class="row">
              {props.ppts.map((item, index) => (
                <div class="col-12 col-md-6 col-xl-3 res-item">
                  <a
                    class="d-block text-decoration-none"
                    href={`https://view.officeapps.live.com/op/embed.aspx?src=${getPPtLink(item.filename)}`}
                    target="blank"
                  >
                    <div class="res-item-inner">
                      <div class="res-img">
                        <img
                          src={require("../../assets/images/ppt.png").default}
                          alt="presentation"
                          width="80"
                          height="80"
                        />
                      </div>
                      <div class="res-header d-flex justify-content-between align-items-center px-4 py-2">
                        <h5 class="text-dark m-0">Lesson {index + 1}</h5>
                        <div>
                          <img
                            src={
                              require("../../assets/images/play_video_player_icon.svg")
                                .default
                            }
                            alt="presentation"
                            width="22"
                            height="16"
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
