import axios from "axios";
import variable from "../servicesvariable";

class CourseListService {
    getAllCourse() {
        return axios.get(variable.Tutorial_Api_Course)
    }
    getCourseById(id) {
        console.log(variable.Tutorial_Api_Course + id);
        return axios.get(variable.Tutorial_Api_Course + id)
    }
    addnewCourse(data) {
        return axios.post(variable.TutorialApi_AddNewCourse,
            data)
    }
    updateCourseByID(id, data) {
        return axios.put(variable.Tutorial_Api_Course + id,
            data)
    }
    deleteCourseByID(id) {
        return axios.delete(variable.Tutorial_Api_Course + id)
    }
}

export default new CourseListService();