import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import CourseListService from '../../services/Course-List-Service';

const AllCourseDetails = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    let id = queryParams.get('id')
    const [formData, setFormData] = useState([{ name: '', level: '', description: '' }]);

    useEffect(() => {
        if (id) {
            CourseListService.getCourseById(id).then(res => {
                setFormData(res.data)
            }).catch(err => {
                setFormData([])
            })
        }
    }, [])
    return (
        <div className='container'>
            <div className="d-flex justify-content-between align-items-center">
                <h2>Course Details</h2>
                <Link to="/admin/course-list">
                    <button className="btn btn-success">View Course List</button>
                </Link>
            </div>
            <div className='row'>
                {formData && formData.length > 0 ? formData.map(formData => (<div className="card mt-5 mx-auto" style={{ width: '36rem' }}>
                    <div className="card-body">
                        <h4> Coursename:</h4>  <h6 className="card-title" dangerouslySetInnerHTML={{ __html: formData.course_name }} /><hr />
                        <h4>Category:</h4> <p className="card-text" dangerouslySetInnerHTML={{ __html: formData.category }} /><hr />
                        <h4>level:</h4> <p className="card-text" dangerouslySetInnerHTML={{ __html: formData.level }} /><hr />
                        <h4>Description:</h4> <p className="card-text" dangerouslySetInnerHTML={{ __html: formData.description }} /><hr />
                    </div>
                </div>)) : (
                    <h5 dangerouslySetInnerHTML={{ __html: `No data found ${id}` }} />
                )}

            </div>
        </div>
    );
};

export default AllCourseDetails;