import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import CourseService from "../../services/CourseService";
import { FaPen, FaTimes } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import AddPracticalXslx from "./AddPracticalXslx";
import { useNavigate } from "react-router-dom";
import Loader from '../../components/loader/Loader';
import Pagination from "react-js-pagination";


export default function ManagePracticalQuestionList(props) {
    const navigate = useNavigate();
    const [examTypes, setExamTypes] = useState([
    ]);
    const [activeQuestion, setActiveQuestion] = useState([
    ]);
    const [difficultyList, setDifficultyList] = useState([
    ]);
    const [examQuestion, setExamQuestion] = useState([]);
    const [readMore, setReadMore] = useState([]);
    const [subcourseOptions, setSubcoursesOptions] = useState([]);
    const [confirmBox, setConfirmBox] = useState(false);
    const [message, setMessage] = useState({ status: false, msg: "" });
    const [openModal, setOpenModal] = useState(false);
    const [questionId, setQuestionId] = useState("");
    const [updateData, setUpdateData] = useState({});
    const [updated, setUpdated] = useState(false);
    const [filterQuestions, setFilterQuestions] = useState({});
    const [loader, setLoader] = useState(false)
    const [page, setPage] = useState({ selected: 0 });
    const [currentpage, setcurrentPage] = useState(1);
    const [practicalCount, setPracticalCount] = useState(0)
    const [questionDifficultyCount, setQuestionDifficultyCount] = useState({})

    const toChars = (n) =>
        `${n >= 26 ? toChars(Math.floor(n / 26) - 1) : ""}${"ABCDEFGHIJKLMNOPQRSTUVWXYZ"[n % 26]
        }`;

    const handleMessage = (message, status, magtime = false) => {
        setConfirmBox(true)
        setMessage({ msg: message, status: status })
        if (magtime) {
            setTimeout(() => setConfirmBox(false), 3000)
        }
    }

    const handleCatchError = (err) => {
        setLoader(false)
        if (err["response"]) {
            if (err["response"].status === 401) {
                if (err["response"]?.data?.message === "You are not authorized to view this content") {
                    handleMessage(err["response"].data.message, false)
                } else {
                    localStorage.removeItem("token");
                    navigate("/login");
                }
            } else if (err["response"].status === 404) {
                handleMessage(err["response"]?.data?.message, false)
            } else {
                localStorage.removeItem("token");
                navigate("/login");
            }
        }
    }


    const getsubcourseOptions = (filterQuestions, page = 1) => {
        const token = localStorage.getItem('token');
        setLoader(true)
        CourseService.getPracticalsQuestionsList(filterQuestions, page, token).then((res) => {
            if (res.data) {
                setLoader(false)
                setExamQuestion(res.data.filterQuestions);
                setPracticalCount(res.data.totalPractical)
                setQuestionDifficultyCount(res.data.difficultyList)
                setPage({ selected: 1 })
                setcurrentPage(page)
                if (res.data.dataStatus) {
                    setExamTypes(res.data.examTypes)
                    setActiveQuestion(res.data.questionStatus)
                    setDifficultyList(res.data.Difficulty)
                }
            }
        }).catch((err) => {
            handleCatchError(err)
        });
    };

    useEffect(() => {
        setLoader(true)
        CourseService.getSubCourses().then((res) => {
            if (res.data) {
                var subcourses = [];
                res.data.map(async (item, index) => {
                    await subcourses.push({
                        id: index + 1,
                        value: item._id,
                        label: item.name,
                    });
                });
                setSubcoursesOptions(subcourses);
                getsubcourseOptions(filterQuestions)
                // setSubcoursesList(res.data)
            }
        }).catch(error => {
            handleCatchError(error);
        });
    }, []);

    const handleIndex = (index) => {
        const finalNumber = ((currentpage * 3) - 3)
        return finalNumber + index
    };

    const difficultyLeval = (difficultyType) => {
        if (difficultyType === 1) {
            return "Easy";
        } else if (difficultyType === 2) {
            return "Medium";
        } else {
            return "Hard";
        }
    };

    const handleChangeQuestionStatus = async (e, questionId, index) => {
        const token = localStorage.getItem("token")
        const questionType = "Practical";
        if (e.target.checked) {
            const questionRight = true;
            const res = await CourseService.changeQuestionsRights(
                questionId,
                questionType,
                questionRight,
                token
            );
            // if (res.data) {
            //     getsubcourseOptions(filterQuestions);
            // }
        } else {
            const questionRight = false;
            const res = await CourseService.changeQuestionsRights(
                questionId,
                questionType,
                questionRight,
                token
            );
            // if (res.data) {
            //     getsubcourseOptions(filterQuestions);
            // }
        }
        setExamQuestion(examQuestion => {
            let copyQuestions = [...examQuestion]
            copyQuestions[index]['questionApprove'] = !e.target.checked
            return copyQuestions
        })
    };

    const handleDelete = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/login");
        } else {
            if (questionId) {
                setLoader(true)
                const questionType = "Practical";
                CourseService.deleteQuestion(questionId, questionType, token).then(
                    (res) => {
                        if (res.data) {
                            window.scrollTo(0, 0);
                            setLoader(false)
                            setMessage({ msg: res.data.message, status: true });
                            setConfirmBox(true);
                            setTimeout(() => setConfirmBox(false), 5000);
                            setOpenModal(false);
                            getsubcourseOptions(filterQuestions)
                        }
                    }
                ).catch(error => {
                    handleCatchError(error);
                });
            } else {
                window.scrollTo(0, 0);
                setMessage({ msg: "delete id is not found", status: false });
                setConfirmBox(true);
                setTimeout(() => setConfirmBox(false), 3000);
            }
        }
    };

    let closeModal = () => {
        setOpenModal(false);
    };

    const handleFilters = (name, value) => {
        let filterQuestionValue = {};
        if (name === 'subCourseId') {
            if (value?.length > 0) {
                setFilterQuestions({ [name]: value });
                filterQuestionValue = { [name]: value }
            } else {
                setFilterQuestions({});
                filterQuestionValue = {}
                setExamTypes([])
                setActiveQuestion([])
                setDifficultyList([])
            }
        } else {
            setFilterQuestions({ ...filterQuestions, [name]: value });
            filterQuestionValue = { ...filterQuestions, [name]: value }
        }
        if (filterQuestionValue) {
            getsubcourseOptions(filterQuestionValue)
        }
    }

    const handlePageChange = (pagenumber) => {
        getsubcourseOptions(filterQuestions, pagenumber)
        window.scrollTo(0, 0);
    }

    return (
        <>
            <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
                <nav>
                    <ol className="breadcrumb mb-0 py-2">
                        <li
                            className={
                                page.selected === 1
                                    ? "breadcrumb-item active"
                                    : "breadcrumb-item text-primary"
                            }
                            style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
                            onClick={() => {
                                setPage({ selected: 1 });
                            }}
                        >
                            Handle practical questions
                        </li>
                        {page.selected === 2 ? (
                            <li
                                className={
                                    page.selected === 2 ? "breadcrumb-item active" : "d-none"
                                }
                            >
                                Update practical questions
                            </li>
                        ) : null}
                    </ol>
                </nav>
            </div>
            {loader &&
                <Loader loaderStatus={loader} message="Loading ....." />
            }
            <div className="container my-2 bg-white p-4">
                {confirmBox && (
                    <div className="formloade">
                        <div className="row text-center">
                            <div className="col-6 w-100 confirmbox">
                                <div
                                    className={`alert ${message.status ? "alert-success" : "alert-danger"
                                        }`}
                                >
                                    {message.msg}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {page.selected === 1 && (
                    <>
                        <div className="mx-auto d-flex justify-content-center mt-2">
                            <div className="me-5" style={{ width: "200px" }}>
                                <label>Select Skill</label>
                                <Select
                                    options={subcourseOptions}
                                    placeholder={"Select Skill"}
                                    value={filterQuestions.subCourseId}
                                    isSearchable
                                    isClearable
                                    isMulti
                                    onChange={(e) => handleFilters("subCourseId", e)}
                                />
                            </div>
                            <div className="me-5" style={{ width: "200px" }}>
                                <label>Select examType</label>
                                <Select
                                    options={examTypes}
                                    placeholder={"Select examType"}
                                    value={filterQuestions?.typeOfExam ? filterQuestions?.typeOfExam : null}
                                    isSearchable
                                    isClearable
                                    isDisabled={examTypes?.length > 1 ? false : true}
                                    onChange={(e) => handleFilters("typeOfExam", e)}
                                />
                            </div>
                            <div className="me-5" style={{ width: "200px" }}>
                                <label>Select Difficulty</label>
                                <Select
                                    options={difficultyList}
                                    placeholder={"Select Difficulty"}
                                    value={filterQuestions?.difficulty ? filterQuestions?.difficulty : null}
                                    isSearchable
                                    isMulti
                                    isDisabled={difficultyList?.length > 1 ? false : true}
                                    onChange={(e) => handleFilters("difficulty", e)}
                                />
                            </div>
                            {/* {
                                subCourseTopics?.length > 0 && <div className="me-5" style={{ width: "200px" }}>
                                    <label>Select subTopic</label>
                                    <Select
                                        options={subCourseTopics}
                                        placeholder={"Select Topic"}
                                        value={filterQuestions.subQuestions}
                                        isSearchable
                                        isMulti
                                        onChange={(e) => handleFilters("subQuestions", e)}
                                    />
                                </div>
                            } */}
                            <div className="me-5" style={{ width: "200px" }}>
                                <label>Select status</label>
                                <Select
                                    options={activeQuestion}
                                    placeholder={"Select status"}
                                    value={filterQuestions?.questionApprove ? filterQuestions.questionApprove : null}
                                    isSearchable
                                    isClearable
                                    isDisabled={activeQuestion?.length > 1 ? false : true}
                                    onChange={(e) => handleFilters("questionApprove", e)}
                                />
                            </div>
                        </div>
                        <div className="text-end my-2">
                            {questionDifficultyCount?.Easy > 0 && <span className="mx-2 ">
                                <span className="text-primary">Easy : </span>
                                {questionDifficultyCount.Easy}
                            </span>}
                            {questionDifficultyCount?.Medium > 0 && <span className="mx-2 ">
                                <span className="text-primary">Medium : </span>
                                {questionDifficultyCount.Medium}
                            </span>}
                            {questionDifficultyCount?.Hard > 0 && <span className="mx-2 ">
                                <span className="text-primary">Hard : </span>
                                {questionDifficultyCount.Hard}
                            </span>}
                        </div>
                    </>
                )}

                {page.selected === 1 && <>
                    {examQuestion?.length > 0 ?
                        <>
                            <div className="d-flex justify-content-center mb-5">
                                <Pagination
                                    activePage={currentpage}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    itemsCountPerPage={3}
                                    totalItemsCount={practicalCount}
                                    pageRangeDisplayed={5}
                                    onChange={(e) => handlePageChange(e)}
                                /></div>
                            <div></div>
                            <ol class="pt-5">
                                {examQuestion.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="text-start text-primary fw-bold">({handleIndex(index + 1)}).</div>
                                            <div className="row col-md-12 my-2">
                                                <div className="col-md-2">
                                                    <span className="text-primary fw-normal">
                                                        SubCourse:
                                                    </span>
                                                    <span className="text-break">{item?.subCourseId?.name}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span className="text-primary fw-normal">
                                                        Difficulty:
                                                    </span>
                                                    {difficultyLeval(item.difficulty)}
                                                </div>
                                                <div className="col-md-2">
                                                    <span className="text-primary fw-normal">
                                                        Exam Type:
                                                    </span>
                                                    {item.typeOfExam}
                                                </div>
                                                <div className="col-md-1">
                                                    <span className="text-primary fw-normal">
                                                        TotalTime:
                                                    </span>
                                                    {item.Time}
                                                </div>
                                                <div className="col-md-1 mx-2">
                                                    <span className="text-primary fw-normal">
                                                        Reminder:
                                                    </span>
                                                    {item.ReminderPrompt}
                                                </div>
                                                <div className="col-md-2 row">
                                                    <div className="col-md-3 text-primary fw-normal">
                                                        Status:
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-check form-switch ms-3">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={item.questionApprove}
                                                                onChange={(e) => {
                                                                    handleChangeQuestionStatus(e, item._id, index);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 row">
                                                    <div className="col-md-4 text-primary fw-normal">
                                                        Action:
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="d-flex">
                                                            <div>
                                                                <button
                                                                    className="btn btn-warning px-2 py-1 mx-1 fs-8"
                                                                    onClick={() => {
                                                                        setPage({ selected: 2 });
                                                                        setQuestionId(item._id);
                                                                        const temp = { ...item };
                                                                        temp.cid = item.subCourseId._id;
                                                                        temp.type = item.typeOfExam;
                                                                        temp.time = item.Time;
                                                                        temp.remainder = item.ReminderPrompt;
                                                                        temp.data = item.Question;
                                                                        const subQuestions = item.subQuestions.map(
                                                                            (i) => {
                                                                                return {
                                                                                    data: i.Question,
                                                                                    time: i.Time,
                                                                                    remainder: i.ReminderPrompt,
                                                                                    difficulty: i.difficulty,
                                                                                };
                                                                            }
                                                                        );
                                                                        temp.subQuestions = subQuestions;
                                                                        setUpdateData({ ...temp });
                                                                    }}
                                                                >
                                                                    <FaPen className="color-white text-white" />
                                                                </button>
                                                            </div>
                                                            <div>
                                                                <button
                                                                    className="btn btn-secondary px-2 py-1 mx-1"
                                                                    id={item._id}
                                                                    onClick={() => {
                                                                        setQuestionId(item._id);
                                                                        setOpenModal(true);
                                                                    }}
                                                                >
                                                                    <FaTimes className="color-white text-white" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div></div>
                      <div></div> */}
                                            </div>
                                            <div
                                                className="mx-2"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.Question,
                                                }}
                                            ></div>
                                            <ul className="ms-5">
                                                {item.subQuestions.length > 0 &&
                                                    item.subQuestions.map((item2, index) => {
                                                        return (
                                                            <>
                                                                <div className="d-flex">
                                                                    <div className="me-2">
                                                                        <span>{toChars(index)} .</span>
                                                                    </div>
                                                                    <div>
                                                                        <span
                                                                            className="list-unstyled"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: `${item2.Question}`,
                                                                            }}
                                                                        ></span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                            </ul>
                                        </React.Fragment>
                                    );
                                })}
                            </ol>
                            <div className="d-flex justify-content-center mb-5">
                                <Pagination
                                    activePage={currentpage}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    itemsCountPerPage={3}
                                    totalItemsCount={practicalCount}
                                    pageRangeDisplayed={5}
                                    onChange={(e) => handlePageChange(e)}
                                /></div>
                            <div>
                                <Modal
                                    show={openModal}
                                    onHide={closeModal}
                                    style={{ marginTop: "15%" }}
                                    aria-labelledby="ModalHeader"
                                >
                                    <Modal.Body>
                                        <h5>Are you sure you want to delete this question ?</h5>
                                    </Modal.Body>
                                    <Modal.Footer class="text-center mb-2">
                                        <button
                                            className="btn btn-primary me-3"
                                            onClick={handleDelete}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            className="btn btn-primary"
                                            onClick={(e) => setOpenModal(false)}
                                        >
                                            No
                                        </button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </> : <div className="text-center text-danger my-5">No Records Founds</div>
                    }
                </>}
                {updateData._id && page.selected === 2 && (
                    <AddPracticalXslx
                        updateData={updateData}
                        updated={(value) => getsubcourseOptions(filterQuestions)}
                    />
                )}
            </div>
        </>
    );
}
