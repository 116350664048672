import React, { useEffect, useState } from 'react';
import variable from '../../servicesvariable'

const ShowContactForm = () => {
    const contactform = variable.Tutorial_get_Contact_Form

    return (
        <div className='container'>

            <iframe
                title="Embedded Content"
                width="500"
                height="500"
                src={contactform}
                frameBorder="0"
                allowFullScreen
            />

        </div>
    );
};

export default ShowContactForm;
