import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Batch from '../../services/Batch';

const AddBatch = () => {
    const [batches, setBatches] = useState([]);
    const [formData, setFormData] = useState({ name: '', description: '' });
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        // Check if an ID is provided in the URL params
        if (id) {
            // Fetch batch data for editing
            Batch.getBatchById(id)
                .then((res) => {
                    const { Name, Description, Department } = res.data[0];
                    setFormData({ Name, Description, Department });
                    setIsEditing(true);
                })
                .catch((error) => {
                    console.error('Error fetching batch for editing:', error);
                });
        }
    }, [id]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { Name, Description, Department } = formData;

        if (!Name) {
            alert('Name is required.');
            return;
        }

        const batchData = {
            Name,
            Description,
            Department
        };

        // Check if it's an edit or create operation
        if (isEditing) {
            // Update existing batch
            Batch.editBatch(id, batchData)
                .then(() => {
                    alert('Batch updated successfully');
                    navigate('/admin/Batch/list');
                })
                .catch((error) => {
                    console.error('Error updating batch:', error);
                });
        } else {
            // Create new batch
            Batch.addBatch(batchData)
                .then((res) => {
                    if (res.status === 201) {
                        alert('Batch created successfully')
                        navigate('/admin/Batch/list');
                    } else {
                        alert("something went wrong")
                    }

                })
                .catch((error) => {
                    console.error('Error creating batch:', error);
                });
        }
    };


    return (
        <div className='container'>
            <div className="d-flex  justify-content-between align-items-center">
                <h2>{isEditing ? 'Edit Batch' : 'Create Batch'}</h2>
                <Link to="/admin/Batch/list">
                    <button
                        className="btn btn-success"
                    >
                        Click to view batch list
                    </button></Link>

            </div>

            <form onSubmit={handleSubmit} className="container mt-4 mx-4">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <label>
                            Name:
                            <input
                                type="text"
                                name="Name"
                                value={formData.Name}
                                onChange={handleChange}
                                className="form-control"
                                required
                            />
                            <div dangerouslySetInnerHTML={{ __html: formData.Name }} />
                        </label>
                        <br />
                        <label>
                            Description:
                            <input
                                type="text"
                                name="Description"
                                value={formData.Description}
                                onChange={handleChange}
                                className="form-control"
                            />
                            <div dangerouslySetInnerHTML={{ __html: formData.Description }} />
                        </label>
                        <br />
                        <label>
                            Department:
                            <input
                                type="text"
                                name="Department"
                                value={formData.Department}
                                onChange={handleChange}
                                className="form-control"
                            />
                            <div dangerouslySetInnerHTML={{ __html: formData.Department }} />
                        </label>
                        <br />
                        <button type="submit" className="btn btn-primary mt-2">
                            {isEditing ? 'Update Batch' : 'Create Batch'}
                        </button>
                    </div>
                </div>
            </form>
        </div>

    )
};

export default AddBatch;
