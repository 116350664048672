import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Batch from '../../services/Batch';


const BatchLists = () => {
    const [batches, setBatches] = useState([]);
    const navigate = useNavigate()

    // Set initial state
    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = () => {
        Batch.getBatch().then(res => {
            setBatches(res.data)
        }).catch(e => console.log(e))
    }

    const handleDelete = (batchId) => {
        // Implement delete functionality here
        Batch.deleteBatch(batchId).then(res => {
            if (res.status === 200) {
                alert("Data deleted successfully");
                fetchData()
            } else {
                alert("something wen wrong")
            }
        }).catch(error => alert(error))
    };


    return (<div className="container mt-4 mx-auto">
        <div className="d-flex justify-content-between align-items-center">
            <h2>Batch List</h2>
            <Link to="/admin/createBatch">
                <button className="btn btn-success">Add batch</button>
            </Link>
        </div>
        <table className="table">
            <thead>
                <tr>
                    <th>Sr No</th>
                    <th>Batch Name</th>
                    <th>Description</th>
                    <th>Department</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>

                {batches.map((batch, index) => (
                    <tr key={batch.id}>
                        <td>{index + 1}</td>
                        <td>{batch.Name}</td>
                        <td>{batch.Description}</td>
                        <td>{batch.Department}</td>
                        <td>
                            <Link to={`/admin/batchdetails?id=${batch.id}`}>
                                <button data-toggle="tooltip" data-placement="top" title='view' className="btn me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </svg>
                                </button></Link>
                            <Link to={`/admin/updateBatch/${batch.id}`}>
                                <button className="btn btn-primary" >Edit</button>
                            </Link>
                            <button className="btn btn-danger ml-2 ms-1" onClick={() => handleDelete(batch.id)}>Delete</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div >
    )
};
export default BatchLists;