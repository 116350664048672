import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { FaPen, FaTimes } from "react-icons/fa";
import BatchManagementServices from "../../services/BatchManagementServices";
import CourseService from "../../services/CourseService";
import { Modal } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

export default function AddDayInBatch() {
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [AllOptions, setAllOptions] = useState([]);
  //   const [confirmBox, setConfirmBox] = useState(false);
  const [postData, setPostData] = useState({
    batchName: "",
    startDate: "",
    endDate: "",
    reason: "",
    remark: "",
  });
  const [postDataError, setPostDataError] = useState({});
  const [batchesOptions, setBatchesOptions] = useState([]);
  const [batches, setBatches] = useState([]);
  const [allDayBatches, setAllDayBatches] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [reasonoption, setReasonOption] = useState([
    { value: "For Practice", label: "For Practice" },
    { value: "Trainer on Leave", label: "Trainer on Leave" },
    { value: "Trainee on Leave", label: "Trainee on Leave" },
    { value: "Exam/Review Pending", label: "Exam/Review Pending" },
  ]);
  const [confirmBox, setConfirmBox] = useState(false);
  var token = localStorage.getItem("token");

  useEffect(() => {
    CourseService.getSubCourses().then((res) => {
      let data = res.data.map((item) => {
        return { value: item.name, label: item.name };
      });
      setAllOptions(data);
    });
    getTrainingBatches();
    getBatchSchedule();
  }, [confirmBox]);

  function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  const getBatchSchedule = () => {
    if (token) {
      BatchManagementServices.getDayBatch(token)
        .then((res) => {
          if (res.data && res.data.msg && res.data.msg.length > 0) {
            setAllDayBatches(res.data.msg);
          }
        })
        .catch((error) => console.log(error));
    } else {
      navigate("/login");
    }
  };

  const DateFormat = (dateValue) => {
    let date = new Date(dateValue);
    return `${date.getFullYear()}-${
      date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
    }-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`;
    // }
  };

  const getTrainingBatches = () => {
    if (token) {
      // setToken(token);
      BatchManagementServices.GetAllBatch(token)
        .then((res) => {
          setBatches(res.data.data);
          const tempBatches = [];
          if (res.data.data?.length > 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              const batch = res.data.data[i];
              if (batch.status) {
                const temp = {
                  value: batch.name,
                  label: batch.name,
                  id: batch._id,
                };
                tempBatches.push(temp);
              }
            }
          }
          setBatchesOptions([...tempBatches]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate("/login");
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "startDate":
        if (postData.endDate?.length > 0) {
          const startValue = new Date(value);
          const endValue = new Date(postData.endDate);
          if (startValue < endValue) {
            setPostData({ ...postData, [name]: value });
            setPostDataError({});
          } else {
            setPostData({ ...postData, [name]: value });
            setPostDataError({
              ...postDataError,
              endData: "",
              startDate: "Please Start Date Value Less Than End Date",
            });
          }
        } else {
          setPostData({ ...postData, [name]: value });
          setPostDataError({});
        }
        break;
      case "endDate":
        if (value?.length > 0) {
          if (postData.startDate !== "") {
            const startValue = new Date(postData.startDate);
            const endValue = new Date(value);
            if (startValue >= endValue) {
              setPostDataError({
                ...postDataError,
                [name]: "Please End Date Value Greater Than Start Date",
              });
              setPostData({ ...postData, [name]: value });
            } else {
              setPostData({ ...postData, [name]: value });
              setPostDataError({
                ...postDataError,
                [name]: "",
              });
            }
          } else {
            setPostDataError({
              ...postDataError,
              [name]: "Please Select Start Date",
            });
            setPostData({ ...postData, [name]: value });
          }
        }
        break;
      default:
    }
  };

  const checkValid = (dayData) => {
    const setError = {};
    let valid = true;
    const keys = Object.keys(dayData);
    for (let dayValue of keys) {
      const value = dayData[dayValue];
      if (value === "" || value === undefined) {
        valid = false;
        setError[dayValue] = "Required Filed";
      }
    }
    setPostDataError(setError);
    return valid;
  };

  let closeModal = () => {
    setOpenModal(!openModal);
    setUpdateId("");
  };

  const handleDeleteBatch = () => {
    setOpenModal(false);
    if (token && updateId) {
      BatchManagementServices.deleteDayBatch(updateId, token)
        .then((res) => {
          setPostData({
            ...postData,
            batchName: "",
            startDate: "",
            endDate: "",
            reason: "",
            remark: "",
          });
          setSelectedOptions([]);
          setUpdateId("");
          alert(res.data.msg);
          setConfirmBox(true);
          setTimeout(() => {
            setConfirmBox(false);
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate("/login");
    }
  };

  const handleCreateBatch = (e) => {
    e.preventDefault();
    let temp = {
      batchName: postData.batchName.value,
      startDate: postData.startDate,
      endDate: postData.endDate,
      reason: postData.reason.value,
      remark: postData.remark,
    };
    const validForm = checkValid(temp);
    if (validForm) {
      if (updateId === "") {
        BatchManagementServices.postDayBatch(temp, token)
          .then((res) => {
            setPostData({
              ...postData,
              batchName: "",
              startDate: "",
              endDate: "",
              reason: "",
              remark: "",
            });
            setSelectedOptions([]);
            alert(res.data.msg);
            setConfirmBox(true);
            setTimeout(() => {
              setConfirmBox(false);
            }, 5000);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        BatchManagementServices.updateDayBatch(temp, updateId, token)
          .then((res) => {
            if (res.data) {
              setPostData({
                ...postData,
                batchName: "",
                startDate: "",
                endDate: "",
                reason: "",
                remark: "",
              });
              setUpdateId("");
              setSelectedOptions([]);
              setConfirmBox(true);
              setTimeout(() => {
                setConfirmBox(false);
              }, 5000);
              alert(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      checkValid(temp);
    }
  };

  return (
    <>
      <div className="container mb-5 w-75">
        {token && jwt_decode(token).role === "Admin" && (
          <div className="">
            <form onSubmit={handleCreateBatch}>
              <h3 className="text-center">Add Day In Batch</h3>
              <div class="form-group mt-4">
                <label for="exampleInputEmail1">Batch Name</label>
                {batches && batches.length > 0 && (
                  <Select
                    // {...register("batchName")}
                    options={batchesOptions}
                    value={postData.batchName}
                    placeholder="Please Select Batch"
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        batchName: { value: e.id, label: e.label },
                      })
                    }
                    required
                  />
                )}
                {postDataError && postDataError.batchName?.length > 0 && (
                  <small className="text-danger mt-1">
                    {postDataError.batchName}
                  </small>
                )}
              </div>
              <div class="form-group mt-3 row">
                <div className="col">
                  <label for="exampleInputPassword1">Start Date</label>
                  <input
                    // {...register("startDate")}
                    value={postData.startDate}
                    onChange={handleChange}
                    type="date"
                    name="startDate"
                    class="form-control mt-1"
                    placeholder="Password"
                  />
                  {postDataError && postDataError.startDate?.length > 0 && (
                    <small className="text-danger mt-1">
                      {postDataError.startDate}
                    </small>
                  )}
                </div>
                <div className="col">
                  <label for="exampleInputPassword1">End Date</label>
                  <input
                    // {...register("endDate")}
                    value={postData.endDate}
                    name="endDate"
                    onChange={handleChange}
                    // onChange={(e) => {
                    //   setPostData({ ...postData, endDate: e.target.value });
                    // }}
                    type="date"
                    class="form-control mt-1"
                    placeholder="Password"
                  />
                  {postDataError && postDataError.endDate?.length > 0 && (
                    <small className="text-danger mt-1">
                      {postDataError.endDate}
                    </small>
                  )}
                </div>
              </div>
              <div class="form-group mt-4">
                <label for="exampleInputEmail1">Reason</label>
                <Select
                  options={reasonoption}
                  // {...register("reason")}
                  value={postData.reason}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      reason: { value: e.value, label: e.label },
                    })
                  }
                  isSearchable={false}
                  required
                />
                {postDataError && postDataError.reason?.length > 0 && (
                  <small className="text-danger mt-1">
                    {postDataError.reason}
                  </small>
                )}
              </div>
              <div class="form-group mt-4">
                <label for="exampleInputEmail1">Remark</label>
                <input
                  // {...register("remark")}
                  value={postData.remark}
                  onChange={(e) => {
                    setPostData({ ...postData, remark: e.target.value });
                  }}
                  type="text"
                  class="form-control mt-1"
                  placeholder="Enter Remark"
                />
                {postDataError && postDataError.remark?.length > 0 && (
                  <small className="text-danger mt-1">
                    {postDataError.remark}
                  </small>
                )}
              </div>
              <div className="mt-3 ">
                <button className="btn btn-primary me-2">Submit</button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    setPostData({
                      batchName: "",
                      startDate: "",
                      endDate: "",
                      reason: "",
                      remark: "",
                    });
                    setPostDataError({});
                    setUpdateId("");
                  }}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        )}
        <div class="my-4">
          <h3 className="text-center">Batch Related Days</h3>
          <table className="table mt-4">
            <thead className="fw-bold">
              <th scope="col">#</th>
              <th scope="col" className="ps-2">
                Batch Name
              </th>
              <th scope="col" className="ps-2">
                Start Date
              </th>
              <th scope="col" className="ps-2">
                End Date
              </th>
              <th scope="col" className="ps-2">
                Reason
              </th>
              <th scope="col" className="col-md-3 ps-2">
                Remark
              </th>
              <th scope="col" className="ps-2">
                Created By
              </th>
              {token && jwt_decode(token).role === "Admin" && (
                <th scope="col" className="ps-2">
                  Action
                </th>
              )}
            </thead>
            {allDayBatches && allDayBatches.length > 0 ? (
              <tbody>
                {allDayBatches.map((batch, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      {batch.batchName && batch.batchName._id ? (
                        <td>{batch.batchName.name}</td>
                      ) : (
                        <td>-</td>
                      )}
                      <td>{DateFormat(batch.startDate)}</td>
                      <td>{DateFormat(batch.endDate)}</td>
                      <td>{batch.reason}</td>
                      <td>{batch.remark}</td>
                      {batch.createdBy && batch.createdBy._id ? (
                        <td>{batch.createdBy.username}</td>
                      ) : (
                        <td>-</td>
                      )}
                      {token && jwt_decode(token).role === "Admin" && (
                        <td>
                          <button
                            class="btn btn-warning px-2 py-1 mx-1 my-sm-2 my-2 my-md-0"
                            onClick={() => {
                              setPostData({
                                batchName: batch.batchName
                                  ? {
                                      value: batch.batchName._id,
                                      label: batch.batchName.name,
                                    }
                                  : "",
                                startDate: new Date(
                                  batch.startDate
                                ).toLocaleDateString("sv-SE"),
                                endDate: new Date(
                                  batch.endDate
                                ).toLocaleDateString("sv-SE"),
                                reason: {
                                  value: batch.reason,
                                  label: batch.reason,
                                },
                                remark: batch.remark,
                              });
                              setUpdateId(batch._id);
                            }}
                          >
                            <FaPen className="color-white text-white" />
                          </button>
                          <button
                            class="btn btn-secondary px-2 py-1 mx-1"
                            onClick={() => {
                              setOpenModal(!openModal);
                              setUpdateId(batch._id);
                            }}
                          >
                            <FaTimes className="color-white text-white" />
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <p>No Batches Avilable</p>
            )}
          </table>
        </div>
        <div>
          <Modal
            show={openModal}
            onHide={closeModal}
            style={{ marginTop: "5%" }}
            aria-labelledby="ModalHeader"
          >
            <Modal.Header>
              <Modal.Title>Day Batch Detail</Modal.Title>
              <button
                onClick={(e) => {
                  setOpenModal(!openModal);
                  setUpdateId("");
                }}
              >
                <FaTimes />
              </button>
            </Modal.Header>
            <Modal.Body>Are you sure delete batch details ?</Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={handleDeleteBatch}>
                Yes
              </button>
              <button className="btn btn-primary" onClick={closeModal}>
                No
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}
