import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import { FaCloudUploadAlt } from "react-icons/fa";
import ShoWHideToggle from "../showHideToggle/ShowHideToggle";
import { useNavigate } from "react-router-dom";

export default function ManageM3U8videos(props) {
  const navigate = useNavigate();
  const [coursevideos, setCoursevideos] = useState([]);
  const [cvids, setCvids] = useState({
    cid: "",
    topic: "",
    videos: [],
    pdf: [],
  });
  const [uvids, setUvids] = useState({
    topic: "",
    day: 0,
    videos: [],
    pdf: [],
  });
  const [page, setPage] = useState({ selected: 1, cname: "", topic: "" });
  const [dvids, setDvids] = useState([]);
  const [allvideos, SetAllVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [confirmBox2, setConfirmBox2] = useState(false);
  const [addvideoBox, setAddvideoBox] = useState(false);
  const [addvideoday, setAddvideoDay] = useState(false);
  const [msg, setMsg] = useState("");
  const [FormdataCvids, setFormdataCvids] = useState(true);
  const [id, setID] = useState(0);
  const [dSelectStatus, setDeselectStatus] = useState(false);
  const [handleDeletePopup, setHandleDeletePopup] = useState(false);

  useEffect(() => {
    CourseService.getm3u8video()
      .then((res) => {
        SetAllVideos(res.data);
      })
      .catch((err) => console.log(err));
    {
      setTimeout(() => setConfirmBox(false), 5000);
    }
  }, [confirmBox]);

  const handleVidcheck = (e, vid) => {
    const target = e.target;
    if (target.checked) {
      setDvids((d) => [...d, vid]);
    } else {
      const uvids = dvids.filter((item) => item !== vid);
      setDvids(uvids);
    }
  };

  const handleUploadVids = async (param) => {
    let { videos } = param;
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (videos.length > 0) {
        setAddvideoBox(false);
        setAddvideoDay(false);
        // setLoading(true);

        const formdata = new FormData();
        for (const item of videos) {
          const text = /^video/;
          if (text.test(item.type)) {
            formdata.append("cvids", item);
            setMsg("Videos uploaded successfully..");
          } else {
            await setFormdataCvids(false);
            setMsg("file required in video format(like...mp4)");
          }
        }
        try {
          CourseService.postm3u8video(formdata).then((res) => {
            if (res.data) {
              scrollToTop();
              setConfirmBox(true);
              setUvids({ topic: "", videos: "" });
              // setLoading(false);
            } else {
              // console.log("");
            }
          });
        } catch (ex) {
          console.log(ex);
        }
        const upCvideos = [];
      }
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRemoveVids = async () => {
    scrollToTop();
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      CourseService.deletem3u8video(id, token)
        .then((res) => {
          setMsg("Videos deleted successfully..");
          setConfirmBox(true);
        })
        .catch((ex) => console.log(ex));
    }
  };

  const handleRemoveAllm3u8Vids = async () => {
    const token = localStorage.getItem("token");
    if (dvids.length > 0) {
      setLoading(true);
      let upVideo = cvids;
      const videos = cvids.videos.filter((item) => !dvids.includes(item));
      upVideo.videos = videos;
      upVideo.dvideos = dvids;
      if (!token) {
        navigate("/login");
      } else {
        try {
          const res = await CourseService.removeAllm3u8Video(upVideo, token);
          if (res.data.msg) {
            setConfirmBox(true);
            setMsg(res.data.msg);
          }
        } catch (ex) {
          console.log(ex);
        }
      }
      setDvids([]);
      setLoading(false);
    }
    {
      setTimeout(() => setConfirmBox(false), 10000);
    }
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li
              className={
                page.selected === 1
                  ? "breadcrumb-item active"
                  : "breadcrumb-item text-primary"
              }
              style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
              onClick={() => {
                setDvids([]);
                setPage((d) => ({ selected: 1, cname: "" }));
                setAddvideoBox(true);
              }}>
              Videos
            </li>
            {addvideoBox && (
              <li className={`breadcrumb-item d-block active`}>Add Video</li>
            )}
          </ol>
        </nav>
        <div>
          <label
            htmlFor={page.selected === 1 ? "uvids" : "abc"}
            className={
              page.selected == 1
                ? "btn bg-green text-white visible"
                : "btn bg-green text-white invisible"
            }
            onClick={() => {
              setAddvideoDay(true);
            }}>
            <FaCloudUploadAlt className="me-2 fs-5" />
            Upload video
          </label>
        </div>
      </div>
      <div className="container p-2 px-3">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Uploading Videos...</div>
            </div>
          </div>
        )}
        {confirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div className="alert alert-success">{msg}</div>
              </div>
            </div>
          </div>
        )}

        {addvideoday && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <form className="px-lg-5 pt-3" id="myform">
                  <div className="mb-3">
                    <label htmlFor="ppts" className="form-label">
                      Select videos
                    </label>
                    <input
                      type="file"
                      id="videos"
                      className="form-control"
                      required
                      multiple
                      onChange={(e) =>
                        setUvids((d) => ({
                          ...d,
                          videos: e.target.files,
                        }))
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-secondary mt-5 me-3"
                      onClick={() => {
                        setAddvideoDay(false);
                        setUvids({ ...uvids, videos: [] });
                      }}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn bg-green text-white mt-5"
                      disabled={uvids?.videos?.length > 0 ? false : true}
                      onClick={() => {
                        handleUploadVids(uvids);
                      }}>
                      Upload
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {dvids.length > 0 && !addvideoBox && !dSelectStatus ? (
          <ShoWHideToggle
            videoAndPPT={dvids}
            allVideoAndPPT={allvideos}
            setVideoAndPPT={setDvids}
            setDSelectPPTAndVideo={setDeselectStatus}
            setHandleDeletePopup={setHandleDeletePopup}
            handleRemovePPTAndVideo={handleRemoveAllm3u8Vids}
          />
        ) : (
          handleDeletePopup && (
            <ShoWHideToggle
              videoAndPPT={dvids}
              allVideoAndPPT={allvideos}
              setVideoAndPPT={setDvids}
              setDSelectPPTAndVideo={setDeselectStatus}
              setHandleDeletePopup={setHandleDeletePopup}
              handleRemovePPTAndVideo={handleRemoveAllm3u8Vids}
            />
          )
        )}
        <div className="form px-lg-5 pt-3">
          {page.selected === 1 ? (
            <>
              <h4>All Videos</h4>
              <table className="table mt-4">
                <thead className={page.selected !== 1 ? "d-none" : ""}>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Select</th>
                    <th scope="col">Video</th>
                    {/* <th scole="col">Handle</th> */}
                  </tr>
                </thead>
                <tbody>
                  {allvideos.map((item, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <input
                            type="checkbox"
                            checked={
                              dvids.find((dp) => dp === item) ? true : false
                            }
                            id={"check" + index}
                            className="form-check-input p-2 pptcheck"
                            onChange={(e) => {
                              handleVidcheck(e, item);
                            }}
                          />
                        </td>
                        {!item.process ? (
                          <>
                            <td className="text-secondary">
                              {item.videoName.split("-").slice(1, 7).join("-")}{" "}
                              (converting...)
                              {confirmBox2 && item._id == id && (
                                <div className="formloade bg-footer m-2  border">
                                  <div className="row text-center ">
                                    <div className=" confirmbox">
                                      <div className="alert p-2  fw-bold">
                                        Do you really want to remove video?
                                        {/* <br /> */}
                                        <button
                                          className="btn btn-danger ms-4"
                                          onClick={() => {
                                            setConfirmBox2(false);
                                          }}>
                                          Cancel
                                        </button>
                                        <button
                                          className="btn btn-success ms-2"
                                          onClick={() => {
                                            handleRemoveVids();
                                            setConfirmBox2(false);

                                            // setConfirmBox(true);
                                          }}>
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            {/* <td>
                              <button
                                className="btn btn-danger rounded-pill d-flex justify-content-center align-items-center p-0 ms-3"
                                style={{
                                  height: "1.8rem",
                                  width: "1.8rem",
                                }}
                                onClick={() => {
                                  setID(item._id);
                                  setConfirmBox2(true);
                                }}
                              >
                                <FaTrash className="fs-6" />
                              </button>
                            </td> */}
                          </>
                        ) : (
                          <>
                            <td>
                              {item.videoName.split("-").slice(1, 7).join("-")}
                              {confirmBox2 && item._id == id && (
                                <div className="formloade bg-footer m-2  border">
                                  <div className="row text-center ">
                                    <div className=" confirmbox">
                                      <div className="alert p-2  fw-bold">
                                        Do you really want to remove video?
                                        {/* <br /> */}
                                        <button
                                          className="btn btn-danger ms-4"
                                          onClick={() => {
                                            setConfirmBox2(false);
                                          }}>
                                          Cancel
                                        </button>
                                        <button
                                          className="btn btn-success ms-2"
                                          onClick={() => {
                                            handleRemoveVids();
                                            setConfirmBox2(false);
                                            // setConfirmBox(true);
                                          }}>
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            {/* <td>
                              <button
                                className="btn btn-danger rounded-pill d-flex justify-content-center align-items-center p-0 ms-3"
                                style={{
                                  height: "1.8rem",
                                  width: "1.8rem",
                                }}
                                onClick={() => {
                                  setID(item._id);
                                  setConfirmBox2(true);
                                }}
                              >
                                <FaTrash className="fs-6" />
                              </button>{" "}
                            </td> */}
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
