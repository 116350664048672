// CreateOrUpdateCourse.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import CourseListService from '../../services/Course-List-Service';

const CreateOrUpdateCourse = () => {
    const [course, setCourse] = useState({
        course_name: '',
        category: '',
        level: '',
        description: '',
    });

    const levels = ['Beginner', 'Intermediate', 'Advanced']; // Add your predefined levels here

    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            CourseListService.getCourseById(id).then((res) => {
                console.log(res);
                setCourse(res.data[0]);
            });
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCourse((prevCourse) => ({
            ...prevCourse,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id) {
                await CourseListService.updateCourseByID(id, course);
                alert('Course updated successfully');
            } else {
                await CourseListService.addnewCourse(course);
                alert('Course created successfully');
            }
            navigate('/admin/course-list');
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    return (
        <div className="container mt-4">
            <div className='row'>
                <div className='col-6'><h2>{id ? 'Edit Course' : 'Add Course'}</h2></div>
                <div className='col-6 d-flex justify-content-end'>
                    <Link to="/admin/course-list">
                        <button className='btn btn-success'>View Courselist</button>
                    </Link>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="course_name">Course Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="course_name"
                        name="course_name"
                        value={course.course_name}
                        onChange={handleChange}
                    />
                    <div dangerouslySetInnerHTML={{ __html: course.course_name }} />
                </div>
                <div className="form-group">
                    <label htmlFor="category">Category</label>
                    <input
                        type="text"
                        className="form-control"
                        id="category"
                        name="category"
                        value={course.category}
                        onChange={handleChange}
                    />
                    <div dangerouslySetInnerHTML={{ __html: course.category }} />
                </div>
                <div className="form-group">
                    <label htmlFor="level">Level</label>
                    <select
                        className="form-control"
                        id="level"
                        name="level"
                        value={course.level}
                        onChange={handleChange}
                    >
                        <option value="" disabled>
                            Select Level
                        </option>
                        {levels.map((level) => (
                            <option key={level} value={level}>
                                {level}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        value={course.description}
                        onChange={handleChange}
                    />
                    <div dangerouslySetInnerHTML={{ __html: course.description }} />
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                    {id ? 'Update Course' : 'Create Course'}
                </button>
            </form>
        </div>
    );
};

export default CreateOrUpdateCourse;
