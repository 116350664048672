import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import StudentListService from '../../services/Student-List-Service';

const StudentDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)

    let id = queryParams.get('id')

    const [formData, setFormData] = useState([{
        name: '',
        email: '',
        birthdate: ''
    }]);

    useEffect(() => {
        if (id) {
            StudentListService.getStudentById(id).then(res => {
                setFormData(res.data)

            }).catch(err => setFormData([]))
        }
    }, [])

    return (
        <div className='container'>
            <div className="d-flex justify-content-between align-items-center">
                <h2>Student Details</h2>
                <Link to="/admin/student-list">
                    <button className="btn btn-success">Clike to view Student  List</button>
                </Link>

            </div>
            <div className='row'>
                {formData && formData.length > 0 ? formData.map(formData => (<>
                    <div className="card mt-5 mx-auto" style={{ width: '35rem' }}>
                        <div className="card-body">
                            <h4>Name:</h4> <h6 className="card-title" dangerouslySetInnerHTML={{ __html: formData.studentname }} /><hr />
                            <h4 className='mt-3'>Email:</h4> <p className="card-text mt-1" dangerouslySetInnerHTML={{ __html: formData.studentemail }} /><hr />
                            <h4 className='mt-3'>Birthdate:</h4> <p className="card-text mt-1" dangerouslySetInnerHTML={{ __html: formData.birthdate }} /><hr />
                        </div>
                    </div></>))
                    : (
                        <> <h5 dangerouslySetInnerHTML={{ __html: `No data found ${id}` }} /></>
                    )}

            </div>
        </div>
    );
};

export default StudentDetails;
