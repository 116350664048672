// const HOST = "http://localhost";
// const CLIENT = "http://localhost:3000";
// const SERVER = "http://20.84.49.221:5000";
// const SERVER = "http://192.168.103.7:5000"

// when zerotheret build
// const HOST = "http://localhost";
// const CLIENT = "http://localhost:3000";
// const SERVER = "https://vulnerableapi.zerothreat.ai";

// const HOST = "http://192.168.102.249";
// const CLIENT = "http://192.168.102.249:3000";
// const SERVER = "http://192.168.102.249:5000";

/**** Staging URLs ****/
// const HOST = "http://rxtutorial.radixdev77.com";
// const CLIENT = "http://rxtutorial.radixdev77.com";
// const SERVER = "http://rxtutorial.radixdev77.com:5000";

// /**** Live URLs ****/
const HOST = "https://vulnerableapi.zerothreat.ai";
const CLIENT = "https://vulnerable.zerothreat.ai"
const SERVER = "https://vulnerableapi.zerothreat.ai"


const url = {
  TUTORIAL_API_CATEGORY_URL: `${SERVER}/api/category`,
  TUTORIAL_API_COURSE_URL: `${SERVER}/course/`,
  TUTORIAL_API_SUBCOURSE_URL: `${SERVER}/api/subcourse`,
  TUTORIAL_API_BATCH_URL: `${SERVER}/api/batch`,
  TUTORIAL_API_TPDF_URL: `${SERVER}/api/trainingPDF`,
  TUTORIAL_API_CONTENT_URL: `${SERVER}/api/updatecontent/`,
  TUTORIAL_API_API_URL: `${SERVER}/api/course/`,
  TUTORIAL_API_TRAINING_URL: `${SERVER}/api/training-progress/`,
  TUTORIAL_API_Exam_URL: `${SERVER}/api/exam-api/`,
  TUTORIAL_API_LOGIN_URL: `${SERVER}/login/`,
  TUTORIAL_API_LOGOUT_URL: `${SERVER}/logout/`,
  TUTORIAL_API_API_USER: `${SERVER}/api/user/`,
  TUTORIAL_API_ADDDAYINBATCH_URL: `${SERVER}/api/adddayinbatch/`,
  TUTORIAL_API_BATCHMANAGEMENT_URL: `${SERVER}/api/batchmanagement/`,
  TUTORIAL_API_TRAINING_LEAVE_REASON: `${SERVER}/api/getTrainingLeaveReason`,
  Tutorial_API_GetCategory: `${SERVER}/api/productcategory/getcategory/`,
  Tutorial_Api_AddCategory: `${SERVER}/api/productcategory/postCategory`,
  Tutorial_Api_EditCategory: `${SERVER}/api/productcategory/editCategory/`,
  Tutorial_Api_deleteCategory: `${SERVER}/api/productcategory/deletecategory/`,
  Tutorial_API_GetProducts: `${SERVER}/api/products/`,
  Tutorial_API_AddProduct: `${SERVER}/api/products/addnewproduct/`,
  Tutorial_Api_EditProduct: `${SERVER}/api/products/editProduct/`,
  Tutorial_Api_deleteProduct: `${SERVER}/api/products/deleteProduct/`,
  Tutorial_Api_Course: `${SERVER}/api/managecourse/`,
  TutorialApi_AddNewCourse: `${SERVER}/api/managecourse/addnewcourse/`,
  Tutorial_Api_Student: `${SERVER}/api/student/`,
  TutorialApi_AddNewStudent: `${SERVER}/api/student/addnewStudent/`,
  Tutorial_API_GetBatch: `${SERVER}/api/managebatch/`,
  Tutorial_Api_AddBatch: `${SERVER}/api/managebatch/addnewBatch/`,
  Tutorial_Api_EditBatch: `${SERVER}/api/managebatch/editBatch/`,
  Tutorial_Api_deleteBatch: `${SERVER}/api/managebatch/deleteBatch/`,
  Tutorial_Api_ContactUs: `${SERVER}/api/contactus/`,
  Tutorial_APi_ManageTextData: `${SERVER}/api/managetextdata`,
  Tutorial_Api_FilesList: `${SERVER}/api/documents/documentList`,
  Tutorial_Api_Documents: `${SERVER}/api/documents`,
  Tutorial_get_Contact_Form: `${SERVER}/images/Contact.html`,
  TutorialVideoLink: `${SERVER}/file_example_mp4_480_1_5mg.m3u8`,

  SONAR_URL: `${SERVER}/api/sonar/`,
  imgURL: `${SERVER}/public/images`,
  pptURL: `${SERVER}/public/ppts`,
  fileURL: `${SERVER}/public/files`,
  uploadUrl: `${SERVER}/public/uploads`,
  newVideoUrl: `${SERVER}/public/m3u8Video`,
  examVideo: `https://787878784dddsfdfd.cloudfront.net/trainees`,
  CLOUDNARY_IMG_UP: `https://api.cloudinary.com/v1_1/tutorialradix/image/upload`,
  CLOUDNARY_RAW_UP: `https://api.cloudinary.com/v1_1/tutorialradix/raw/upload`,
  SERVER,
  host: `${HOST}`,
  url: CLIENT,
  // confirmationAverageDetail: {
  //   specialSkills: [
  //     {
  //       skill: "TrainingProject",
  //       proportion: 60,
  //       columns: [
  //         "Communication74",
  //         "Analytics",
  //         "Database",
  //         "UI",
  //         "Serverside",
  //         "IP",
  //         "Communication",
  //         "Analytics",
  //         "Database",
  //         "UI",
  //         "Serverside",
  //         "EP",
  //       ],
  //     },
  //     {
  //       skill: "LiveCodingTest",
  //       proportion: 10,
  //       columns: [
  //         "IP",
  //         "EP",
  //       ],
  //     },
  //   ],
  //   rest: 25,
  //   academic: 5,
  // },
  confirmationAverageDetail: {
    specialSkills: [
      {
        skill: "TrainingProject",
        id: "62724b636fee5c70b251b8cb",
        proportion: 0.6, // means 60%
        columns: [
          "Communication",
          "Analytics",
          "Database",
          "UI",
          "Serverside",
          "IP",
          "Communication",
          "Analytics",
          "Database",
          "UI",
          "Serverside",
          "EP",
          "Average",
          "Grade"
        ],
      },
      {
        skill: "LiveCodingTest",
        id: "62f3b98a20efc67bbb6d37c1",
        proportion: 0.10, // means 10%
        columns: [
          "IP",
          "EP",
          "Average",
          "Grade"
        ],
      },
    ],
    commanSkill: {
      proportion: 0.25,
      columns: ["IM", "EM", "IP", "EP", "Average", "Grade"]
    }, // means 25%
    academic: 0.05, // means 5%
  }
};

export default url;
