import React, { useState, useEffect } from "react";
import LoginService from "../../services/LoginService";
import $ from "jquery";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import formloader from "../../images/formloading.svg";
import { useNavigate } from "react-router-dom";

export default function Login(props) {
  const [sent, setSent] = useState({ sent: false, msg: "" });
  const [confirmBox, setConfirmBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    uname: yup
      .string()
      .email("Enter Valid Email Id")
      .required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    var headerHeight = $(".navbar").height();
    // var footerHeight = $(".footer").height();
    // var totalHeight = headerHeight + footerHeight;
    var totalHeight = headerHeight;
    $(".login").css("min-height", "calc(99vh - " + totalHeight + "px - 55px");
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, []);

  const handleForgotPassword = (data) => {
    let uname = data.uname;
    setLoading(true);
    LoginService.postEmail({ email: uname })
      .then((res) => {
        setConfirmBox(true);
        if (res.data?.success) {
          setLoading(false);
          setSent({
            sent: true,
            msg: res.data?.msg,
            alert: "alert-success",
            success: true,
          });
          // navigate("/");
          // data.target.reset();
        }
      })
      .catch((ex) => {
        setLoading(false);
        setConfirmBox(true);
        setSent({
          sent: true,
          msg:
            ex?.response?.data?.msg ||
            ex?.response?.data?.error ||
            "Error sending email",
          alert: "alert-danger",
          success: false,
        });
        // data.target.reset();
      });
    window.scrollTo(0, 0);
    setTimeout(() => setConfirmBox(false), 5000);
  };

  return (
    <>
      {/* <Header text="Forgot Password" /> */}
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">Loading...</div>
          </div>
        </div>
      )}
      <section className="container login">
        <div className="row m-0 py-lg-5 py-3">
          {confirmBox && (
            <div className="text-center">
              <div className="col-lg-5 col-md-7 col-sm-10 mx-auto confirmbox">
                <div className={`alert ${sent.alert}`}>{sent.msg}</div>
              </div>
            </div>
          )}
          <div className="col-lg-5 col-md-7 col-sm-10 mx-auto bg-white py-lg-5 py-3 formcard">
            <h2 className="text-center heading font-weight-300">
              Forgot your password?
            </h2>
            <p className="text-center py-2">
              Link to reset your passwod will be sent to your registered email
            </p>
            <form
              onSubmit={handleSubmit(handleForgotPassword)}
              className="px-lg-4 pt-2 pb-4"
              method="post"
            >
              <div className="mb-3">
                <label htmlFor="uname" className="form-label color-dback ps-1">
                  Email <sup class="text-danger">*</sup>
                </label>
                <input
                  name="email"
                  id="uname"
                  {...register("uname")}
                  placeholder="Email address"
                  className="form-control"
                />
                <small className="form-text d-block my-2 text-red">
                  {errors.uname?.message}
                </small>
              </div>
              <div className="text-center pt-3">
                <button type="submit" className="btn btn-blue forgot-btn">
                  Request password reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
