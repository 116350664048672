import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import ProductandCategory from '../../../services/ProductandCategory';

const CategoryList = () => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate()

    // Set initial state
    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = () => {
        ProductandCategory.getCateGory().then(res => {
            setCategories(res.data)
        }).catch(e => console.log(e))
    }

    const handleEdit = (categoryId) => {
        // Implement edit functionality here
        const selectedCategory = categories.find(category => category.id === categoryId);

        // Navigate to the CreateCategory component with the selected category data
        navigate(`/updatecategory/${categoryId}`, { state: { initialData: selectedCategory } });
    };

    const handleDelete = (categoryId) => {
        // Implement delete functionality here
        ProductandCategory.deleteCategory(categoryId).then(res => {
            if (res.status === 200) {
                alert("Data deleted successfully");
                fetchData()
            } else {
                alert("something wen wrong")
            }
        }).catch(error => alert(error))
    };


    return (
        <div className="container mt-4 mb-5">
            <div className="d-flex justify-content-between align-items-center">
                <h2>Categories</h2>
                <Link to="/createCategory">
                    <button className="btn btn-success">Add Category</button>
                </Link>
            </div>
            <table className="table">
                <thead>
                    <tr><th>Sr No</th>

                        <th>Category Name</th>
                        <th>Description</th>
                        {/* <th>Created Date</th>
                        <th>Last Modified Date</th> */}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map((category, index) => (
                        <tr key={category.id}>
                            <td dangerouslySetInnerHTML={{ __html: index + 1 }} />
                            <td dangerouslySetInnerHTML={{ __html: category.name }} />
                            <td dangerouslySetInnerHTML={{ __html: category.description }} />
                            {/* <td>{category.createdDate}</td>
                            <td>{category.lastModifiedDate}</td> */}
                            <td>
                                <Link to={`/categorydetails?id=${category.id}`}>
                                    <button title='view' className='btn me-1' onClick={() => { navigate(`/categorydetails?id=${category.id}`) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                        </svg>
                                    </button></Link>

                                <Link to={`/updatecategory/${category.id}`}>
                                    <button className="btn btn-primary">Edit</button>

                                </Link>


                                <button className="btn btn-danger ml-2 ms-1" onClick={() => handleDelete(category.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
};

export default CategoryList;
