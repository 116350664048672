import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Select, { components } from "react-select";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import BatchManagementServices from "../../services/BatchManagementServices";
import CourseService from "../../services/CourseService";
import { yupResolver } from "@hookform/resolvers/yup";
import { VscCheck } from "react-icons/vsc";
import { FcCancel } from "react-icons/fc";
import formloader from "../../images/formloading.svg";

export default function CreateBatch(props) {
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [AllOptions, setAllOptions] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const intialBatch = {
    batchName: "",
    selectedSkill: "",
    startDate: "",
    days: "",
    department: ""
  }
  const [postData, setPostData] = useState(intialBatch);
  const [postDataError, setPostDataError] = useState(intialBatch);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [toggleSelectDepartment, setToggleSelectDepartment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState({ message: "", status: false });

  const handleChange = (e) => {
    const { name, value } = e.target
    switch (name) {
      case 'batchName':
        if (value?.length > 0 && value.split('-')?.length === 3 && value.split('-')[2].length > 0) {
          setPostData({ ...postData, [name]: value })
          setPostDataError({ ...postDataError, [name]: "" })
        } else {
          setPostData({ ...postData, [name]: value })
          setPostDataError({ ...postDataError, [name]: "Enter your batch name with proper format . Ex: feb-23-dotnet" })
        }
        break;
      case 'startDate':
        if (value) {
          setPostData({ ...postData, [name]: value })
          setPostDataError({ ...postDataError, [name]: "" })
        }
        break;
      case 'department':
        if (value) {
          setPostData({ ...postData, [name]: value })
        }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    CourseService.getSubCourses().then((res) => {
      let data = res.data.map((item) => {
        return { value: item.name, label: item.name };
      });
      setAllOptions(data);
    });
  }, []);

  useEffect(() => {
    BatchManagementServices.getDepartments(localStorage.getItem("token"))
      .then((res) => {
        const temp = [];
        for (let i = 0; i < res.data.data.length; i++) {
          const department = res.data.data[i];
          temp.push({ value: department.name, label: department.name });
        }
        setDepartments([...temp]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSelectChange = (selectedOptions, e) => {
    setSelectedSkill(selectedOptions);
    if (selectedOptions?.length > 0) {
      setPostDataError({ ...postDataError, selectedSkill: "" })
    } else {
      setPostDataError({ ...postDataError, selectedSkill: "Required Fields *" })
    }
    if (e.action === "remove-value") {
      CourseService.getTrainingPlan(
        e.removedValue.value,
        localStorage.getItem("token")
      ).then((res) => {
        setPostData({ ...postData, days: parseInt(postData.days - res.data.length) });
      });
    } else {
      CourseService.getTrainingPlan(
        e.option.value,
        localStorage.getItem("token")
      ).then((res) => {
        setPostData({ ...postData, days: parseInt(postData.days + res.data.length) });
      });
    }
  };
  const handleSelectDepartment = (selectedOption, e) => {
    setSelectedDepartment(selectedOption);
    if (selectedOption) {
      setPostDataError({ ...postDataError, department: "" })
    } else {
      setPostDataError({ ...postDataError, department: "Required Fields *" })
    }
  };
  const handleValidation = (postBatchData) => {
    const validationSchema = {}
    const validationErrorList = []
    for (const key in postBatchData) {
      if (postBatchData[key]?.length === 0) {
        const required = 'Required Fields *';
        if (postDataError[key]?.length === 0) {
          validationSchema[key] = required;
        } else {
          validationSchema[key] = postDataError[key]
        }
      } else {
        if (key === "days") {
          if (postBatchData[key] > 0) {
            validationSchema[key] = ''
          } else {
            validationSchema[key] = postDataError[key]
          }
        } else {
          validationSchema[key] = postDataError[key]
        }
      }
    }
    setPostDataError(validationSchema)
    const data = Object.values(validationSchema).forEach((item) => item.length > 0 ? validationErrorList.push(false) : validationErrorList.push(true))
    return validationErrorList.includes(false) ? false : true
  }
  const handleCreateBatch = (e) => {
    e.preventDefault();
    let temp = {
      batchName: postData.batchName,
      startDate: postData.startDate,
      days: postData.days,
      selectedSkill: selectedSkill.length > 0 ? selectedSkill : '',
      department: selectedDepartment?.value ? selectedDepartment.value : '',
    };
    const requiredFieldValidation = handleValidation(temp)
    if (requiredFieldValidation) {
      setLoading(true)
      BatchManagementServices.CreateBatch(temp, localStorage.getItem("token"))
        .then((res) => {
          setConfirmBox(true)
          setLoading(false)
          setPostData(intialBatch);
          setSelectedSkill([]);
          setSelectedDepartment({});
          setMsg({ status: res.data.success, message: res.data.msg })
          // alert("Batch Created Succesfully");
        })
        .catch((err) => {
          if(err['response']){
            setLoading(false)
            if (err['response']?.status === 500) {
              setConfirmBox(true)
              setMsg({ status: err['response']?.data.success, message: err['response']?.data.msg })
            } else if (err['response']?.status === 401) {
              setConfirmBox(true)
              setMsg({ status: err['response']?.data.success, message: err['response']?.data.msg })
            } else {
              props.props.history.push("/login");
            }
          }
        });
    } else {
      if (temp.days === 0) {
        setConfirmBox(true)
        setMsg({ status: false, message: "There is no training plan in the selected skill. Kindly add at least one training plan in the skill." })
      }
    }
    setTimeout(() => setConfirmBox(false), 3000)
  };

  function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }
  const SortableMultiValue = SortableElement((props) => {
    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  });
  const SortableSelect = SortableContainer(Select);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selectedSkill, oldIndex, newIndex);
    setPostData({ ...postData, selectedSkill: newValue })
    setSelectedSkill(newValue);
  };

  const handleBatchSubmit = () => {
    BatchManagementServices.postDepartments(
      { name: postData.department },
      localStorage.getItem("token")
    )
      .then((res) => {
        setDepartments([...departments, res.data.data]);
        BatchManagementServices.getDepartments(localStorage.getItem("token"))
          .then((res) => {
            const temp = [];
            for (let i = 0; i < res.data.data.length; i++) {
              const department = res.data.data[i];
              temp.push({ value: department.name, label: department.name });
            }
            setDepartments([...temp]);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    setPostData({ ...postData, department: "" });
    setToggleSelectDepartment(!toggleSelectDepartment);
  };

  const handleClear = () => {
    setPostData(intialBatch)
    setPostDataError(intialBatch)
    setSelectedDepartment([])
    setSelectedSkill([])
  }
  return (
    <>
      <div className="container mt-5 w-50">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="200" />
              </div>
              <div className="col-12 text-white h4">
                Uploading batch data
              </div>
            </div>
          </div>
        )}
        {confirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div
                  className={`alert ${msg.status ? "alert-success" : "alert-danger"
                    }`}
                >
                  {msg.message}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="">
          <form onSubmit={handleCreateBatch} method="post">
            <h3 className="text-center">Create New Batch</h3>
            <div class="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Batch Name</label>
              <input
                type="text"
                class="form-control mt-1"
                name="batchName"
                placeholder="Enter Branch Name"
                value={postData.batchName}
                onChange={handleChange}
              />
              {postDataError?.batchName?.length > 0 && <small className="text-danger mt-1">
                {postDataError.batchName}
              </small>}

            </div>
            <div class="form-group mt-3">
              <label htmlFor="exampleInputPassword1">Start Date</label>
              <input
                value={postData.startDate}
                name="startDate"
                onChange={handleChange}
                type="date"
                class="form-control mt-1"
                placeholder="Password"
              />
              {postDataError?.startDate?.length > 0 && <small className="text-danger mt-1">
                {postDataError.startDate}
              </small>}
            </div>
            <div className="form-group mt-4">
              <label htmlFor="exampleInputPassword1">Select Skills</label>
              <SortableSelect
                axis="xy"
                onSortEnd={onSortEnd}
                distance={4}
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                isMulti
                options={AllOptions}
                value={selectedSkill}
                placeholder="Select Skill"
                onChange={handleSelectChange}
                components={{
                  MultiValue: SortableMultiValue,
                }}
                closeMenuOnSelect={false}
              />
              {postDataError?.selectedSkill?.length > 0 && <small className="text-danger mt-1">
                {postDataError.selectedSkill}
              </small>}
            </div>
            <div className="form-group mt-4">
              <div className="row">
                {!toggleSelectDepartment ? (
                  <label htmlFor="exampleInputPassword1">
                    Select Department
                  </label>
                ) : (
                  <label htmlFor="exampleInputPassword1">Add Department</label>
                )}
                <div
                  className={`col ${!toggleSelectDepartment ? "col-11" : "col-12"
                    }`}
                >
                  {!toggleSelectDepartment ? (
                    <SortableSelect
                      axis="xy"
                      onSortEnd={onSortEnd}
                      distance={4}
                      getHelperDimensions={({ node }) =>
                        node.getBoundingClientRect()
                      }
                      isClearable
                      options={departments}
                      value={selectedDepartment}
                      placeholder="Select Department"
                      onChange={handleSelectDepartment}
                      closeMenuOnSelect={true}
                    />
                  ) : (
                    <div className="row">
                      <div className="col col-10">
                        <input
                          value={postData.department}
                          name="department"
                          onChange={handleChange}
                          type="text"
                          class="form-control mt-1"
                          placeholder="Department"
                        />
                      </div>
                      <div className="col col-2">
                        <button
                          className="btn btn-sm mt-1 ms-1 btn-success"
                          onClick={handleBatchSubmit}
                        >
                          <VscCheck />
                        </button>
                        <button
                          className="btn btn-sm mt-1 ms-1 btn-light"
                          onClick={() =>
                            setToggleSelectDepartment(!toggleSelectDepartment)
                          }
                        >
                          <FcCancel />
                        </button>
                      </div>
                    </div>
                  )}
                  {postDataError?.department?.length > 0 && <small className="text-danger mt-1">
                    {postDataError.department}
                  </small>}
                </div>
                <div className="col col-1">
                  {!toggleSelectDepartment && (
                    <button
                      className="btn btn-primary my-auto btn-small"
                      onClick={() =>
                        setToggleSelectDepartment(!toggleSelectDepartment)
                      }
                    >
                      +
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <button className="btn btn-primary">Submit</button>
              <button type="button" onClick={handleClear} className="btn btn-secondary mx-2">Reset</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
