import React, { useEffect, useState } from "react";
import CourseService from "../../services/CourseService";
import "./logoSlider.scss";
import Slider from "react-slick";
import global from "../../servicesvariable";
import AmazonWeb from "../../assets/images/amzon-web-servers-icon.png";
import Angularjs from "../../assets/images/angularjs-icon.png";
import Azure from "../../assets/images/azure-icon.png";
import Bootstrap from "../../assets/images/Bootstrap.png";
import Chsarp from "../../assets/images/chsarp-icon.png";
import Css3 from "../../assets/images/css3-icon.png";
import Dotnetcore from "../../assets/images/dotnet-icon.png";
import Html5 from "../../assets/images/html5-list-icon.png";
import Javascript from "../../assets/images/javascript-icon.png";
import MySql from "../../assets/images/my-sql-icon.png";
import Php from "../../assets/images/php-list-icon.png";
import xamarin from "../../assets/images/Xamarin.png";
import flutter from "../../assets/images/Flutter.png";
import ionic from "../../assets/images/Ionic.png";

export default function LogoSlider() {
  const [allsubcourse, setallsubcourse] = useState([]);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 14,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 8.5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 2,
        },
      },
    ],
  };
  //   var logoImg = [
  //       {img: AmazonWeb, name:"amazonWeb"},
  //       {img: Angularjs, name:"angularjs"},
  //       {img: Azure, name:"azure"},
  //       {img: Bootstrap, name:"bootstrap"},
  //       {img: Chsarp, name:'chsarp'},
  //       {img: Css3, name:'css3'},
  //       {img: Dotnetcore, name:'dotnetcore'},
  //       {img: Html5, name:'html5'},
  //       {img: Javascript, name:'javascript'},
  //       {img: MySql, name:'mySql'},
  //       {img: Php, name:'php'},
  //       {img: xamarin, name:'Xamarin'},
  //       {img: flutter, name:'Flutter'},
  //       {img: ionic, name:'Ionic'},
  // ]
  var imgList = [];
  var logo = [];
  useEffect(() => {
    CourseService.getSubCourses()
      .then((res) => {
        if (res.data) {
          setallsubcourse(res.data);
        }
      })
      .catch((ex) => {});
  }, []);
  // logoImg.forEach((img) => {
  allsubcourse.forEach((data) => {
    if (data.type == null) {
      console.log(data);
    }

    if (
      data.icon !== "default.jpg" &&
      !logo.find((item) => item.icon === data.icon)
    ) {
      logo.push(data);
      imgList.push(
        <div class="text-center">
          <a
            href={`/course/${data.name}`}
            class="d-inline-block"
            title={`${data.name}`}
          >
            {/* <img class="mx-auto" alt="" width="48" height="48" src={img.img} /> */}
            {data.icon.match(/http/g) ? (
              <img
                src={data.icon}
                class="mx-auto"
                alt=""
                width="48"
                height="48"
              />
            ) : (
              <>
                <img
                  src={`${global.imgURL}/${data.icon}`}
                  class="mx-auto"
                  alt={data.name}
                  height="48"
                  width="48"
                ></img>
              </>
            )}
          </a>
        </div>
      );
      // }
      // else{
      //   imgList.push(<div class="text-center"><a href="#" class="d-inline-block" title="course"><img class="mx-auto" alt="" width="48" height="48" src={img.img} /></a></div>  )
    }
    // })
  });
  return (
    <div class="container-fluid logo-container">
      {allsubcourse && allsubcourse.length > 0 && (
        <Slider {...settings}>{imgList}</Slider>
      )}
    </div>
  );
}
