import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import ManageCourseCard from "../cards/ManageCourseCard";
import { FaCloudUploadAlt, FaAngleUp, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ToolTip from "../BsComponents/ToolTip";

const ManageCourses = () => {
  const [courses, setCourses] = useState([]);

  const [allcategory, setallcategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [visible, setVisible] = useState(false);
  const level = ["Beginner", "Intermediate", "Advanced"];
  const navigate = useNavigate();

  const getCourses = () => {
    CourseService.getCourses()
      .then(async (res) => {
        if (res.data.length > 0) {
          setCourses(res.data);
        }
        setLoading(false);
      })
      .catch((ex) => console.log(ex));
  };
  useEffect(() => {
    setLoading(true);

    CourseService.getCategory()
      .then((res) => {
        setallcategory(res.data);
      })
      .catch((ex) => console.log(ex));
    getCourses();
    window.addEventListener("scroll", toggleVisible);
  }, []);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 80) {
      setVisible(true);
    } else if (scrolled <= 80) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCourseUpdate = (upcourse) => {
    const token = localStorage.getItem("token");
    if (
      upcourse.name &&
      upcourse.name.trim() &&
      upcourse.type &&
      upcourse.desc &&
      upcourse._id &&
      upcourse.level
    ) {
      setLoading(true);
      upcourse.name = upcourse.name.trim();

      CourseService.putCourse(upcourse, token)
        .then((res) => {
          if (res.data.msg) {
            setMsg("Course Updated Successfully...");
            getCourses();
          }
        })
        .catch((ex) => console.log(ex));

      setLoading(false);
    }
  };

  const handleRemoveCourse = (item) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      setLoading(true);
      CourseService.removeCourse(item, token)
        .then((res) => {
          if (res.data.msg !== "dependencies") {
            setMsg("Course Removed Successfully...");
            getCourses();
          } else {
            alert("Course can't Remove.");
          }
        })
        .catch((ex) => console.log(ex));
      setLoading(false);
    }
  };
  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li className={"breadcrumb-item active"}>Manage Courses</li>
          </ol>
        </nav>
        <div className="">
          <ToolTip title={"Add Course"} placement={"bottom"}>
            <button
              htmlFor="adcourse"
              className="btn bg-green text-white mx-5"
              onClick={() => {
                navigate("/admin/course/manage-course/add");
              }}
            >
              <FaPlus className="me-2 fs-5" />
              Add Course
            </button>
          </ToolTip>
        </div>
      </div>

      <div className="container px-3 mb-3">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Uploading course...</div>
            </div>
          </div>
        )}
        <div className="form px-lg-5">
          <div className="row g-4 listcourses py-4 mb-5">
            {courses.map((item, index) => {
              return (
                <ManageCourseCard
                  item={item}
                  key={index}
                  handleCourseUpdate={handleCourseUpdate}
                  handleRemoveCourse={handleRemoveCourse}
                  allcategory={allcategory}
                  level={level}
                />
              );
            })}
          </div>
        </div>

        <FaAngleUp
          className="scrollbutton rounded-circle mb-5"
          onClick={scrollToTop}
          style={{ display: visible ? "inline" : "none" }}
        />
      </div>
    </>
  );
};

export default ManageCourses;
