import React, { useEffect, useState, useRef } from "react";
import CourseService from "../../services/CourseService";
import { FaPlay } from "react-icons/fa";
import jwt_decode from "jwt-decode";
import { Document, Page, pdfjs } from "react-pdf";
import Reacthlsplayer from "react-hls-player";
import { fileURL } from "../../services/CourseService";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import { useParams, useNavigate } from "react-router-dom";
import global from "../../servicesvariable";

export default function Videos(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const scname = props.scname;
  let { cname, sname, data, vid } = useParams();
  const video = useRef();
  const [numPages, setNumPages] = useState(null);
  const [videos, setVideos] = useState([]);
  const [playvideo, setPlayvideo] = useState();
  const width = useWindowWidth();
  const [playvideoid, setPlayvideoID] = useState();
  const [played, setPlayed] = useState();
  const [percentages, setPercentages] = useState([]);
  const [currentplayTime, setplayTime] = useState(0);
  const [percents, setPercent] = useState(0);
  const [index, setindex] = useState(0);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );

    textLayers.forEach((layer) => {
      const { style } = layer;

      style.top = "0";

      style.left = "0";

      style.transform = "";
    });
  }
  useEffect(() => {
    if (data && vid) {
      navigate("/course/" + cname + "/subcourse/" + sname + "/videos/" + vid);
    }
    const token = localStorage.getItem("token");
    if (token) {
      const uid = jwt_decode(token).id;
      CourseService.getVideos(scname)
        .then((res) => {
          if (res.data.length > 0) {
            setVideos(res.data);
          }
        })
        .catch((ex) => console.log(ex));
      CourseService.getProgress(token, uid)
        .then((res) => {
          if (res.data.length > 0) {
            setPercentages(res.data);
          }
        })
        .catch((ex) => console.log(ex));
    }
    // if (window.screen.width < 992) {
    //   $(window).scroll(function () {
    //     if ($(window).scrollTop() > 100) {
    //       $(".playvideo").css({
    //         position: "absolute",
    //         top: "0px",
    //         left: "0px",
    //         // "padding-bottom": "10px",
    //         // 'width': '100%',
    //         background: "rgba(255, 255, 255, 0.95)",
    //         "z-index": "99",
    //       });
    //     } else {
    //       $(".playvideo").css({
    //         position: "relative",
    //         background: "#FFFFFF",
    //         // 'width': '100%'
    //       });
    //     }
    //   });
    // }
    // setPercent(0);
  }, [currentplayTime]);

  const openNav = () => {
    document.getElementById("myNav").style.width = "100%";
    document.getElementById("playvideos").style.display = "none";
  };

  const closeNav = () => {
    document.getElementById("myNav").style.width = "0%";
    document.getElementById("playvideos").style.display = "block";
  };

  const handlePlayVideo = (url) => {
    navigate(
      "/course/" + cname + "/subcourse/" + sname + "/videos/" + url.filename
    );
    const vid = url.filename;
    // CourseService.getLocalVideo(vid, token)
    //   .then((res) => {
    //     if (res) {
    setPlayvideo(url.filename);
    setPlayvideoID(url.id);
    //   }
    // })
    // .catch((ex) => console.log(ex));
    percentages.forEach((element) => {
      if (element.id === url.id) {
        setPercent(element.percentage);
      }
    });
  };

  const handleplay = async () => {
    if (percents !== 0 && percents !== 100) {
      video.current.currentTime = (video.current.duration * percents) / 100;
    }
  };

  const handlepause = async () => {
    // setPercent(0);
    if (played) {
      const element = percentages.find((element) => {
        return element.id === played.id;
      });
      if (!element && token) {
        const decoded = jwt_decode(token);
        const data = {
          id: played.id,
          uid: decoded.id,
          cname: scname,
          percentage: Math.ceil(
            (video.current.currentTime * 100) / video.current.duration
          ),
        };
        CourseService.postProgress(data, token, decoded.id).then((item) => {
          // setPercent(0);
        });
      }
    } else {
      if (percents !== 0 && percents !== 100) {
        video.current.currentTime = (video.current.duration * percents) / 100;
      }
      const element = percentages.find((element) => {
        return element.id === videos[0].videos[0].id;
      });
      if (!element && token) {
        const decoded = jwt_decode(token);
        const data = {
          id: videos[0].videos[0].id,
          uid: decoded.id,
          cname: scname,
          percentage: Math.ceil(
            (video.current.currentTime * 100) / video.current.duration
          ),
        };
        CourseService.postProgress(data, token, decoded.id).then((item) => {
          setPercent(0);
        });
      }
    }
    if (percents !== 100) {
      await setplayTime(Math.floor(video.current.currentTime));
      const per = {
        percentage: Math.ceil(
          (video.current.currentTime * 100) / video.current.duration
        ),
      };
      if (
        Math.floor(
          (video.current.currentTime * 100) / video.current.duration
        ) === 100
      ) {
        videos.forEach((item) => {
          item.videos.forEach(async (video) => {
            if (played) {
              if (video.id === played.id) {
                await setPlayed(item.videos[index + 1]);
                await setindex(index + 1);
                percentages.forEach(async (element) => {
                  if (item.videos[index + 1]) {
                    if (element.id === item.videos[index + 1].id) {
                      await setPercent(element.percentage);
                    }
                  }
                });
                if (played && token) {
                  const decoded = jwt_decode(token);
                  CourseService.putProgress(
                    played.id,
                    per,
                    token,
                    decoded.id
                  ).then((item) => {
                    setPercent(0);
                  });
                } else if (token) {
                  const decoded = jwt_decode(token);
                  CourseService.putProgress(
                    videos[0].videos[0].id,
                    per,
                    token,
                    decoded.id
                  ).then((item) => {
                    setPercent(0);
                  });
                } else {
                  navigate("/login");
                }

                if (item.videos[index + 1]) {
                  await handlePlayVideo(item.videos[index + 1]);
                }
              }
            } else {
              if (video.id === videos[0].videos[0].id) {
                await setPlayed(item.videos[index + 1]);
                await setindex(index + 1);
                percentages.forEach(async (element) => {
                  if (item.videos[index + 1]) {
                    if (element.id === item.videos[index + 1].id) {
                      await setPercent(element.percentage);
                    }
                  }
                });
                if (item.videos[index + 1]) {
                  await handlePlayVideo(item.videos[index + 1]);
                }
              }
            }
          });
        });
      }

      if (played && token) {
        const decoded = jwt_decode(token);
        CourseService.putProgress(played.id, per, token, decoded.id).then(
          (item) => {
            setPercent(0);
          }
        );
      } else if (token) {
        const decoded = jwt_decode(token);
        CourseService.putProgress(
          videos[0].videos[0].id,
          per,
          token,
          decoded.id
        ).then((item) => {
          setPercent(0);
        });
      } else {
        navigate("/login");
      }
    }
  };

  const handlePdf = () => {
    return Array.apply(null, { length: numPages })
      .map((val, index) => {
        return index + 1;
      })
      .map((pages, i) => (
        <div id={`page-${pages}`} key={i}>
          {width < 1200 && (
            <Page
              onLoadSuccess={removeTextLayerOffset}
              renderAnnotationLayer={false}
              renderTextLayer={true}
              pageNumber={pages}
              width={Math.min(width * 0.9, 400)}
            />
          )}
          {width >= 1200 && (
            <Page
              onLoadSuccess={removeTextLayerOffset}
              renderAnnotationLayer={false}
              renderTextLayer={true}
              pageNumber={pages}
            />
          )}
        </div>
      ));
  };

  const handleToggle = (item, index) => {
    item.videos = item.videos.sort(
      (item, nextitem) =>
        (parseInt(item.filename.split("-").slice(1, 2).join("-")) >
          parseInt(nextitem.filename.split("-").slice(1, 2).join("-")) &&
          1) ||
        -1
    );
    return (
      <div
        class={`${
          window.screen.width > 992 ? "overlay-contentt" : "overlay-contenttt"
        }`}
      >
        <div className="accordion-item border-0 mb-0" key={item._id}>
          <h2 className="accordion-header">
            <button
              className="w-100 h5 bg-none border border-none  overflow-hidden text-truncate"
              type="button"
              style={{ height: "40px" }}
            >
              {item.topic}
            </button>
          </h2>
          <div id={"myacc" + index}>
            <div className="accordion-body ">
              {item.videos.map((videoid, index) => {
                if (videoid.process) {
                  if (videoid.process === true) {
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          handlePlayVideo(videoid);
                          setindex(index);
                          setPlayed(videoid);
                          closeNav();
                        }}
                        className={`row ${
                          played && played.filename == videoid.filename
                            ? "bg text-white"
                            : "color-green"
                        } ${
                          vid && vid == videoid.filename
                            ? "bg text-white"
                            : "color-green"
                        }${
                          !played &&
                          !vid &&
                          videos[0].videos[0].filename == videoid.filename
                            ? "bg text-white"
                            : "color-green"
                        }`}
                      >
                        <div
                          className={`col-2 d-flex justify-content-center align-items-center my-3 ms-3 playbtn ${
                            played && played.filename === videoid.filename
                              ? "text-white"
                              : "color-green"
                          }`}
                        >
                          <FaPlay className="fs-6" />
                        </div>
                        <div className="col-lg-9 col d-flex align-items-center color-dback ">
                          <h6
                            className={"m-0 color-dback text-truncate"}
                            style={{ cursor: "pointer" }}
                          >
                            {(index + 1 < 10 ? "0" : "") +
                              (index + 1) +
                              videoid.filename
                                .split("-")
                                .slice(1, 5)
                                .join(" ")
                                .replace(".m3u8", "")
                                .replace(".ir", "")
                                .replace(".ir_", "")
                                .replace(/[^\D]/g, "")
                                .replace(/^_/g, " ")
                                .replace(/^./g, " ")
                                .replace(/^[a-zA-Z]+_/g, "")}{" "}
                          </h6>
                          {percentages.map((item, index) => {
                            if (token) {
                              const decoded = jwt_decode(token);
                              if (
                                item.id === videoid.id &&
                                item.uid === decoded.id &&
                                item.percentage == 100
                              ) {
                                return (
                                  <input
                                    type="checkbox"
                                    className="col-2"
                                    style={{ height: "16px" }}
                                    checked="true"
                                  />
                                );
                              }
                            } else {
                              navigate("/login");
                            }
                            return null;
                          })}
                        </div>
                        <hr className="pb-0 mb-0 color-dback" />
                      </div>
                    );
                  }
                } else {
                  return (
                    <div className="row mb-2" key={index}>
                      <div className="col-2 d-flex justify-content-center align-items-center color-green mt-2 ms-3 playbtn">
                        <FaPlay className="fs-6" />
                      </div>
                      <div className="col-lg-9 col-8 d-flex align-items-center color-dback">
                        <h6
                          className={" m-0 color-dback text-truncate"}
                          style={{ cursor: "pointer" }}
                        >
                          {index +
                            1 +
                            videoid.filename
                              .split("-")
                              .slice(1, 5)
                              .join(" ")
                              .replace(".m3u8", "")
                              .replace(".ir", "")
                              .replace(".ir_", "")
                              .replace(/[^\D]/g, "")
                              .replace(/^_/g, " ")
                              .replace(/^./g, " ")
                              .replace(/^[a-zA-Z]+_/g, "")}{" "}
                        </h6>
                      </div>
                      <h6 className="text-danger ms-3">
                        please wait for this video to play
                      </h6>
                      <hr className="pb-0 mb-0 color-dback" />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleTime = async () => {
    // if (percents !== 100) {
    //   await setplayTime(Math.floor(video.current.currentTime));
    //   const per = {
    //     percentage: Math.ceil(
    //       (video.current.currentTime * 100) / video.current.duration
    //     ),
    //   };
    //   if (
    //     Math.floor(
    //       (video.current.currentTime * 100) / video.current.duration
    //     ) === 100
    //   ) {
    //     videos.forEach((item) => {
    //       item.videos.forEach(async (video) => {
    //         if (played) {
    //           if (video.id === played.id) {
    //             await setPlayed(item.videos[index + 1]);
    //             await setindex(index + 1);
    //             percentages.forEach(async (element) => {
    //               if (item.videos[index + 1]) {
    //                 if (element.id === item.videos[index + 1].id) {
    //                   await setPercent(element.percentage);
    //                 }
    //               }
    //             });
    //             if (item.videos[index + 1]) {
    //               await handlePlayVideo(item.videos[index + 1]);
    //             }
    //           }
    //         } else {
    //           if (video.id === videos[0].videos[0].id) {
    //             await setPlayed(item.videos[index + 1]);
    //             await setindex(index + 1);
    //             percentages.forEach(async (element) => {
    //               if (item.videos[index + 1]) {
    //                 if (element.id === item.videos[index + 1].id) {
    //                   await setPercent(element.percentage);
    //                 }
    //               }
    //             });
    //             if (item.videos[index + 1]) {
    //               await handlePlayVideo(item.videos[index + 1]);
    //             }
    //           }
    //         }
    //       });
    //     });
    //   }
    //   if (played && token) {
    //     const decoded = jwt_decode(token);
    //     CourseService.putProgress(played.id, per, token, decoded.id).then(
    //       (item) => {
    //         setPercent(0);
    //       }
    //     );
    //   } else {
    //     const decoded = jwt_decode(token);
    //     CourseService.putProgress(
    //       videos[0].videos[0].id,
    //       per,
    //       token,
    //       decoded.id
    //     ).then((item) => {
    //       setPercent(0);
    //     });
    //   }
    // }
  };

  return (
    videos.length > 0 && (
      <div className="dataframe bg-white p-3">
        <div className="row">
          <div
            className="col-lg-4 order-2  order-md-1 border-0 overflow-auto my-0"
            id="accordionVideos"
            style={{ position: "relative", height: "500px" }}
          >
            {videos.length !== 0 &&
              videos.map((item, index) => {
                if (window.screen.width > 992) {
                  return handleToggle(item, index);
                }
              })}
            <div id="myNav" class="overlay">
              <h3 className="text-white p-3 bg-lblue">
                {" "}
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="30">
                  <g fill="none" fill-rule="evenodd">
                    <path fill="none" d="M14 0h29.16v29.156H14z" />
                    <path
                      fill="#fff"
                      d="M38.887 15.424l-15.304 5.742h-.002a.995.995 0 01-.988 0 .977.977 0 01-.493-.846V5.836c0-.349.189-.671.493-.846a1 1 0 01.988 0l15.304 8.742h.002a.978.978 0 01.493.846c0 .35-.189.672-.493.846z"
                    />
                    <g fill="#fff" transform="translate(0 5)">
                      <rect width="19" height="3" rx="2.5" />
                      <rect width="13" height="3" x="6" y="7" rx="2.5" />
                      <rect width="8" height="3" x="11" y="14" rx="2.5" />
                    </g>
                  </g>
                </svg>
                videos
              </h3>

              <a
                href="javascript:void(0)"
                class="closebtn text-white"
                onClick={closeNav}
              >
                &times;
              </a>

              {videos.length !== 0 &&
                videos.map((item, index) => {
                  if (window.screen.width < 992) {
                    return handleToggle(item, index);
                  }
                })}
            </div>
            {window.screen.width < 992 && (
              <div
                className="border-0 text-center m-3 rounded py-2"
                style={{ backgroundColor: "#dee2e6" }}
              >
                <span
                  style={{
                    fontSize: "calc(1.025rem + .9vw)",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={openNav}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="30"
                    style={{ float: "left", marginLeft: "15px" }}
                  >
                    <g fill="none" fill-rule="evenodd">
                      <path fill="none" d="M14 0h29.16v29.156H14z" />
                      <path
                        fill="#222"
                        d="M38.887 15.424l-15.304 5.742h-.002a.995.995 0 01-.988 0 .977.977 0 01-.493-.846V5.836c0-.349.189-.671.493-.846a1 1 0 01.988 0l15.304 8.742h.002a.978.978 0 01.493.846c0 .35-.189.672-.493.846z"
                      />
                      <g fill="#222" transform="translate(0 5)">
                        <rect width="19" height="3" rx="2.5" />
                        <rect width="13" height="3" x="6" y="7" rx="2.5" />
                        <rect width="8" height="3" x="11" y="14" rx="2.5" />
                      </g>
                    </g>
                  </svg>
                  <lable style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Toggle Video List
                  </lable>
                </span>
              </div>
            )}
          </div>

          <div
            className="playvideo col-lg-8 order-1 order-md-2"
            // style={{ position: "fixed-top" }}
            id="playvideos"
          >
            {played && played.filename && !vid && (
              <div className="col-10 frame">
                <figure>
                  <Reacthlsplayer
                    id="video"
                    src={global.SERVER + "/" + played.filename}
                    className="my-2"
                    onPlay={handleplay}
                    onPause={handlepause}
                    playerRef={video}
                    onTimeUpdate={handleTime}
                    controls
                  ></Reacthlsplayer>
                </figure>
              </div>
            )}
            {vid && (
              <div className="col-10 frame">
                <figure>
                  <Reacthlsplayer
                    id="video"
                    src={global.SERVER + "/" + vid}
                    className="my-2"
                    onPlay={handleplay}
                    onPause={handlepause}
                    playerRef={video}
                    onTimeUpdate={handleTime}
                    controls
                  ></Reacthlsplayer>
                </figure>
              </div>
            )}
            {videos[0] && videos[0].videos[0] && !played && !vid && (
              <div className="col-10 frame">
                <figure>
                  <Reacthlsplayer
                    id="video"
                    src={global.SERVER + "/" + videos[0].videos[0].filename}
                    className="my-2"
                    onPlay={handleplay}
                    onPause={handlepause}
                    playerRef={video}
                    onTimeUpdate={handleTime}
                    controls
                  ></Reacthlsplayer>
                </figure>
              </div>
            )}
            {/* {percentages.map((item, index) => {
              if (token && played) {
                const decoded = jwt_decode(token);
                if (item.id === playvideoid && item.uid === decoded.id) {
                  return <Progress percent={item.percentage} key={index} />;
                }
              } else {
                const decoded = jwt_decode(token);
                if (
                  videos[0] &&
                  item.id === videos[0].videos[0].id &&
                  item.uid === decoded.id
                ) {
                  return <Progress percent={item.percentage} key={index} />;
                }
              }
              return null;
            })} */}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-4"></div>
          {played && played.pdffilename && (
            <div className="col-lg-8">
              <Document
                file={`${fileURL}/${played.pdffilename}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {handlePdf()}
              </Document>
            </div>
          )}
          {!played && videos[0] && videos[0].videos[0].pdffilename && (
            <div className="col-lg-8">
              <Document
                file={`${fileURL}/${videos[0].videos[0].pdffilename}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {handlePdf()}
              </Document>
            </div>
          )}
        </div>
      </div>
    )
  );
}
