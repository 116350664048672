import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import { FaCloudUploadAlt, FaPlay, FaPen, FaPlusCircle } from "react-icons/fa";
import Reacthlsplayer from "react-hls-player";
import LoginService from "../../services/LoginService";
// import ManageM3U8videos from "./ManageM3U8videos";
import global from "../../servicesvariable";
import jwt_decode from "jwt-decode";
import ShoWHideToggle from "../showHideToggle/ShowHideToggle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ToolTip from "../BsComponents/ToolTip";

export default function VideoList() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const [coursevideos, setCoursevideos] = useState([]);
  const [cvids, setCvids] = useState({
    cid: "",
    topic: "",
    videos: [],
    pdf: [],
    topicId: "",
  });
  const [uvids, setUvids] = useState({
    topic: "",
    day: 0,
    videos: [],
    pdf: [],
  });

  const [page, setPage] = useState({
    selected: 2,
    cname: "",
    subCourseName: "",
    topic: "",
  });
  const [allvideos, SetAllVideos] = useState([]);
  const [dvids, setDvids] = useState([]);
  const [dpdfs, setPdfs] = useState([]);
  const [filename, setFilename] = useState([]);
  const [tnames, setTnames] = useState([]);
  const [vnames, setVnames] = useState("");
  const [m3u8, setM3U8] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [addvideoBox, setAddvideoBox] = useState(false);
  const [addpdfBox, setAddpdfBox] = useState(false);
  const [addm3u8Box, setAddm3u8Box] = useState(false);
  const [subcourseid, setsubcourseId] = useState("Common");
  const [subcategoryid, setsubcategoryId] = useState("Common");
  const [addvideoday, setAddvideoDay] = useState(false);
  const [allcategory, setallcategory] = useState([]);
  const [allsubcategory, setallsubcategory] = useState([]);
  const [subcoursename, setSubCoursename] = useState("Select SubCourses");
  const [msg, setMsg] = useState({ status: "", msg: "" });
  const [editTopicId, setEditTopicId] = useState("");
  const [loaderMessage, setLoaderMessage] = useState(false);
  const [dSelectStatus, setDeselectStatus] = useState(false);
  const [handleDeletePopup, setHandleDeletePopup] = useState(false);
  const [dSelectStatus1, setDeselectStatus1] = useState(false);
  const [handleDeletePopup1, setHandleDeletePopup1] = useState(false);
  const [handlePPTAlert, setHandlePPTAlert] = useState(false);
  const [handleVideoAlert, setHanadleVideoAlert] = useState(false);
  const token = localStorage.getItem("token");
  useEffect(async () => {
    if (state && id) {
      setSubCoursename(`Select ${state} SubCourses`);
      const getSubCourse = await findSubCourse(id);
      const subCourseName = getSubCourse ? getSubCourse?.name : state;
      const videList = await getVideos(subCourseName, token);
      if (videList?.length > 0) {
        setCoursevideos(videList);
        setTnames(videList);
        setPage({
          ...page,
          selected: 2,
          cname: state,
          subCourseName: getSubCourse?._id,
        });
      } else {
        setCoursevideos([]);
        setTnames([]);
        setPage({
          ...page,
          selected: 2,
          cname: state,
          subCourseName: getSubCourse?._id,
        });
      }
    }
    if (jwt_decode(localStorage.getItem("token")).role === "Trainer") {
      LoginService.getSingleUser(
        jwt_decode(localStorage.getItem("token")).id,
        localStorage.getItem("token")
      )
        .then((res) => {
          res.data.courses.map((item) => {
            CourseService.getSinglesubcourse(item)
              .then((result) => {
                if (result.data) {
                  setallsubcategory((d) => [...d, result.data[0]]);
                }
              })
              .catch((ex) => console.log(ex));
          });
        })
        .catch((ex) => console.log(ex));
      getM3u8Video();
    } else if (jwt_decode(localStorage.getItem("token")).role === "Admin") {
      CourseService.getSubCourses()
        .then((res) => {
          if (res.data) {
            setallsubcategory(res.data);
          }
        })
        .catch((ex) => console.log(ex));
      getM3u8Video();
    }
  }, [id, state]);

  const getM3u8Video = () => {
    CourseService.getm3u8video()
      .then((res) => {
        SetAllVideos(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getVideos = async (cname, token) => {
    let videos = [];
    await CourseService.getVideos(cname, token)
      .then((res) => {
        if (res.data?.length > 0) {
          videos = res.data;
        }
      })
      .catch((err) => console.log(err));
    return videos;
  };

  const findSubCourse = async (id) => {
    let subCourseName;
    if (id?.length > 0) {
      await CourseService.getSinglesubcourse(id)
        .then((res) => {
          if (res.data) {
            setallcategory(res.data);
            subCourseName = res.data?.[0];
          }
        })
        .catch((err) => console.log("Hello", err));
    }
    return subCourseName;
  };

  const handlem3u8check = async (e) => {
    if (e.target.checked === true) {
      setM3U8((d) => [...d, e.target.value]);
    } else {
      var cor = [];
      if (m3u8.length !== 0) {
        cor = m3u8.filter((j) => j === e.target.value);
      }
      if (cor) {
        const videos = m3u8.filter((j) => j !== e.target.value);
        setM3U8(videos);
      }
    }
  };

  const handleSubCourseChange = async (cname, value) => {
    const token = localStorage.getItem("token");
    setSubCoursename(cname);
    if (value === "subcourse") {
      if (!token) {
        navigate("/login");
      } else {
        const videoList = await getVideos(cname, token);
        if (videoList?.length > 0) {
          setCoursevideos(videoList);
          setTnames(videoList);
          setPage({
            ...page,
            subCourseName: videoList?.[0]?.cid,
          });
        }
      }
    }
  };

  const handleTopicChange = (
    tname,
    subcoursename = "",
    topicId = "",
    videoList = []
  ) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      const vids =
        videoList?.length > 0
          ? videoList.find((item) => item.topic === tname)
          : coursevideos.find((item) => item.topic === tname);
      vids.videos.sort(
        (item, nextitem) =>
          (item.filename.split("-").slice(1, 7).join("-") >
            nextitem.filename.split("-").slice(1, 7).join("-") &&
            1) ||
          -1
      );

      setAddvideoBox(false);
      setCvids(vids);
      // setPage((d) => ({ selected: 3, cname: d.cname, topic: tname }));
      setPage({
        ...page,
        selected: 3,
        topic: tname,
      });
    }
  };

  const handleVidcheck = (e, vid) => {
    const target = e.target;
    if (target.checked) {
      setDvids((d) => [...d, vid.filename]);
      if (vid?.pdffilename) {
        setPdfs([...dpdfs, vid.pdffilename]);
      }
      setHandlePPTAlert(true);
    } else {
      const uvids = dvids.filter((item) => item !== vid.filename);
      const ppdfs = dpdfs.filter((item) => item !== vid.pdffilename);
      if (uvids?.length > 0) {
        setHandlePPTAlert(true);
      } else {
        setHandlePPTAlert(false);
      }
      setPdfs(ppdfs);
      setDvids(uvids);
    }
  };

  const handlePdfcheck = (e, pdf) => {
    const target = e.target;
    if (target.checked) {
      setPdfs((d) => [...d, pdf.pdffilename]);
      setFilename((d) => [...d, pdf.filename]);
      setHandlePPTAlert(false);
      setHanadleVideoAlert(true);
    } else {
      const updfs = dpdfs.filter((item) => item !== pdf.pdffilename);
      if (updfs.length > 0) {
        setHanadleVideoAlert(true);
      } else {
        setHanadleVideoAlert(false);
      }
      setHandlePPTAlert(false);
      setPdfs(updfs);
    }
  };

  const handleuploadTopic = async (param) => {
    if (param.topic.length > 80) {
      setMsg({
        status: false,
        msg: "The topic name cannot exceed more than 80 characters.",
      });
      setConfirmBox(true);
      setTimeout(() => {
        setConfirmBox(false);
      }, 3000);
      return;
    }
    var course = "";
    const subCoursesName =
      subcoursename?.split(" ")?.length > 1
        ? allcategory?.[0]?.name
        : subcoursename?.split(" ")?.[0];
    if (subcourseid !== "Common") {
      course = allsubcategory.find((item) => item._id === subcourseid);
    } else {
      course = allsubcategory.find((item) => item.name === subCoursesName);
    }
    let topic;
    if (topic) {
      setAddvideoBox(false);
      setLoading(true);
    }
    if (editTopicId !== "") {
      topic = {
        topic: param.topic,
        topicId: editTopicId,
        cname: subCoursesName,
      };
    } else {
      topic = {
        topic: param.topic,
        cname: subCoursesName,
      };
    }
    if (course) {
      const id = course._id;
      CourseService.CreateAndUpdateVideoTopic(id, topic, token).then((res) => {
        setMsg({ status: true, msg: res.data.msg });
        if (res.data.updateTopic) {
          setsubcourseId(res.data.updateTopic.cid);
        } else {
          setEditTopicId("");
          setsubcourseId(res.data.createTopic.cid);
        }
        setConfirmBox(true);
        setTimeout(() => {
          if (res.data.updateTopic) {
            setEditTopicId("");
            setAddvideoBox(false);
          }
          setConfirmBox(false);
          // handleSubCourseChange(res.data.cname, "subcourse");
        }, 3000);
        handleSubCourseChange(res.data.cname, "subcourse");
        setUvids({ topic: "", videos: "" });
        setLoading(false);
      });
    } else {
      setConfirmBox(true);
      if (course) {
        return;
      } else {
        setMsg({
          status: false,
          msg: "Not found sub course",
        });
      }
      setTimeout(() => {
        setConfirmBox(false);
      }, 3000);
    }
  };

  const handleUpdateAndDeleteM3u8 = async (videoMode) => {
    var course = "";
    let topic = cvids.topic;
    const subCoursesName =
      subcoursename?.split(" ")?.length > 1
        ? allcategory?.[0]?.name
        : subcoursename?.split(" ")?.[0];
    const token = localStorage.getItem("token");
    if (subcourseid !== "Common") {
      course = allsubcategory.find((item) => item._id === subcourseid);
    } else {
      course = allsubcategory.find((item) => item.name === subCoursesName);
    }
    const m3u8Video = allvideos.filter((video) =>
      m3u8.includes(video.videoName)
    );
    if (course?._id) {
      setAddvideoBox(false);
      setAddvideoDay(false);
      setAddpdfBox(false);
      if (videoMode === "upload") {
        setLoading(true);
        CourseService.putM3U8video(
          course._id,
          { topic, m3u8Video, subcoursename },
          token
        ).then(async (res) => {
          if (res.data) {
            setMsg({
              status: res.data.status,
              msg: res.data.msg,
            });
            setConfirmBox(true);
            setTimeout(() => {
              setLoading(false);
              setConfirmBox(false);
              // handleSubCourseChange(res.data.cname, "subcourse");
              setPage({ ...page, selected: 3 });
              setAddm3u8Box(false);
            }, 3000);
            getUpdatedVideoList();
            setM3U8([]);
            setUvids({ topic: "", videos: "" });
          }
        });
      } else {
        if (window.confirm("Do you want to delete this video")) {
          setLoading(true);
          CourseService.deleteM3u8Videos(
            course._id,
            { topic, m3u8Video, subcoursename },
            token
          ).then((res) => {
            if (res.data) {
              setMsg({
                status: res.data.status,
                msg: res.data.msg,
              });
              setM3U8([]);
              getM3u8Video();
              setConfirmBox(true);
              setTimeout(() => {
                setConfirmBox(false);
              }, 3000);
              setUvids({ topic: "", videos: "" });
              setLoading(false);
            }
          });
        }
      }
    }
    {
      setTimeout(() => setConfirmBox(false), 10000);
    }
  };

  const handleUploadPdf = async (param) => {
    let { pdf } = param;
    const token = localStorage.getItem("token");

    if (!!pdf && pdf.length > 0) {
      setLoading(true);
      const formdata = new FormData();
      for (const item of pdf) {
        formdata.append("pdf", item);
      }

      CourseService.putVideopdf(vnames, formdata, token)
        .then((res) => {
          if (res.data) {
            setAddpdfBox(false);
            setLoading(false);
            setMsg({
              status: res.data.status,
              msg: res.data.msg,
            });
            setConfirmBox(true);
            setTimeout(() => {
              setConfirmBox(false);
              // handleSubCourseChange(res.data.cname, "subcourse");
              setPage({ ...page, selected: 3 });
              setAddm3u8Box(false);
            }, 3000);
            getUpdatedVideoList();
            setUvids({ topic: "", videos: "" });
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setConfirmBox(true);
          setMsg({
            status: false,
            msg: "Failed to upload pdf.",
          });
          setTimeout(() => {
            setConfirmBox(false);
          }, 3000);
          console.log(err);
        });
    }
  };

  const handleRemoveVids = async () => {
    if (
      cvids.topic &&
      dvids.length > 0 &&
      window.confirm("Do you want to delete these videos")
    ) {
      setLoaderMessage(true);
      setLoading(true);
      const token = localStorage.getItem("token");
      let upVideo = cvids;
      const allVideos = cvids?.videos;
      const videos = cvids.videos.filter(
        (item) => !dvids.includes(item.filename)
      );
      upVideo.videos = videos;
      upVideo.dvideos = allVideos.filter((item) => {
        return dvids.some((v) => v === item.filename);
      });
      try {
        const res = await CourseService.removeVideo(upVideo, token);
        if (res.data) {
          setConfirmBox(true);
          setMsg({
            status: res.data.status,
            msg: res.data.msg,
          });
          setHandlePPTAlert(false);
          setHandleDeletePopup(false);
          setDeselectStatus(false);
          setCvids({ ...cvids, videos: res.data.videos });
          setLoading(false);
          setDvids([]);
          setPdfs([]);
        }
      } catch (ex) {
        console.log(ex);
      }

      setLoaderMessage(false);
    }
    {
      setTimeout(() => setConfirmBox(false), 3000);
    }
  };

  const getUpdatedVideoList = async () => {
    let subCourseName;
    const findSubcourseName = subcoursename.split(" ");
    if (findSubcourseName?.length > 1) {
      subCourseName = allcategory?.[0]?.name;
    } else {
      subCourseName = findSubcourseName[0];
    }
    const videoList = await getVideos(subCourseName, token);
    if (videoList?.length > 0) {
      setCoursevideos(videoList);
      handleTopicChange(cvids.topic, subcoursename, "", videoList);
    }
  };

  const handleRemovePdfs = async () => {
    const token = localStorage.getItem("token");
    if (dpdfs.length > 0 && token) {
      const subCourseData = {
        subCourseId: page.subCourseName,
        subTopicName: page.topic,
      };
      setLoading(true);
      await CourseService.removeVideopdf(subCourseData, filename, token).then(
        (res) => {
          if (res.data.msg) {
            setConfirmBox(true);
            setHandlePPTAlert(false);
            setHanadleVideoAlert(false);
            setHandleDeletePopup1(false);
            setDeselectStatus1(false);
            setDvids([]);
            setPdfs([]);
            setFilename([]);
            getUpdatedVideoList();
            setMsg({
              status: true,
              msg: res.data.msg,
            });
            setLoading(false);
            setPage({
              ...page,
              selected: 3,
            });
          }
        }
      );
    }
    {
      setTimeout(() => {
        setConfirmBox(false);
        setPage({ ...page, selected: 3 });
      }, 3000);
    }
  };

  const handleTchange = (item, index) => {
    return (
      <>
        <div key={index} className="col-lg-4">
          <div className="bg-white p-3 rounded citem mx-2 row">
            <div
              class="col-md-9 text-break"
              onClick={() => {
                handleTopicChange(item.topic, item.cid.name, item._id);
              }}
            >
              {item.topic}
            </div>
            <div class="col-md-3 text-end">
              <ToolTip title={"Edit"} placement={"bottom"}>
                <button
                  class="btn btn-light border px-2 mx-1"
                  onClick={() => {
                    setAddvideoBox(true);
                    setEditTopicId(item._id);
                    setUvids((d) => ({
                      ...d,
                      topic: item.topic,
                    }));
                  }}
                >
                  <FaPen className="color-black text-black" />
                </button>
              </ToolTip>
            </div>
          </div>
        </div>
      </>
    );
  };

  const findVideoAndPPt = (videoAndPdfList, fileType) => {
    let fileList = [];
    if (videoAndPdfList?.length > 0) {
      videoAndPdfList.forEach((item) => {
        if (fileType === "video") {
          if (item.filename) {
            fileList.push(item.filename);
          }
        } else {
          if (item.pdffilename) {
            fileList.push(item.pdffilename);
          }
        }
      });
    }
    return fileList;
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li
              className={"breadcrumb-item text-primary"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/admin/course/video");
                setDvids([]);
                setPdfs([]);
                setPage({
                  ...page,
                  selected: 1,
                });
                setsubcourseId("Common");
                setAddvideoBox(false);
                setAddpdfBox(false);
                setAddm3u8Box(false);
                setTnames([]);
                setallcategory([]);
              }}
            >
              Videos
            </li>
            <li
              className={
                page.selected >= 2
                  ? page.selected === 2 &&
                    !addvideoBox &&
                    !addpdfBox &&
                    !addm3u8Box
                    ? "breadcrumb-item active d-block"
                    : addvideoBox
                      ? "breadcrumb-item text-primary d-block pe-2"
                      : "breadcrumb-item text-primary d-block"
                  : "d-none"
              }
              style={{ cursor: page.selected > 2 ? "pointer" : "auto" }}
              onClick={() => {
                setDvids([]);
                setPdfs([]);
                setPage({
                  ...page,
                  selected: 2,
                });
                setAddvideoBox(false);
                setAddm3u8Box(false);
                setAddpdfBox(false);
              }}
            >
              {page.cname}
            </li>
            <li
              className={
                page.selected >= 3
                  ? page.selected === 3
                    ? "breadcrumb-item active d-block"
                    : "breadcrumb-item text-primary d-block"
                  : "d-none"
              }
              style={{ cursor: page.selected > 3 ? "pointer" : "auto" }}
              onClick={() => {
                setDvids([]);
                setPdfs([]);
                setPage({
                  ...page,
                  selected: 3,
                });
                setAddvideoBox(false);
                setAddm3u8Box(false);
                setAddpdfBox(false);
              }}
            >
              {page.topic}
            </li>
            <li
              className={
                page.selected >= 4
                  ? page.selected === 4
                    ? "breadcrumb-item active d-block"
                    : "breadcrumb-item text-primary d-block"
                  : "d-none"
              }
              style={{ cursor: page.selected > 4 ? "pointer" : "auto" }}
              onClick={() => {
                setDvids([]);
              }}
            >
              {addpdfBox && <span>Add pdf</span>}
              {addm3u8Box && <span>Add m3u8 video</span>}
            </li>
            {addvideoBox && (
              <li
                className={
                  page.selected == 2
                    ? page.selected === 2
                      ? "breadcrumb-item active d-block"
                      : "breadcrumb-item text-primary d-block"
                    : "d-none"
                }
                style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
                onClick={() => {
                  setDvids([]);
                }}
              >
                {`${editTopicId !== "" ? ` / Edit` : ` / Add`} Topic`}
              </li>
            )}
          </ol>
        </nav>
        <div>
          <ToolTip title={"Upload Pdf"} placement={"bottom"}>
            <label
              htmlFor={page.selected === 3 ? "uvids" : "abc"}
              className={
                page.selected > 2
                  ? "btn bg-green text-white visible"
                  : "btn bg-green text-white invisible"
              }
              style={{ marginRight: "4px" }}
              onClick={() => {
                setAddm3u8Box(false);
                setAddpdfBox(true);
                setPage({
                  ...page,
                  selected: 4,
                });
              }}
            >
              <FaCloudUploadAlt className="me-2 fs-5" />
              Upload Pdf
            </label>
          </ToolTip>
          {page?.selected === 2 && allcategory?.length > 0 && (
            <ToolTip title={"Add Topic"} placement={"bottom"}>
              <label
                htmlFor={page.selected === 3 ? "uvids" : "abc"}
                className={
                  page.selected >= 2
                    ? "btn bg-green text-white visible"
                    : "btn bg-green text-white invisible"
                }
                style={{ marginRight: "4px" }}
                onClick={
                  page.selected === 2
                    ? () => {
                      setAddvideoBox(true);
                      setEditTopicId("");
                      setUvids({ ...uvids, topic: "" });
                    }
                    : () => {
                      // setAddvideoDay(true);
                      setAddvideoBox(true);
                      setEditTopicId("");
                      setUvids({ ...uvids, topic: "" });
                    }
                }
              >
                <FaPlusCircle className="me-2 fs-5" />
                Add Topic
              </label>
            </ToolTip>
          )}
          <ToolTip title={"Upload Video"} placement={"bottom"}>
            <label
              htmlFor={page.selected === 3 ? "uvids" : "abc"}
              className={
                page.selected > 2
                  ? "btn bg-green text-white visible"
                  : "btn bg-green text-white invisible"
              }
              onClick={() => {
                setAddpdfBox(false);
                getM3u8Video();
                setAddm3u8Box(true);
                setPage({
                  ...page,
                  selected: 4,
                });
              }}
            >
              <FaCloudUploadAlt className="me-2 fs-5" />
              Upload Video
            </label>
          </ToolTip>
        </div>
      </div>
      <div className="container p-2 px-3">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
            </div>
          </div>
        )}
        {confirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div
                  className={
                    msg.status ? "alert alert-success" : "alert alert-danger"
                  }
                >
                  {msg.msg}
                </div>
              </div>
            </div>
          </div>
        )}
        {addvideoBox && (
          <div className="formloade bg-white my-2 p-4">
            <div className="row ">
              <div className="col-6 w-100 confirmbox">
                <form className="px-lg-5 pt-3" id="myform">
                  <div className="mb-3">
                    <label htmlFor="topic" className="form-label">
                      Topic Name <span className="text-danger mx-1">*</span>
                    </label>
                    <input
                      type="text"
                      name="topic"
                      id="topic"
                      className="form-control"
                      value={uvids.topic}
                      placeholder="Enter topic name"
                      required
                      onChange={(e) => {
                        setUvids((d) => ({
                          ...d,
                          topic: e.target.value,
                        }));
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <button
                      type="button"
                      className="btn bg-green text-white mt-3 px-4 me-3"
                      onClick={() => {
                        handleuploadTopic(uvids);
                      }}
                      disabled={!!uvids.topic.trim() ? false : true}
                    >
                      {!editTopicId ? "Add" : "Edit"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary mt-3"
                      onClick={() => {
                        setAddvideoBox(false);
                        setEditTopicId("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {addm3u8Box &&
          !addvideoBox &&
          !addpdfBox &&
          cvids &&
          cvids.topicId !== "" &&
          cvids.cid._id !== "" && (
            // <ManageM3U8videos topicDetail={cvids} courseName={page.cname} />
            <div className="formloade bg-white my-2 p-4">
              <div className="row ">
                <div className="col-6 w-100 confirmbox">
                  <form className="px-lg-5 pt-3" id="myform">
                    <div className="mb-3">
                      <label htmlFor="videoName" className="form-label h5 mb-2">
                        M3U8 Video Name
                      </label>
                      {allvideos.map((item, index) => {
                        return (
                          <>
                            {item.process && !item.add && (
                              <div className="col form-check" key={index}>
                                <input
                                  type="checkbox"
                                  id={item.videoName}
                                  placeholder="videoName"
                                  className="form-check-input"
                                  style={{ float: "left" }}
                                  value={item.videoName}
                                  onChange={(e) => {
                                    return handlem3u8check(e);
                                  }}
                                />
                                <label
                                  htmlFor={item.videoName}
                                  className="form-check-label color-dback"
                                >
                                  {item.videoName
                                    .split("-")
                                    .slice(1, 7)
                                    .join("-")}
                                </label>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                    <div className="mb-3">
                      <button
                        type="button"
                        className="btn btn-secondary mt-5 me-3"
                        onClick={() => {
                          setAddm3u8Box(false);
                          setPage({
                            ...page,
                            selected: 3,
                          });
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        disabled={m3u8?.length > 0 ? false : true}
                        className="btn bg-green text-white mt-5 me-3"
                        onClick={() => {
                          handleUpdateAndDeleteM3u8("upload");
                        }}
                      >
                        Upload
                      </button>
                      <button
                        type="button"
                        className="btn bg-danger text-white mt-5"
                        disabled={m3u8?.length > 0 ? false : true}
                        onClick={() => {
                          handleUpdateAndDeleteM3u8("delete");
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        {addpdfBox && !addvideoBox && (
          <div className="formloade bg-white my-2 p-4">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <form className="px-lg-5 pt-3" id="myform">
                  <div className="mb-3">
                    <label htmlFor="videoName" className="form-label">
                      Video name
                    </label>
                    <select
                      className={"form-select"}
                      id="videoName"
                      name="videoName"
                      onChange={(e) => setVnames(e.target.value)}
                    >
                      <option value="">select file</option>
                      {cvids.videos.map((item, index) => {
                        if (!item.pdffilename) {
                          return (
                            <option value={item._id} key={index}>
                              {item.filename}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="ppts" className="form-label">
                      Select pdf file
                    </label>
                    <input
                      type="file"
                      id="videos"
                      className="form-control"
                      required
                      multiple
                      onChange={(e) =>
                        setUvids((d) => ({
                          ...d,
                          pdf: e.target.files,
                        }))
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-secondary mt-5 me-3"
                      onClick={() => {
                        setAddpdfBox(false);
                        setPage({
                          ...page,
                          selected: 3,
                        });
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn bg-green text-white mt-5"
                      onClick={() => {
                        handleUploadPdf(uvids);
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {addvideoday && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <form className="px-lg-5 pt-3" id="myform">
                  <div className="mb-3">
                    <label htmlFor="ppts" className="form-label">
                      Select videos
                    </label>
                    <input
                      type="file"
                      id="videos"
                      className="form-control"
                      required
                      multiple
                      onChange={(e) =>
                        setUvids((d) => ({
                          ...d,
                          topic: cvids.topic,
                          videos: e.target.files,
                        }))
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-secondary mt-5 me-3"
                      onClick={() => {
                        setAddvideoDay(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn bg-green text-white mt-5"
                      onClick={() => {
                        handleuploadTopic(uvids);
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {dvids.length > 0 &&
          !addvideoBox &&
          !addpdfBox &&
          !dSelectStatus &&
          handlePPTAlert ? (
          <ShoWHideToggle
            videoAndPPT={dvids}
            allVideoAndPPT={
              coursevideos.find((item) => item.topic === cvids.topic)?.videos
            }
            setVideoAndPPT={setDvids}
            setDSelectPPTAndVideo={setDeselectStatus}
            setHandleDeletePopup={setHandleDeletePopup}
            handleRemovePPTAndVideo={handleRemoveVids}
            findVideoAndPPt={findVideoAndPPt}
            setHandlePPTAlert={setHandlePPTAlert}
            handlePPTAlert={handlePPTAlert}
            setPdfs={setPdfs}
            setHanadleVideoAlert={setHanadleVideoAlert}
          />
        ) : (
          handleDeletePopup && (
            <ShoWHideToggle
              videoAndPPT={dvids}
              allVideoAndPPT={
                coursevideos.find((item) => item.topic === cvids.topic)?.videos
              }
              setVideoAndPPT={setDvids}
              setDSelectPPTAndVideo={setDeselectStatus}
              setHandleDeletePopup={setHandleDeletePopup}
              handleRemovePPTAndVideo={handleRemoveVids}
              findVideoAndPPt={findVideoAndPPt}
              setHandlePPTAlert={setHandlePPTAlert}
              handlePPTAlert={handlePPTAlert}
              setPdfs={setPdfs}
              setHanadleVideoAlert={setHanadleVideoAlert}
            />
          )
        )}
        {dpdfs.length > 0 &&
          !addvideoBox &&
          !addpdfBox &&
          !dSelectStatus1 &&
          !handlePPTAlert ? (
          <ShoWHideToggle
            videoAndPPT={dpdfs}
            allVideoAndPPT={findVideoAndPPt(
              coursevideos.find((item) => item.topic === cvids.topic)?.videos,
              "ppt"
            )}
            setVideoAndPPT={setPdfs}
            setDSelectPPTAndVideo={setDeselectStatus1}
            setHandleDeletePopup={setHandleDeletePopup1}
            handleRemovePPTAndVideo={handleRemovePdfs}
            handlePPTAlert={handlePPTAlert}
            setHanadleVideoAlert={setHanadleVideoAlert}
          />
        ) : (
          handleDeletePopup1 && (
            <ShoWHideToggle
              videoAndPPT={dpdfs}
              allVideoAndPPT={findVideoAndPPt(
                coursevideos.find((item) => item.topic === cvids.topic)?.videos,
                "ppt"
              )}
              setVideoAndPPT={setPdfs}
              setDSelectPPTAndVideo={setDeselectStatus1}
              setHandleDeletePopup={setHandleDeletePopup1}
              handleRemovePPTAndVideo={handleRemovePdfs}
              handlePPTAlert={handlePPTAlert}
              setHanadleVideoAlert={setHanadleVideoAlert}
            />
          )
        )}

        {!addvideoBox && !addpdfBox && !addm3u8Box && (
          <div className="form px-lg-5 pt-3">
            {page.selected === 2 ? (
              <div>
                {allcategory[0] !== undefined ? (
                  <div className="dropdown">
                    <button
                      class="btn bg-primary text-white dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {subcoursename}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {allcategory.map((cat, index) => {
                        return (
                          <li>
                            <a
                              className="dropdown-item"
                              id="subcourse"
                              href="javascript:void(0)"
                              onClick={(e) => {
                                setsubcourseId(cat._id);
                                setsubcategoryId("Common");
                                setPage({
                                  ...page,
                                  subCourseName: cat.name,
                                });
                                handleSubCourseChange(cat.name, e.target.id);
                              }}
                            >
                              {cat.name === page.cname ? cat.name : cat.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <span className="text-danger text-center h3">
                    No subcourse are found please add
                  </span>
                )}

                <div className="row g-4 listcourses py-4">
                  {subcourseid !== "Common" &&
                    tnames.map((item, index) => {
                      if (item.topic !== undefined) {
                        return handleTchange(item, index);
                      }
                    })}
                  {subcourseid === "Common" &&
                    tnames.map((item, index) => {
                      return handleTchange(item, index);
                    })}
                </div>
              </div>
            ) : null}

            {page.selected === 3 ? (
              <form className="py-3" method="post" id="myform">
                <div className="row listppts g-4">
                  {cvids.videos.map((item, index) => {
                    return (
                      <div className="col-lg-4" key={index}>
                        <div className="card rounded">
                          <figure className="p-1 d-flex justify-content-center align-items-center">
                            <button
                              type="button"
                              id={"playbtn" + index}
                              className="btn rounded-pill mb-4"
                              onClick={() => {
                                const dvid = document.getElementById(
                                  "displayvid" + index
                                );
                                dvid.play();
                              }}
                              style={{
                                position: "absolute",
                                zIndex: "1",
                                height: "2.5rem",
                                width: "2.5rem",
                                textAlign: "center",
                                backgroundColor: "#ffffff",
                                boxShadow: "0 0 6px #554a4a91",
                              }}
                            >
                              <FaPlay className="text-center color-green" />
                            </button>
                            <Reacthlsplayer
                              id={"displayvid" + index}
                              className="w-100"
                              src="https://vulnerableapi.zerothreat.ai/file_example_mp4_480_1_5mg.m3u8"
                              controls
                              onPlay={() => {
                                const playbtn = document.getElementById(
                                  "playbtn" + index
                                );
                                playbtn.style.display = "none";
                              }}
                              onPause={() => {
                                const playbtn = document.getElementById(
                                  "playbtn" + index
                                );
                                playbtn.style.display = "block";
                              }}
                            ></Reacthlsplayer>
                          </figure>

                          <div className="card-body pt-0">
                            <h6 className="card-title color-dgreen">
                              {item.filename.replace(
                                item.filename.split("-")[0] + "-",
                                ""
                              )}
                            </h6>
                          </div>
                          <div className="checkheader">
                            <input
                              type="checkbox"
                              disabled={handleVideoAlert ? true : false}
                              checked={
                                dvids.find((dp) => dp === item.filename)
                                  ? true
                                  : false
                              }
                              id={"check" + index}
                              className="form-check-input p-2 pptcheck"
                              onChange={(e) => {
                                handleVidcheck(e, item);
                              }}
                            />
                          </div>
                        </div>
                        {item.pdffilename && (
                          <div className="card rounded mt-2">
                            <div className="card-body px-5">
                              <div className="checkheader">
                                <input
                                  type="checkbox"
                                  disabled={handlePPTAlert ? true : false}
                                  checked={
                                    dpdfs.find((dp) => dp === item.pdffilename)
                                      ? true
                                      : false
                                  }
                                  id={"check" + index}
                                  className="form-check-input p-2 pptcheck"
                                  onChange={(e) => {
                                    handlePdfcheck(e, item);
                                  }}
                                />
                              </div>

                              <h6 className="card-title color-dgreen">
                                {item.pdffilename.replace(
                                  item.pdffilename.split("-")[0] + "-",
                                  ""
                                )}
                              </h6>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </form>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
}
