import React, { useState, useEffect } from 'react';
import axios from 'axios'
import variable from "../../servicesvariable";

const ContactUs = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        msg: ''
    });

    const [response, setResponse] = useState({
        name: '',
        email: '',
        msg: ''
    })

    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = JSON.stringify({
            "name": formData.name,
            "email": formData.email,
            "msg": formData.msg
        });

        let config = {
            method: 'post',
            url: variable.Tutorial_Api_ContactUs,
            headers: {
                'Content-Type': 'application/json'
            },
            data
        };

        axios.request(config)
            .then((response) => {
                setResponse(response.data)
                setFormData({
                    name: '',
                    email: '',
                    msg: ''
                })
                setFormSubmitted(true)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div>
            <div className="text-center mx-auto mt-5" style={{ width: '50%', marginTop: 'calc(50vh - yourNavbarHeight)' }} >
                <h2>Contact us</h2>
            </div>

            <div className="container mt-4 w-25">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="msg" className="form-label">Message</label>
                        <textarea type="text" className="form-control" id="msg" name="msg" value={formData.msg} onChange={handleChange} required />
                    </div>

                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>


            {formSubmitted && <p>
                <div className="mt-5" style={{ height: '100vh' }}>
                    <div className="card mx-auto" style={{ width: '35rem' }}>
                        <div className="card-body">
                            <h3>Our team will contact you within 5-7 days.</h3>
                            <p className="card-text mt-3"><b>Name:</b> {response.name}.</p>
                            <p className="card-text"><b>Email:</b> {response.email}.</p>
                            <p className="card-text"><b>Message:</b> {response.msg}</p>
                        </div>
                    </div>
                </div>
            </p>}
        </div>
    );
};

export default ContactUs;
